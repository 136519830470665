import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import SimpleDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

function DatePicker(props) {

    const {
        id,
        options,
        placeholder,
        value,
        required,
        disabled,
        readonly,
        autofocus,
        onChange,
        onBlur,
        onFocus,
        formContext,
    } = props;

    const _onChange = (value) => {
        const res = value ? value.format(props.options.dateSaveFormat || "YYYY-MM-DD") : props.options.emptyValue;
        return onChange(res);
        //return onChange(value ? value.format(props.options.dateSaveFormat || "YYYY-MM-DD") : props.options.emptyValue);
    };
    console.log(value);
    return (

        <SimpleDatePicker
            key={props.id}
            selected={value ? moment(value, props.options.dateSaveFormat || "YYYY-MM-DD") : null}
            onChange={_onChange}
            showYearDropdown
            dateFormatCalendar="MMMM"
            yearDropdownItemNumber={15}
            scrollableYearDropdown
            popperPlacement="right-end"
            dateFormat={options.dateFormat || "DD/MM/YYYY"}
            /*filterDate = {(date) => {
                return moment() > date;
            }}*/
            filterDate={(date) => true}
        />
    )
}

DatePicker.defaultProps = {
    autofocus: false,
    options: {},
};

if (process.env.NODE_ENV !== "production") {
    DatePicker.propTypes = {
        schema: PropTypes.object.isRequired,
        id: PropTypes.string.isRequired,
        placeholder: PropTypes.string,
        options: PropTypes.shape({
            rows: PropTypes.number,
        }),
        value: PropTypes.string,
        required: PropTypes.bool,
        disabled: PropTypes.bool,
        readonly: PropTypes.bool,
        autofocus: PropTypes.bool,
        onChange: PropTypes.func,
        onBlur: PropTypes.func,
        onFocus: PropTypes.func,
    };
}
export default DatePicker;
