import React, {Component} from 'react';
import {Row, Col, DropdownToggle, Dropdown} from 'reactstrap';
import i18n from '../../views/Pages/Login/i18n';
import {Link} from "react-router-dom";

class TableHeader2 extends Component {

    constructor() {
        super();
        this.toggleDropdown = this.toggleDropdown.bind(this);
        this.state = {
            dropdownOpen: false,
        };
    }

    toggleDropdown() {
        this.setState({
            dropdownOpen: !this.state.dropdownOpen
        });
    }

    render() {
        var item = [];

        for(const i of this.props.items) {
            item = "/#" + i.link;
        }

        return (
            <Row className='subheader'>
                <Col className='col-8'>
                    <h1>{i18n.t(this.props.title)}</h1>
                </Col>
                <Col className='col-4'>
                    <Dropdown isOpen={this.state.dropdownOpen} toggle={this.toggleDropdown}>
                    <DropdownToggle className='pull-right' color="primary" href={item}>
                        <i className="icon ion-plus-round" ></i>&nbsp;&nbsp;{i18n.t('Add')}
                    </DropdownToggle>
                    </Dropdown>
                </Col>
            </Row>
        )
    }
}

export default TableHeader2;
