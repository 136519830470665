import React, {Component} from 'react';
import {Col, Container, Row} from "reactstrap";
import i18n from "../../views/Pages/Login/i18n";
import Form from "react-jsonschema-form";
import axios from "../../utils/Client";
import {withApollo} from "react-apollo";
import {withRouter} from 'react-router';


const schema = {
    "type": "object",
    "required": [
        "password",
        "passwordConfirm"
    ],
    "properties": {
        "userName": {
            "type": "string",
            "title": i18n.t('register.username')
        },
        "password": {
            "type": "string",
            "title": i18n.t('register.password'),
            "description": i18n.t('register.passwordPolicy')
        },
        "passwordConfirm": {
            "type": "string",
            "title": i18n.t('register.passwordConfirmation')
        }
    }
};

const format = /^(?=.*[0-9]+)(?=.*[A-Z]+)(?=.*[a-z]+)(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+).{8,}$/;

const uiSchema = {
    "userName": {
        "ui:readonly": true
    },
    "password": {
        "ui:widget": "password"
    },
    "passwordConfirm": {
        "ui:widget": "password"
    }
};

function validate(formData, errors) {
    if (formData.password !== formData.passwordConfirm) {
        if (errors) errors.passwordConfirm.addError(i18n.t('register.passwordConfirmationErr'));

    }
    if (!format.test(formData.password)) {
        if (errors) errors.password.addError(i18n.t('register.passwordPolicy'));
    }
    return errors;
}

function validatePassword(formData) {
    return (!(formData.password !== formData.passwordConfirm || !format.test(formData.password)));
}

class RegisterUser extends Component {
    constructor({match, ...props}) {
        super(props);
        const token = match.params.token;

        this.state = {
            token: token,
            data: {}
        };

        let form = this;
        axios.get(`${(window.config.consul.GRAPHQL_URL || window.GRAPHQL_URL || (typeof GRAPHQL_URL !== 'undefined' ? GRAPHQL_URL : '' ))}/oauth/spring-security-oauth-server/register/` + token, {
            headers: {'Content-Type': 'application/json'}
        }).then((response) => {
            if (response.status === 200) {
                form.state.data.userName = response.data.username;
                form.setState({data: form.state.data});
            }
        });

        this.onSubmit = this.onSubmit.bind(this);
        this.onChange = this.onChange.bind(this);
        this.loginUser = this.loginUser.bind(this);
        this.translate = this.translate.bind(this);
    }

    translate(obj) {

        if (obj && typeof obj === 'object' && !Array.isArray(obj)) {

            if (!obj.props) {

                for (let [key, value] of Object.entries(obj)) {

                    if (typeof value === "object") {
                        this.translate(value);
                    } else if (typeof value === "array") {

                        if (key === "enumNames") {
                            obj[key] = value.map(e => i18n.t(e));
                        } else {
                            obj[key] = value.map(e => this.translate(e));
                        }
                    } else if (typeof value === "string") {
                        if (key === "title" || key === "description") obj[key] = i18n.t(obj[key]);
                    }
                }
            }

        } else if (obj && Array.isArray(obj)) {

            obj.forEach((value, key) => {
                if (typeof value === 'object' && !Array.isArray(value)) {
                    this.translate(value);
                } else if (Array.isArray(value)) {

                    if (key === "enumNames") {
                        obj[key] = value.map(e => i18n.t(e));
                    } else {
                        obj[key] = value.map(e => this.translate(e));
                    }
                } else if (typeof value === "string") {
                    if (key === "title" || key === "description") obj[key] = i18n.t(obj[key]);
                }
            });
        }

        return obj;
    }

    onSubmit() {

        let user = {
            "username": this.state.data.userName,
            "password": this.state.data.password,
            "enabled": true,
            "locked": false
        };

        let userToken = this.state.token;
        if (this.state.data.password && this.state.data.password !== "" && validatePassword(this.state.data)) {
            axios.post(`${(window.config.consul.GRAPHQL_URL || window.GRAPHQL_URL || (typeof GRAPHQL_URL !== 'undefined' ? GRAPHQL_URL : '' ))}/oauth/spring-security-oauth-server/register/` + userToken, user)
                .then((response) => {
                    this.loginUser(this.state.data);
                });
        }
    }

    loginUser(data) {
        let form = this;
        axios.post(`${(window.config.consul.CORE_URL || window.CORE_URL || (typeof CORE_URL !== 'undefined' ? CORE_URL : '' ))}/login`, {
            userName: data.userName,
            password: data.password
        }).then(function (response) {
            let expDate = new Date();
            expDate.setTime(response.data.expirationDate);
            document.cookie = "token=true;expires=" + expDate.toUTCString() + ";path=/";
            localStorage.token = response.data.token;
            form.props.history.push("/");
            // EventLogger(data, "USER_SETUP_OK");
        }).catch((error) => {
            // ErrLogger(data, "USER_SETUP_ERROR", error)
        });
    }

    onChange({formData}) {
        this.state.data = formData;
    }

    render() {
        const mainClasses = 'app flex-row align-items-center';
        const translated = this.translate(schema);

        if (this.state.data.userName) {
            return (
                <div>
                    <div className='registration-logo'></div>
                    <div className={mainClasses}>
                        <div className="app-body create-user">
                            <Container fluid={true} className="login">
                                <Row className="justify-content-center">
                                    <Col md="8" style={{paddingLeft: 0, paddingRight: 0}}>
                                        <Row className='subheader'>
                                            <Col className='col-8'>
                                                <h1>{i18n.t('header.registerUsers')}</h1>
                                            </Col>
                                        </Row>
                                        <Row className="page-cnt">
                                            <Col className='col-8'>
                                                <Form
                                                    schema={translated}
                                                    uiSchema={uiSchema}
                                                    // widgets={widgets}
                                                    validate={validate}
                                                    formData={this.state.data}
                                                    onChange={this.onChange}
                                                >
                                                    <div className="buttons">
                                                        <button type="submit" className="btn btn-primary"
                                                                onClick={this.onSubmit}
                                                        >{i18n.t('header.register')}
                                                        </button>
                                                    </div>
                                                </Form>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Container>
                        </div>
                    </div>
                </div>
            );
        } else {
            return (<div>
                <div className='registration-logo'></div>
                <Row className='subheader'>
                    <Col className='col-12'>
                        <h1>{i18n.t('header.invalidReqiest')}</h1>
                    </Col>
                </Row>
            </div>);
        }
    }
}

export default withApollo(withRouter(RegisterUser));