import React, {Component, Fragment} from 'react';
import {OB_InvoiceQuery} from "../../queries/Queries";
import {Query} from "react-apollo";
import Loading from "../Loading";
import {Col, Row} from "reactstrap";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import {dateFormatter, downloadFormatter} from "../../utils/Formatters";
import i18n from "../../views/Pages/Login/i18n";
import AgreementsGrid from "../AgreementsGrid";

class PurchaseInvoice extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        return (
            <div className="manage-users">
                <Row className='subheader'>
                    <Col className='col-8'>
                        <h1>{i18n.t('purchase.title')}</h1>
                    </Col>
                </Row>
                <div className="page-cnt">
                    <Fragment>
                            <Query query={OB_InvoiceQuery} variables={{
                                "startRow": 0,
                                "offset": 100,
                                "where": this.props.businessPartnerId !== "" ? `businessPartner.id = '${this.props.businessPartnerId}' and salesTransaction = false and documentStatus = 'CO'` : "salesTransaction = false and documentStatus = 'CO'",
                                "orderBy": "documentNo"
                            }}>
                                {({ loading, error, data }) => {
                                    if (loading) return <Loading/>;
                                    if (error) return `Error! ${error.message}`;
                                    return (
                                        <BootstrapTable keyField={"processDefinitionId"} data={data.OB_invoice}
                                                        hover
                                                        bordered={false}
                                                        columns={[
                                                            {
                                                                dataField: 'billDoctype.documentType.name',
                                                                text: "Name",
                                                                sort: true,
                                                                formatter: (cell, row, index, formatExtraData) => {
                                                                    if (cell === 'AP Invoice') {
                                                                        return 'Self Bill Invoice';
                                                                    } else if (cell === 'AP CreditMemo') {
                                                                        return 'Self Bill Credit';
                                                                    }
                                                                }
                                                            },{
                                                                dataField: 'documentNo',
                                                                text: "Number",
                                                                sort: true,
                                                            },{
                                                                dataField: 'documentStatus',
                                                                text: "Status",
                                                                sort: true
                                                            },{
                                                                dataField: 'invoiceDate',
                                                                text: "Date",
                                                                formatter: dateFormatter,
                                                                sort: true
                                                            },{
                                                                formatExtraData: {
                                                                    version: 'v2',
                                                                    expression: `$split(transactionDocument.documentTemplateList[0].templateFilename, '.', 1)[0] & '/pdf'`
                                                                },
                                                                dataField: "transactionDocument.documentTemplateList.0.templateFilename",
                                                                text: "Download",
                                                                formatter: downloadFormatter,
                                                            }
                                                        ]}
                                                        pagination={paginationFactory({
                                                            page: 1,
                                                            sizePerPageList: [{
                                                                text: "10", value: 10
                                                            },{
                                                                text: "20", value: 20
                                                            },{
                                                                text: "30", value: 30
                                                            },],
                                                        })}
                                                        defaultSorted={[
                                                            {
                                                                dataField: 'invoiceDate',
                                                                order: 'desc'
                                                            }
                                                        ]}>
                                        </BootstrapTable>
                                    )
                                }}
                            </Query>
                    </Fragment>


                </div>
            </div>

        )
    }
}

export default PurchaseInvoice;