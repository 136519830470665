import React from 'react';
import { WithWizard } from 'react-albus';
import JsxParser from "react-jsx-parser";
import {Button} from "reactstrap";

const Navigation = () => (
  <WithWizard
    render={({ next, previous, step, steps }) => (
      <div>
      <JsxParser blacklistedAttrs={[]} showWarnings={true} bindings={{next, previous, step, steps}} components={{Button}} jsx={`
      <div className="example-buttons">
        {steps.indexOf(step) < steps.length - 1 && (
          <button className="btn-fluid margin-1-b" onClick={next}>
            Next
          </button>
        )}
        {steps.indexOf(step) > 0 && (
          <button className="btn-fluid btn-secondary" onClick={previous}>
            Back
          </button>
        )}
      </div>
      `}/> </div>
    )}
  />
);

export default Navigation;
