import React, { Component } from 'reactn';
import ReactDOM from 'react-dom';
import i18n from "../../views/Pages/Login/i18n";
import {
    Col,
    Row,
    Button,
    ButtonDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Modal,
    ModalHeader,
    ModalBody,
    Input,
    Form,
    FormGroup
} from "reactstrap";
import QueryDataGrid from "../QueryDataGrid/QueryDataGrid";
import TariffOverview from "../TariffOverview/TariffOverview";
import {Mutation, Query, ApolloConsumer} from "react-apollo";
import {
    getQuoteProductNamesQuery,
    getQuotesQuery,
    getQuoteStatusesQuery,
    getQuoteStatusFlowFromQuery,
    setQuoteStatusMutation
} from "../../queries/Queries";
import Loading from "../Loading";
import {Link, withRouter} from "react-router-dom";
import QuoteOverview from "../QuoteOverview";
import PaginatedQueryDataGrid from "../PaginatedQueryDataGrid";
import ExpiringAlert from "../ExpiringAlert";


class NoQuotes extends Component {
    constructor(props) {
        super(props)
    }
}
class QuotesGrid extends Component {
    constructor(props) {
        super(props)
        this.state = {
            dropdownOpen: false,
            modalOpen: false,
            reasonModalOpen: false,
            loading: false
        };
        this.toggle = this.toggle.bind(this);
        this.toggleModal = this.toggleModal.bind(this);
        this.toggleReasonModal = this.toggleReasonModal.bind(this);
    }

    toggle() {
        this.setState({
            dropdownOpen: !this.state.dropdownOpen
        });
    }
    toggleModal() {
        this.setState({ modalOpen: !this.state.modalOpen })
    }
    toggleReasonModal(statusToSet, setQuoteStatus, quoteId) {
        this.setState({ reasonModalOpen: !this.state.reasonModalOpen, statusToSet , setQuoteStatus, quoteId})
    }

    render() {
        const idCol = {
            dataField: 'id',
            text: i18n.t('quotes.id'),
            sort: true
        };
        const clientNameCol = {
            dataField: 'clientName',
            text: i18n.t('quotes.clientName'),
            sort: true
        };
        const ownerOrganizationNameCol = {
            dataField: 'ownerOrganizationName',
            text: i18n.t('quotes.salesOrganization'),
            sort: true,

        };
        const subOwnerOrganizationNameCol = {
            dataField: 'subOwnerOrganizationName',
            text: i18n.t('quotes.subSalesOrganization'),
            sort: true,
            hidden: !this.global.permissions.includes('Quotes.ViewSubbrokerColumn')
        };
        const postalCodeCol = {
            dataField: 'supplyAddress.postalCode',
            text: i18n.t('quotes.postalCode'),
            sort: true
        };
        const dateCol = {
            dataField: 'date',
            text: i18n.t('quotes.date'),
            sort: true,
            formatter: (cell, row) => {
                return new Date(cell.time).toLocaleDateString();
            }
        };
        const tariffCol = {
            dataField: 'tariff',
            text: i18n.t('quotes.tariffName'),
            sort: true
        };
        const meterPointCol = {
            text: i18n.t('quotes.meterPoint'),
            formatter: (cell, row) => {
                return row.products.map(e => e.meterPoint).join('\n')
            }
        };
        const productNameCol = {
            text: i18n.t('quotes.productName'),
            formatter: (cell, row) => {
                return row.products.map(e => e.name).join('\n')
            }
        };
        const statusCol = {
            dataField: 'status.name',
            text: i18n.t('quotes.status'),
            sort: true,
            formatter: cell => i18n.t(cell)
        };
        const statusReasonCol = {
            dataField: 'statusReason',
            text: i18n.t('quotes.reason'),
        };
        const termCol = {
            dataField: 'contractDuration',
            text: i18n.t('quotes.contractDuration'),
        };
        const aqCol = {
            text: i18n.t('Annual usage'),
            formatter: (cell, row) => {
                return row.products.map(e => e.annualQuantity).join('\n')
            }
        };
        const startDateCol = {
            dataField: 'startDate',
            text: i18n.t('quotes.startDate'),
            sort: true,
            formatter: (cell, row) => {
                return new Date(cell).toLocaleDateString();
            }
        };
        const actionCol = {
            formatter: (cell, row) => {
                switch (row.status.key) {
                    case "REVISED":
                        return <a style={{
                            cursor: "pointer",
                            fontSize: "150%"
                        }} color={"primary"}
                                  title={"Create Quote"}
                                  onClick={() => this.props.history.push(`/create-quote/${row.id}`)}>
                            <i className="icon ion-ios-copy"/>&nbsp;&nbsp;
                        </a>;
                    case "ACCEPTED":
                        return <a style={{
                            cursor: "pointer",
                            fontSize: "150%"
                        }} color={"primary"}
                                  title={"Create Contract"}
                                  onClick={() => this.props.history.push(`/create-agreement/${row.processInstanceId}`)}>
                            <i className="icon ion-android-bookmark"/>&nbsp;&nbsp;
                        </a>;
                    case "PENDING_PRICING":                        
                        return this.global.permissions.includes('Quotes.OpenPendingPricing') ?
                            <a style={{
                                cursor: "pointer",
                                fontSize: "150%"
                            }} color={"primary"}
                               title={"Price Quote"}
                               onClick={() => this.props.history.push(`/price-quote/${row.id}`)}>
                                <i className="icon ion-pricetags"/>&nbsp;&nbsp;
                            </a> : null;
                    case "INDUSTRY_CHECK_DONE":                        
                        return this.global.permissions.includes('Quotes.OpenIndustryCheckDone') || this.global.username === row.ownerUserId ?
                            <a style={{
                                cursor: "pointer",
                                fontSize: "150%"
                            }} color={"primary"}
                               title={"Price Quote"}
                               onClick={() => this.props.history.push(`/price-quote/${row.id}`)}>
                                <i className="icon ion-pricetags"/>&nbsp;&nbsp;
                            </a> : null;
                    default:
                        return null;
                }
            }
        };
        const columns = (window.config.consul.DYCE || window.DYCE || (typeof DYCE !== 'undefined' ? DYCE : '' )) ? [
            dateCol,
            clientNameCol,
            ownerOrganizationNameCol,
            subOwnerOrganizationNameCol,
            meterPointCol,
            productNameCol,
            termCol,
            aqCol,
            statusCol,
            startDateCol,
            actionCol
        ] : [
            idCol,
            clientNameCol,
            ownerOrganizationNameCol,
            subOwnerOrganizationNameCol,
            postalCodeCol,
            dateCol,
            tariffCol,
            meterPointCol,
            productNameCol,
            statusCol,
            statusReasonCol,
            actionCol
        ];
        return (
            <ApolloConsumer>
                {client => <div><Query query={getQuoteProductNamesQuery}>
                    {(resultProductNames) => <Query query={getQuoteStatusesQuery}>
                        {(result) => <Mutation mutation={setQuoteStatusMutation}>
                            {(setQuoteStatus) => (<PaginatedQueryDataGrid dataPath={'getQuotes.content'}
                                                             paginationPath={'getQuotes'}
                                                             keyField={'id'}
                                                             query={getQuotesQuery}
                                                             variables={{
                                                                 organizationId: this.props.organizationId,
                                                                 username: this.props.username
                                                             }}
                                                             saveFilter
                                                             filters={[
                                                                 {
                                                                     type: 'text',
                                                                     fields: ['clientName', 'postalCode', 'extraData.products'],
                                                                     placeholder: i18n.t('quotes.searchBarPlaceholder'),
                                                                     classes: "search",
                                                                     cols: 5
                                                                 },
                                                                 {
                                                                     type: 'dropdown',
                                                                     fields: ['extraData.products'],
                                                                     placeholder: "All Products",
                                                                     possibleValues: resultProductNames.loading ? [] : resultProductNames.data.getQuoteProductNames.map((name) => ({
                                                                         key: name,
                                                                         value: name
                                                                     })),
                                                                     cols: 2
                                                                 },
                                                                 {
                                                                     type: 'dropdown',
                                                                     fields: ['status.key'],
                                                                     placeholder: "All Statuses",
                                                                     possibleValues: result.loading ? [] : result.data.getQuoteStatuses.map(({key, name}) => ({
                                                                         key,
                                                                         value: i18n.t(name)
                                                                     })),
                                                                     cols: 2
                                                                 }
                                                             ]}

                                                             columns={columns}
                                                             defaultSorted={{
                                                                 dataField: 'date',
                                                                 order: 'desc'
                                                             }}
                                                             menuItems={async(obj) =>  {
                                                                 const { data } = await client.query({
                                                                             query: getQuoteStatusFlowFromQuery,
                                                                             variables: { from: {
                                                                                     key: obj.status.key
                                                                                 }}
                                                                         });

                                                                         return [...data.getQuoteStatusFlowFrom.map(({to}) => ({name: i18n.t(to.name), callback: () => {
                                                                                 if (!to.requireReason) {

                                                                                     return setQuoteStatus({
                                                                                         variables: {
                                                                                             quoteId: obj.id,
                                                                                             quoteStatus: {key: to.key},
                                                                                             statusReason: this.state.statusReason
                                                                                         }
                                                                                     })
                                                                                     .then((data) => {return data})
                                                                                     .catch((errors) => ReactDOM.render(<ExpiringAlert color="danger" message={"There was an error with your quote. Change of status is unsuccessful."} />, document.getElementById('alert')));

                                                                                 } else {
                                                                                     this.toggleReasonModal(to, setQuoteStatus, obj.id);
                                                                                     const promise = new Promise((resolve, reject) =>{
                                                                                         this.setState({statusPromiseResolve : resolve});
                                                                                     });

                                                                                     return promise;
                                                                                 }
                                                                             }})),
                                                                             {
                                                                                name:i18n.t('quoteStatus.detailsAction'), 
                                                                                href: (obj) => '/#/quotes/'+obj.id
                                                                            }
                                                                        ];
                                                                     }
                                                             }
                                                             noDataView={<NoQuotes/>}
                        />)}
                        </Mutation>}
                    </Query>
                    }
                </Query>
                    <Modal isOpen={this.state.reasonModalOpen}
                           toggle={this.toggleReasonModal}
                           className="quote-preview-model">
                        <ModalHeader
                            toggle={this.toggleReasonModal}>
                            Enter reason:
                        </ModalHeader>
                        <ModalBody>
                            <Form>
                                <FormGroup>
                                    <Input type={"select"}
                                           value={this.state.statusReason}
                                           onChange={(e) => this.setState({statusReason: e.target.value})}>
                                        <option></option>
                                        <option
                                            value={"Uncompetitive price"}>Uncompetitive price</option>
                                        <option
                                            value={"Unclear conditions"}>Unclear conditions</option>
                                        <option
                                            value={"Choice of another supplier"}>Chose another supplier</option>
                                        <option
                                            value={"Objection"}>Objection</option>
                                    </Input>
                                </FormGroup>
                            </Form>

                            <Button color='primary'
                                    onClick={() => {
                                        this.state.setQuoteStatus({
                                            variables: {
                                                quoteId: this.state.quoteId,
                                                quoteStatus: this.state.statusToSet, //TODO fix closure  {key: key.to}
                                                statusReason: this.state.statusReason
                                            }
                                        }).then(result => this.state.statusPromiseResolve());
                                        this.toggleReasonModal(null, null, null);
                                        this.setState({statusReason: null});
                                    }}>Submit</Button>

                            <Button color='secondary'
                                    onClick={() => {
                                        this.toggleReasonModal(null,null,null);
                                        this.setState({statusReason: null});
                                    }}>Cancel</Button>
                        </ModalBody>
                    </Modal>
                </div>
                }

            </ApolloConsumer>
        )
    }
}

export default withRouter(QuotesGrid);
