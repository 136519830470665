import React from 'react';
import {graphql, withApollo} from "react-apollo";
import gql from "graphql-tag";
import {withRouter} from 'react-router';
import {Query} from 'react-apollo';
import i18n from '../../views/Pages/Login/i18n';
import Loading from "../../components/Loading"
import {getBusinessPartnerQuery} from "../../queries/Queries";
import CreateReading from "../../views/CreateReading";
import BasicQueryComponent from "../BasicQueryComponent";
import _ from "underscore";
import {
    Col,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Modal,
    ModalBody,
    ModalHeader,
    Row
} from 'reactstrap';
import CreateTask from "../../views/CreateTask";


class BusinessPartnerHeader extends BasicQueryComponent {
    constructor(props) {
        super(props);

        this.toggleDropdown = this.toggleDropdown.bind(this);
        this.toggleReadingModal = this.toggleReadingModal.bind(this);
        this.toggleTaskModal = this.toggleTaskModal.bind(this);
        this.state = {
            dropdownOpen: false,
            showCreateReadingModal: false,
            showCreateTaskModal: false
        };
    }



    toggleDropdown() {
        this.setState({
            dropdownOpen: !this.state.dropdownOpen
        });
    }
    toggleReadingModal() {
        this.setState({
            showCreateReadingModal: !this.state.showCreateReadingModal
        });
    }
    toggleTaskModal() {
        this.setState({
            showCreateTaskModal: !this.state.showCreateTaskModal
        });
    }
    render() {
        let variables;
        if (this.props.match.params["variables"]) {
            variables = JSON.parse(this.props.match.params["variables"][this.props.queryKey]);
        } else {
            variables = this.props.variables;
            if (variables) {
                const compiled = _.template(JSON.stringify(variables));
                variables = JSON.parse(compiled(this.props.match.params));
            }
        }
        return (<Query query={gql`${this.props.queries[this.props.queryKey]}`} variables={variables}>
            {(result) => {
                if (result.loading) return (<Loading />);//<Loading/>;
                if (result.error) return <div>{result.error} </div>;
                const { data } = result;
                if(Object.keys(data).length == 0){
                    this.props.client.query({query: gql`${this.props.queries[this.props.queryKey]}`, variables: variables});
                    return (<Loading />);
                }
                let expr = null;
                if (this.props.expression) {
                    const compiled = _.template(this.props.expression);
                    expr = compiled(this.props.match.params);
                }
                const expression = expr ? jsonata(expr) : null;
                const transformedData = expression ? expression.evaluate(data) : data;
                const dataWithParents = this.setParents(transformedData, []);

                const { children } = this.props;
                const { queries, queryKey, variables, title, ...newProps } = this.props;
                const dataObj = this.getFieldValue(data, this.props.dataPath);
                return (
                    <div className='bp busines-parnter-cnt'>
                        <Row className='subheader'>
                            <Col className='col-8'>
                                <h1>{dataObj.name}</h1>
                            </Col>
                            <Col className='col-4'>
                                <Dropdown isOpen={this.state.dropdownOpen} toggle={this.toggleDropdown} >
                                    <DropdownToggle className='pull-right' color='primary'>
                                        <i className="icon ion-plus-round"></i>&nbsp;&nbsp;{i18n.t('header.new')}
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        <DropdownItem onClick={()=>this.setState({showCreateReadingModal:true})}>Reading</DropdownItem>
                                        <DropdownItem onClick={()=>this.setState({showCreateTaskModal:true})}>Task</DropdownItem>
                                        <DropdownItem onClick={()=>this.props.history.push('/start/bp/'+dataObj.id)}>Process</DropdownItem>
                                    </DropdownMenu>
                                </Dropdown>
                            </Col>
                        </Row>
                        <Modal isOpen={this.state.showCreateReadingModal} toggle={this.toggleModal} size={"large"}  style={{"maxWidth": "500px", "width": "90%"}}>
                            <ModalHeader toggle={this.toggleReadingModal}>Create Reading</ModalHeader>
                            <ModalBody>
                                <CreateReading estateList={dataObj.mDMBPEstateList} toggle={this.toggleReadingModal}/>
                            </ModalBody>
                        </Modal>
                        <CreateTask modalOpen={this.state.showCreateTaskModal} onClose={this.toggleTaskModal} customer={dataObj.id}/>
                    </div>
                );
            }
            }
        </Query>);
            }
}

export default graphql(getBusinessPartnerQuery, {
    name: 'getBusinessPartner',
    options: props => ({
        variables: { startRow: 0, offset: 10, where: "id = '" + props.match.params.id + "'", orderBy: "name" }
    }),
})(withApollo(withRouter(BusinessPartnerHeader)));