    import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import {
    createTaskMutation,
    getTasksQuery,
} from "../../queries/Queries";
import ExpiringAlert from "../../components/ExpiringAlert";
import gql from "graphql-tag";
import schema from "../../schemas/task.schema.js"
import uischema from "../../schemas/task.uischema.js"
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { Mutation } from "react-apollo";
import Form from "react-jsonschema-form";
import i18n from "../Pages/Login/i18n";
import TasksAttachments from "../../components/TasksAttachments";
import moment from "moment";
import { withRouter } from 'react-router';

class CreateTask extends Component {
   
    constructor(props) {
        super(props);
        this.minimizeTask = this.minimizeTask.bind(this);
        this.state = {
            isMinimized : false
        }
    }
    minimizeTask(){
         this.setState({
            isMinimized: !this.state.isMinimized
        });
    }


    render() {

        return (
            <Modal isOpen={this.props.modalOpen} toggle={this.props.onClose} size={"large"} modalClassName={this.state.isMinimized? "minimized" : ""} className="create-taks-modal">
                <ModalHeader toggle={this.props.onClose}>
                
                <button className="btn btn-primary btn-minimize" onClick={this.minimizeTask}><i className={this.state.isMinimized? "icon ion-android-arrow-up" : "icon ion-android-arrow-down"}></i></button>
                </ModalHeader>
                <ModalBody>
                    <div>
                    <div className="task-labels clearfix">
                        <span className="pull-left">{ i18n.t('tasks.addTask') }</span>
                        {/*<span className="pull-right">by David Rios on 25/05/2018</span>*/}
                    </div>
                    <Mutation mutation={gql`${createTaskMutation}`} refetchQueries={[{query: getTasksQuery}]}>
                        {(createTask, {data}) => (<Form schema={schema}
                                                        uiSchema={uischema}
                                                        onSubmit={({formData}) => {
                                                            createTask({ variables: { task:{
                                                                        name:formData.name,
                                                                        delegationState : "pending",
                                                                        dueDate:formData.dueDate,
                                                                        description: formData.description,
                                                                        assignee: formData.assignee,
                                                                        priority: formData.priority,
                                                                        variables : [
                                                                            {
                                                                                name: "process",
                                                                                type: "string",
                                                                                value : formData.process
                                                                            },
                                                                            {
                                                                                name: "customer",
                                                                                type: "string",
                                                                                value : formData.customer
                                                                            },
                                                                            {
                                                                                name: "status",
                                                                                type: "string",
                                                                                value : formData.status
                                                                            }
                                                                        ]
                                                                    }}}).then((response)=>{
                                                                        this.child.uploadAttachments(response.data.createTask);
                                                                        this.props.onClose();
                                                                        ReactDOM.render(<ExpiringAlert color="success" message={i18n.t('tasks.successfulCreate')}  />, document.getElementById('alert').appendChild(document.createElement("div")));
                                                            });

                                                        }}
                                                        formData={{dueDate:"", customer: this.props.match.params.param}}>
                                                        <TasksAttachments ref={instance => { this.child = instance; }} uploadImmediately={false} />
                                                         <div className="buttons">
                                                            <button type="submit" className="btn btn-primary">Add Task</button>
                                                            <button type="button" className="btn btn-secondary" onClick={() => this.props.onClose()}>Cancel</button>
                                                            </div>
                                                    </Form>)
                        }
                    </Mutation>
                    </div>
                </ModalBody>
            </Modal>)

    }
}


export default withRouter(CreateTask);
