import ImageSelector from './ImageSelector';
import ImageSelectorV2 from './ImageSelectorV2';
import QueryTypeAheadWidget from "./QueryTypeAheadWidget";
import QuertyAsyncTypeAheadWidgetEIK from "./QuertyAsyncTypeAheadWidgetEIK";
import QueryTypeAheadWidget2 from "./QueryTypeAheadWidget2";
import CheckboxWidget from "./ChekboxWidget";
import FileSelector from "./FileSelector";
import DataView from "./DataView";
import InputField from "./InputField";
import DatePicker from "../../components/FormWidget/DatePicker";
import DownloadContractWidget from "./DownloadContractWidget";
import QuertyAsyncTypeAheadWidgetPointOfSupply from "./QuertyAsyncTypeAheadWidgetPointOfSupply";
import QuertyAsyncTypeAheadWidgetPointOfSupplyArea from "./QuertyAsyncTypeAheadWidgetPointOfSupplyArea";
import UrnBuilder from "./UrnBuilder";
import IdentificatorMeasurementField from "./IdentificatorMeasurementField";
import Recaptcha from "./Recaptcha";
import StringField from "./form-overrides/StringField";
import SchemaField from "./form-overrides/SchemaField";
import ObjectField from "./form-overrides/ObjectField";
import ArrayField from "./form-overrides/ArrayField";
import CardNumberField from "./CardNumberField";
import SvgImageContainer from "./SvgImageContainer";
import PhoneField from "./PhoneField";
import RedirectWidget from "./RedirectWidget";

const widgets = {
  imageSelector : ImageSelector,
  imageSelectorV2 : ImageSelectorV2,
  queryTypeAheadWidget2 : QueryTypeAheadWidget2,
  quertyAsyncTypeAheadWidgetEIK : QuertyAsyncTypeAheadWidgetEIK,
  checkbox : CheckboxWidget,
  inputField: InputField,
  datePicker: DatePicker,
  downloadContractWidget: DownloadContractWidget,
  quertyAsyncTypeAheadWidgetPointOfSupply: QuertyAsyncTypeAheadWidgetPointOfSupply,
  quertyAsyncTypeAheadWidgetPointOfSupplyArea: QuertyAsyncTypeAheadWidgetPointOfSupplyArea,
  buildUrn: UrnBuilder,
  IdentificatorMeasurementField: IdentificatorMeasurementField,
  recaptcha: Recaptcha,
  svgImageContainer: SvgImageContainer,
  phoneField: PhoneField,
  redirect: RedirectWidget
};

const fields = {
  StringField: StringField,
  SchemaField: SchemaField,
  ObjectField: ObjectField,
  ArrayField: ArrayField,
  dataView: DataView,
  fileSelector : FileSelector,
  queryTypeAheadWidget : QueryTypeAheadWidget,
  cardNumberField: CardNumberField
};

export default {widgets, fields};
