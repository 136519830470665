import React, {useEffect} from "react";
import LoadingImage from "../../images/loading-grey-small.svg";

function RedirectWidget(props) {
    const {
        options,
        value,
        required,
        disabled,
        readonly,
        autofocus,
        onBlur,
        onFocus,
        onChange,
        type,
        id,
    } = props;

    const url = props.schema.url;
    const message = props.schema.message ? props.schema.message : '';
    const location = props.schema.title ? props.schema.title : '';
    const delay = props.schema.milliseconds ? props.schema.milliseconds : 5000;
    useEffect(() => {
        setTimeout(() => {
            window.location.href = url
        }, delay)
    });

    return <div>
        <div className="row"><h2>{message}</h2></div>
        <div className="row"><h6>Пренасочване към {location}</h6>
            <img src={LoadingImage}/>
        </div>
    </div>;
}

export default RedirectWidget;