import React from "react";
import PropTypes from "prop-types";
import gql from "graphql-tag";
import ReactDOM from "react-dom";
import {AsyncTypeahead} from "react-bootstrap-typeahead";
import {Query, withApollo} from "react-apollo";
import ExpiringAlert from "../../components/ExpiringAlert";
import 'react-bootstrap-typeahead/css/Typeahead.css';

class AsyncSelect extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            filter: props.value || "~"

        }
    }

    render() {
        const cities = gql`query ($city: String){
        city_point_of_supply(where: {city: {region: {_ilike: $city}}}) {
            city {
              city_id
              name
              region
            }
            point_of_supply {
              name
            }
          }
        }`;

        return <Query query={cities} variables={{city: this.state.filter}}>
            {(result) => {

                if (result.error) ReactDOM.render(<ExpiringAlert color="danger"
                                                                 message={result.error.message}/>, document.getElementById('alert').appendChild(document.createElement("div")));
                if (result.data && Object.keys(result.data).length) {
                    let areasCollection = result.data.city_point_of_supply.map((el) => el.city[0].region);
                    areasCollection = areasCollection.filter((el,i) => areasCollection.indexOf(el) === i);
                    // const d = result.data.city_point_of_supply[0];
                    const d = areasCollection;
                    // this.props.onInit(d);
                    result.collection = areasCollection;
                }
                return <AsyncTypeahead id={this.props.path.join("_") + "search_area"} ref="typeahead3" isLoading={this.state.isLoading}
                                       cssclassName="md-12"
                                       options={!result.loading ? result.collection : []}
                                       labelKey={(x) => { return x}}

                                       placeholder={this.props.placeholder}
                                       filterBy={() => true}
                                       clearButton
                                       selectHintOnEnter={true}
                                       onSearch={(query) => {
                                           this.setState({filter: '%' + query + '%'});
                                       }}
                                       onChange={(selected) => {
                                           this.props.onChange(selected[0] ? selected[0] : "")
                                       }}
                                       defaultInputValue={this.props.value}
                                       selected={this.props.value ? [this.props.value] : []}
                />
            }
            }
        </Query>
    }

}


function QuertyAsyncTypeAheadWidgetPointOfSupply(props) {
    const {
        options,
        value,
        required,
        disabled,
        readonly,
        autofocus,
        onBlur,
        onFocus,
        onChange,
        onPropertyChange,
        id,
    } = props;

    const details = gql`query MyQuery ($city: String) {
            client (where: {vat_number:{_eq: $city}}) {
                name
                address
                email
                fax
                first_name
                last_name
                middle_name
                phone
                city {
                    city_id
                    name
                    region
                }
                city_id
                vat_number
            }
        }`

    function loadOffers(event) {
        const selectedArea = event;
        const contextPath = [...props.path];
        contextPath.splice(-1,1);
        let obj = getValue(this.state, contextPath)
        if (props.path[0]==="correspondence_address") {
            setValue(this.state, contextPath, {...obj, correspondence_address_area: selectedArea})
        } else {
            setValue(this.state, contextPath, {...obj, area: selectedArea})
        }
        this.setState({...this.state});
    }

    function hasValue(obj, path) {
        return obj[path[0]] && (path.length === 1 || hasValue(obj[path[0]], path.slice(1)));
    }
    function getValue(obj, path) {
        return (!obj[path[0]] || path.length === 1) ? obj[path[0]] : getValue(obj[path[0]], path.slice(1));
    }
    function setValue(obj, path, value) {
        if (path.length === 1 && obj) {
            obj[path[0]] = value;
        } else {
            setValue(obj[path[0]], path.slice(1), value);
        }
    }

    return (
        <AsyncSelect  {...props} value={value} onInit={(data) => {if (!this.state.offer && hasValue(this.state, props.path)) loadOffers.call(this, data)}}
                     onChange={(event) => {
                         onChange(event ? event : null);
                         //if (event) {
                             loadOffers.call(this, event);
                         //}
                     }}/>
    );
}

if (process.env.NODE_ENV !== "production") {
    QuertyAsyncTypeAheadWidgetPointOfSupply.propTypes = {
        schema: PropTypes.object.isRequired,
        id: PropTypes.string.isRequired,
        placeholder: PropTypes.string,
        options: PropTypes.shape({
            rows: PropTypes.number,
        }),
        value: PropTypes.string,
        required: PropTypes.bool,
        disabled: PropTypes.bool,
        readonly: PropTypes.bool,
        autofocus: PropTypes.bool,
        onChange: PropTypes.func,
        onBlur: PropTypes.func,
        onFocus: PropTypes.func,
    };
}
export default QuertyAsyncTypeAheadWidgetPointOfSupply;
