function Validate(formData, errors) {
    if (formData.file_upload_validations) {
        let groupKey = Object.keys(formData.file_upload_validations)[0];
        let keys = groupKey.split('.');
        let files = formData[keys[0]][keys[1]];
        if (files) {
            for (let i = 0; i < files.length; i++) {
                if (Object.keys(files[i]).length > 0) {
                    if (files[i].hasOwnProperty('isUploading')) {
                        errors[keys[0]].addError(files[i].isUploading);
                    }
                    return errors;
                }
            }
            let message = formData.file_upload_validations[groupKey];
            errors[keys[0]].addError(message);
        }
    }
    return errors;
}

export default Validate;