import React from "react";
import PropTypes from "prop-types";
import {Input} from "reactstrap";
import moment from "moment";


function MonthPicker(props) {

    const {
        id,
        options,
        placeholder,
        value,
        required,
        disabled,
        readonly,
        autofocus,
        onChange,
        onBlur,
        onFocus,
        formContext,
    } = props;


    const _onChange = (event) => {
        return onChange(event.target.value === "" ? options.emptyValue : event.target.value);
    };

    const maxMonthsBefore = options.monthsBefore || 18;
    const maxMonthsAfter = options.monthsAfter || 0;

    const minmonth = moment().add(-maxMonthsBefore, "months");
    const maxmonth = moment().add(maxMonthsAfter, "months");
    let month = maxmonth;
    const months = [];
    while (month > minmonth) {
        months.push(month.clone());
        month.add(-1, "month");
    }
    let pickerLang = {
        months: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
        , from: 'From', to: 'To'
    }
        , mrange = {}

    let makeText = m => {
        if (m && m.year && m.month) return (pickerLang.months[m.month-1] + '. ' + m.year)
        return '?'
    }

    return (
        <Input type="select" name="select" id="exampleSelect" onChange={_onChange} >
            <option disabled={true} selected={true}>Please select a month</option>
            {months.map(m => <option value={options.endOfMonth ? m.endOf('month').format() : m.startOf('month').format()}>{makeText({month: m.get("month")+1, year: m.get("year")})}</option>)}
        </Input>
    )

}

MonthPicker.defaultProps = {
    autofocus: false,
    options: {},
};

if (process.env.NODE_ENV !== "production") {
    MonthPicker.propTypes = {
        schema: PropTypes.object.isRequired,
        id: PropTypes.string.isRequired,
        placeholder: PropTypes.string,
        options: PropTypes.shape({
            rows: PropTypes.number,
        }),
        value: PropTypes.string,
        required: PropTypes.bool,
        disabled: PropTypes.bool,
        readonly: PropTypes.bool,
        autofocus: PropTypes.bool,
        onChange: PropTypes.func,
        onBlur: PropTypes.func,
        onFocus: PropTypes.func,
    };
}
export default MonthPicker;
