import React from "react";
import {Input} from "reactstrap";
import PropTypes from "prop-types";

function CheckboxWidget(props) {
    const {
        options,
        value,
        required,
        disabled,
        readonly,
        autofocus,
        onBlur,
        onFocus,
        onChange,
        id,
    } = props;
    // Generating a unique field name to identify this set of radio buttons
    const name = Math.random().toString();
    const { enumOptions, enumDisabled, inline } = options;
    // checked={checked} has been moved above name={name}, As mentioned in #349;
    // this is a temporary fix for radio button rendering bug in React, facebook/react#7630.
    return (
        <div className="field-checkbox-group" id={id}>
            {enumOptions.map((option, i) => {
                const checked = option.value === value;
                const itemDisabled =
                    enumDisabled && enumDisabled.indexOf(option.value) !== -1;
                const disabledCls =
                    disabled || itemDisabled || readonly ? "disabled" : "";
                const radio = (
                    <span>
            <Input
                type="checkbox"
                checked={checked}
                id={name + "_" + i}
                name={name}
                required={required}
                value={option.value}
                disabled={disabled || itemDisabled || readonly}
                autoFocus={autofocus && i === 0}
                onChange={_ => {onChange(option.value === arguments[0].value ? null : option.value)}}
                onBlur={onBlur && (event => onBlur(id, event.target.value))}
                onFocus={onFocus && (event => onFocus(id, event.target.value))}
            />
            <label htmlFor={name + "_" + i} className="css-label">
                  <i className="icon ion-android-checkbox" />
                &nbsp; &nbsp;{options.links && options.links[i] && <a target="_blank" href={options.links[i]}>{option.label}</a> || option.label}{required ? '*' : ''}
            </label>
          </span>
                );

                return inline ? (
                    <label key={i} className={`checkbox-inline ${disabledCls}`}>
                        {radio}
                        {options.imgs && (<img alt={option.label} width={10} height={10} src={options.imgs[i]} style={{margin: "10px"}} />)}
                    </label>
                ) : (
                    <div key={i} className={`checkbox ${disabledCls}`}>
                        <label>{radio}
                            {options.imgs && (<img alt={option.label} width={10} height={10} src={options.imgs[i]} />)}
                        </label>

                    </div>
                );
            })}
        </div>
    );
}

CheckboxWidget.defaultProps = {
    autofocus: false,
    options: {
        inline: false
    }
};

if (process.env.NODE_ENV !== "production") {
    CheckboxWidget.propTypes = {
        schema: PropTypes.object.isRequired,
        id: PropTypes.string.isRequired,
        placeholder: PropTypes.string,
        options: PropTypes.shape({
            rows: PropTypes.number,
        }),
        value: PropTypes.string,
        required: PropTypes.bool,
        disabled: PropTypes.bool,
        readonly: PropTypes.bool,
        autofocus: PropTypes.bool,
        onChange: PropTypes.func,
        onBlur: PropTypes.func,
        onFocus: PropTypes.func,
    };
}

export default CheckboxWidget;