import React, {Component} from 'reactn';
import {withRouter} from 'react-router';
import ReactDOM from 'react-dom';
import {compose, graphql, Mutation, withApollo} from "react-apollo";
import {branch, renderComponent} from "recompose";
import {
    Breadcrumb,
    BreadcrumbItem,
    Col,
    Form,
    Input,
    Nav,
    NavItem,
    NavLink,
    Row,
    TabContent,
    TabPane
} from 'reactstrap';
import i18n from '../Pages/Login/i18n';
import classnames from "classnames";
import PasswordInputMatch from "../../components/PasswordInputMatch";
import UserDetails from "../UserDetails/UserDetails";
import Loading from '../../components/Loading';
import ExpiringAlert from '../../components/ExpiringAlert';
import axios from "../../utils/Client";
import {
    changePasswordMutation,
    createUserRoles,
    deleteUserRoles,
    getRolesForUserQuery,
    getUserQuery,
    getUsers,
    organizationsQuery,
    updateUserMutation
} from "../../queries/Queries";


class EditUser extends Component {

    constructor(props) {
        super(props);

        this.state = {user : this.prepareUser(this.props.getUser.getUser,this.props.getRolesForUser.getRolesForUser)};
        this.state.activeTab = "1";
        this.handleSubmit = this.handleSubmit.bind(this);
        this.toggle = this.toggle.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handlePasswordChange = this.handlePasswordChange.bind(this);

    }
    componentWillReceiveProps(nextProps) {
        this.setState({user : this.prepareUser(nextProps.getUser.getUser,nextProps.getRolesForUser.getRolesForUser)});

    }
    prepareUser(user, roles){
        let newUser = {...this.props.getUser.getUser};
        delete newUser.__typename;
        newUser.phoneNumbers = newUser.phoneNumbers ? newUser.phoneNumbers.map((o) => {
            var pn = {...o};
            delete pn.__typename;
            return pn;
        }) : [];
        newUser.organizations = newUser.organizations ? newUser.organizations.map((o) => {delete o.ultimateParent; return {value : o, label: o.name}}) : [];
        newUser.organizations.forEach(({value}) => delete value.ultimateParent);
        // if(this.props.showSystems) {
            newUser.userSystems = newUser.userSystems ? newUser.userSystems.map((o) => {
                let us = {...o};
                us.system = {...o.system};
                delete us.__typename;
                delete us.system.__typename;
                return us;
            }) : [];
        // } else {
        //     delete newUser.userSystems;
        // }
        newUser.roles = roles.reduce((result, role) => ({...result, [role]: true}), {});
        return newUser;
    }

    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        }
    }

    handleChange(name, value, name1, value1) {
        if(name1){
            this.setState({ user: { ...this.state.user, [name]: value, [name1]: value1 } });
        } else {
            this.setState({ user: { ...this.state.user, [name]: value} });
        }
    }

    handleSubmit(updateUserMutation, isAdmin, event) {
        // if(document.getElementById('user-details').reportValidity()) {
            var user = {...this.state.user};
            delete user.roles;
            delete user.avatar;
            user.organizations = user.organizations.map(({value}) => value);
            const submitRoles = () => {
                var addRoles = [];
                var deleteRoles = [];
                for (var role in this.state.user.roles) {
                    if (this.state.user.roles[role]) {
                        addRoles.push(role.toUpperCase());
                    } else {
                        deleteRoles.push(role.toUpperCase());
                    }
                }
                this.props.createUserRoles({
                    variables: {
                        username: this.state.user.username,
                        roles: addRoles
                    },
                    refetchQueries: [{query: getRolesForUserQuery, variables: {username: this.state.user.username}},
                        {query: getUsers},
                        {query: getUserQuery, variables: {username: this.props.match.params.username}},
                        {query: getUserQuery, variables: {}}, {query: organizationsQuery}]
                }).then(({data}) => {
                    if (deleteRoles.length > 0) {
                        this.props.deleteUserRoles({
                            variables: {
                                username: this.state.user.username,
                                roles: deleteRoles
                            },
                            refetchQueries: [{query: getRolesForUserQuery, variables: {username: this.state.user.username}},
                                {query: getUsers},
                                {query: getUserQuery, variables: {username: this.props.match.params.username}},
                                {query: getUserQuery, variables: {}}, {query: organizationsQuery}]
                        }).then(({data}) => {
                            console.log('got data', data);
                        }).catch((error) => {
                            ReactDOM.render(<ExpiringAlert color="danger"
                                                           message={error.message}></ExpiringAlert>, document.getElementById('alert').appendChild(document.createElement("div")));
                            console.log('there was an error sending the query', error);
                        });
                    }
                }).catch((error) => {
                    ReactDOM.render(<ExpiringAlert color="danger"
                                                   message={error.graphQLErrors[0].message}></ExpiringAlert>, document.getElementById('alert').appendChild(document.createElement("div")));
                    console.log('there was an error sending the query', error);
                });
            }

            const rolesInUserMutation = this.props.rolesInUserMutation;
            if (rolesInUserMutation) {
                console.log("Adding roles to user mutation");
                var userRoles = [];
                for (var role in this.state.user.roles) {
                    this.state.user.roles[role] && userRoles.push(role.toUpperCase());
                }
                user.roles = userRoles;
            }

            const updateUser = () => {
                updateUserMutation({
                    variables: {
                        user: user
                    },
                    refetchQueries: isAdmin
                        ?  [{query: getUsers}, {query: getUserQuery, variables: {username : this.props.match.params.username} }, {query: getUserQuery, variables: {} }, {query: organizationsQuery}]
                        : [{query: getUserQuery, variables: {username : this.props.match.params.username} }, {query: getUserQuery, variables: {} }, {query: organizationsQuery}]
                }).then(({data}) => {
                    if(isAdmin){
                        submitRoles();
                    }

                    ReactDOM.render(<ExpiringAlert color="success" message={i18n.t('users.successfulEdit')}  />, document.getElementById('alert').appendChild(document.createElement("div")));
                    console.log('got data', data);
                }).catch((error) => {
                    ReactDOM.render(< ExpiringAlert color="danger" message={error.message} />, document.getElementById('alert').appendChild(document.createElement("div")));
                    console.log('there was an error sending the query', error);
                });
            }
            if(this.state.user.avatar) {
                const data = new FormData();
                data.append('image', this.state.user.avatar);
                const config = {
                    headers: {'Authorization': "Bearer " + localStorage.token}
                };
                axios.post(`${(window.config.consul.CORE_URL || window.CORE_URL || (typeof CORE_URL !== 'undefined' ? CORE_URL : '' ))}/images`, data, config).then(response => {
                    user.avatarUrl = `${(window.config.consul.CORE_URL || window.CORE_URL || (typeof CORE_URL !== 'undefined' ? CORE_URL : '' ))}/images/` + response.data;
                    updateUser();
                    if(this.props.currentUser.username === this.state.user.username){
                        this.props.setCurrentUser({...this.state.user, avatarUrl: user.avatarUrl });
                    }
                });
            } else {
                updateUser();
            }
            this.props.history.goBack();
            event.preventDefault();
        // }
    }

    handlePasswordChange(event) {
        var user = {...this.state.user};
        delete user.roles;
        delete user.avatar;
        delete user.organizations;
        delete user.roles;
        //user.organizations = user.organizations.map((o) => ({ organizationId: !Number.isInteger(o.value) ? 0 : o.value, name: o.label }));

        this.props.changePasswordMutation({
            variables: {
                user: user
            }
        }).then(({ data }) => {
            console.log('got data', data);
            ReactDOM.render(<ExpiringAlert color="success" message={i18n.t('users.successfulEdit')} />, document.getElementById('alert').appendChild(document.createElement("div")));
            this.props.history.goBack();
        }).catch((error) => {
            ReactDOM.render(<ExpiringAlert color="danger" message={error.message} />, document.getElementById('alert').appendChild(document.createElement("div")));
            console.log('there was an error sending the query', error);
        });

        event.preventDefault();
    }

    render() {
        const { initialValid } = this.props;

        const isAdmin = this.global.permissions.includes('Users.AssignRoles');

        return (
            <div className='edit-users'>
                <Row className='subheader'>
                    <Col className='col-8'>
                        <h1>{i18n.t('header.editUsers')}</h1>
                        
                    </Col>
                    <Col className='col-4'>
                        <Breadcrumb>
                            <BreadcrumbItem><i className="icon ion-android-home"></i><a href="#">Home</a></BreadcrumbItem>
                            {isAdmin && <BreadcrumbItem><a href="#/users">Users</a></BreadcrumbItem>}
                            <BreadcrumbItem active>{i18n.t('header.editUsers')}</BreadcrumbItem>
                        </Breadcrumb>
                    </Col>
                </Row>
                <div className='bp-nav-items'>
                    <Nav tabs>
                        <NavItem><NavLink className={classnames({ active: this.state.activeTab === '1' })}
                            onClick={() => { this.toggle('1'); }}>{i18n.t('createUsers.profile')}</NavLink></NavItem>
                        {this.global.permissions.includes("Users.ChangeAnyPassword") && <NavItem><NavLink className={classnames({ active: this.state.activeTab === '2' })}
                            onClick={() => { this.toggle('2'); }}>{i18n.t('createUsers.password')}</NavLink></NavItem>}
                    </Nav>
                    </div>
                    <TabContent activeTab={this.state.activeTab} className='bp-tab-content'>
                        <TabPane tabId="1">
                            <Row>
                                <Col xs="12" sm="12" md="10" lg="8" xl="6">
                                    <UserDetails user={this.state.user} onChange={this.handleChange}
                                                 organizationRequired={this.props.organizationRequired}
                                                 showPasswordField={false} allowedRoles={this.props.allowedRoles}
                                                 showSystems={this.props.showSystems} defaultOrganization={this.props.match.params.id}
                                                 userRole={this.props.currentUser}
                                    />
                                </Col>
                            </Row>
                            <Row className="btn-cnt">
                                <Col xs="12" sm="12" className="spacer"></Col>
                                <Col xs="4" sm="4" md="2" lg="2" xl="2"></Col>
                                <Col xs="8" sm="8">
                                    <Mutation mutation={this.props.updateUserMutation} refetchQueries={[{query: getUsers}]}>
                                    {(updateUserMutation, data) =>  <Input className='btn btn-primary' type='submit'
                                               value={i18n.t('header.editUser')}
                                               onClick={(event) => this.handleSubmit(updateUserMutation, isAdmin, event)}/>
                                    }
                                    </Mutation>
                                    <Input className="btn btnXsecondary" type="submit" value={i18n.t('header.cancel')} onClick={() => this.props.history.goBack()} />
                                </Col>
                            </Row>
                        </TabPane>
                        <TabPane tabId="2">
                            <Form>
                                <PasswordInputMatch password={this.state.user.password} handleChange={this.handleChange} handleConfirm={this.handlePasswordChange} />
                            </Form>
                        </TabPane>
                    </TabContent>
                
            </div>
        )

    }
}


const LoadingComponent = () => <Loading />

let withRouter2 = withRouter(EditUser);
let withApollo1 = withApollo(withRouter2);
export default compose(
    graphql(getUserQuery, {
        name: 'getUser',
        options: props => ({
            variables: { username: props.match.params.username }
        }),
    }),
    graphql(getRolesForUserQuery, {
        name: 'getRolesForUser',
        options: props => ({
            variables: { username: props.match.params.username }
        }),
    }),
    branch(
        (props) => {
            return !props.getUser.getUser && props.getUser.loading || !props.getRolesForUser.getRolesForUser && props.getRolesForUser.loading
        },
        renderComponent(LoadingComponent)
    ),
    // graphql(updateUserMutation, { name: 'updateUserMutation' }),
    graphql(changePasswordMutation, { name: 'changePasswordMutation' }),
    graphql(createUserRoles, { name: 'createUserRoles' }),
    graphql(deleteUserRoles, { name: 'deleteUserRoles' }))(withApollo1);
