import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import i18n from '../Pages/Login/i18n';
import { Row, Col, Button } from 'reactstrap';
import QueryDataGrid from "../../components/QueryDataGrid/QueryDataGrid";
import {ApolloConsumer, Mutation, Query} from "react-apollo";
import {
    disableOpenbravoSalesOrganization,
    getOrganizationParentsQuery,
    getOrganizationStatusesQuery,
    salesOrganizationsQuery, 
    setOrganizationStatusMutaton
} from "../../queries/Queries";
import PaginatedQueryDataGrid from "../../components/PaginatedQueryDataGrid";

class NoOrganisations extends Component {

    constructor(arg) {
        super(arg)
        if(document.getElementById('filters'))
            document.getElementById('filters').remove();
    }

    render() {
        return (
            <div className="nosalesagent">
                <i className="icon-atom ion-organisation-default"></i>
                <p className="text">There are no organizations added yet.</p>
                <Link to={"/create-organisation"} className='btn btn-primary '> <i className="icon ion-plus-round"></i>&nbsp;&nbsp;Add Organization </Link>
            </div>
        );
    }
}

class Organisations extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeTab: '1'
        };
        this.toggle = this.toggle.bind(this);

    }
    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        }
    }
    render() {

        return (
            <ApolloConsumer>
                {client => <div> <div className="organization">
                    <Row className='subheader'>
                        <Col className='col-8'>
                            <h1>{i18n.t('header.organizations')}</h1>
                        </Col>
                        <Col className='col-4'>
                            <Link to={"/create-organisation"} >
                                <Button className='pull-right' color="primary"> <i className="icon ion-plus-round"/>&nbsp;&nbsp; {i18n.t('organizations.addOrganization')}
                                </Button>
                            </Link>

                        </Col>
                    </Row>

                    <div className="page-cnt">
                        <Query query={getOrganizationParentsQuery}>
                            { result =>  <Query query={getOrganizationStatusesQuery}>
                                { statusResult => <Mutation mutation={disableOpenbravoSalesOrganization}>
                                    {(disableOrg, data) => <Mutation mutation={setOrganizationStatusMutaton} >
                                        {(setOrganizationStatus) =>  <PaginatedQueryDataGrid
                                            paginationPath={'getSalesOrganizations'}
                                            dataPath={'getSalesOrganizations.content'}
                                            keyField={'id'}
                                            query={salesOrganizationsQuery}
                                            saveFilter
                                            filters={[
                                                {
                                                    type: 'text',
                                                    fields: ['name'],
                                                    placeholder: "Search by name",
                                                    classes: "search",
                                                    cols: 4
                                                },
                                                {
                                                    type: 'dropdown',
                                                    fields: ['category'],
                                                    placeholder: "All Categories",
                                                    possibleValues:[{key:'Broker', value:'Broker'}, {key:'Broker Lead', value:'Broker Lead'}, {key:'Sub Broker', value:'Sub Broker'}],
                                                    cols: 2
                                                },
                                                ...((window.config.consul.DYCE || window.DYCE || (typeof DYCE !== 'undefined' ? DYCE : '' ))
                                                    ? [{type: 'dropdown',
                                                    fields: ['status.key'],
                                                    placeholder: "All Statuses",
                                                    possibleValues: statusResult.loading
                                                        ? []
                                                        : statusResult.data.getOrganizationStatuses.map((status) => ({
                                                        key: status.key,
                                                        value: i18n.t(status.name)
                                                    })),
                                                    cols: 2
                                                    }]
                                                : []),
                                                {
                                                    type: 'dropdown',
                                                    fields: ['parent.name'],
                                                    isExactMatch: true,
                                                    placeholder: "All Parents",
                                                    possibleValues: result.loading ? [] : result.data.getOrganizationParents.map(({organizationId, name}) => ({key: name, value:name})),
                                                    cols: 2
                                                },
                                                ...(!(window.config.consul.DYCE || window.DYCE || (typeof DYCE !== 'undefined' ? DYCE : '' ))
                                                    ? [{type: 'boolean',
                                                    fields: ['active'],
                                                    possibleValues:[{key: true, value:i18n.t('organizations.active')}, {key:false, value:i18n.t('organizations.deactivated')}],
                                                    placeholder: i18n.t('organizations.active')+', '+i18n.t('organizations.deactivated'),
                                                    cols: 2
                                                }] : [])
                                            ]}
                                            defaultSorted={{dataField : 'name', order: 'asc'}}
                                            columns={[
                                                {
                                                    dataField: 'id',
                                                    formatter: (cell, row) => <img width="32" height="32" src={(window.config.consul.CORE_URL || window.CORE_URL || (typeof CORE_URL !== 'undefined' ? CORE_URL : '' ))+'/logo/'+cell} style={{borderRadius: "50%"}}/>
                                                },
                                                {
                                                    dataField: 'name',
                                                    text: i18n.t('organizations.salesOrganization'),
                                                    sort: true
                                                },
                                                {
                                                    dataField: 'category',
                                                    text: i18n.t('organizations.category'),
                                                    sort: true
                                                },
                                                {
                                                    dataField: 'parent.name',
                                                    text: i18n.t('organizations.parent'),
                                                    sort: true
                                                },
                                                {
                                                    dataField: 'status.name',
                                                    text: i18n.t('organizations.status'),
                                                    sort: true,
                                                    formatter: (cell, row) => i18n.t(cell)
                                                },
                                                ...(!(window.config.consul.DYCE || window.DYCE || (typeof DYCE !== 'undefined' ? DYCE : '' ))
                                                    ? [{
                                                    dataField: 'active',
                                                    text: i18n.t('organizations.active'),
                                                    sort: true,
                                                    formatter: (cell, row) => cell ? i18n.t('organizations.active') : i18n.t('organizations.deactivated')
                                                }] : [])

                                            ]}
                                            menuItems={async(obj) =>  {
                                                const { data } = await client.query({
                                                    query: getOrganizationStatusesQuery
                                                });

                                                return [...data.getOrganizationStatuses.map((status) => ({name: i18n.t(status.action), callback: () => {
                                                        if (!status.requireReason) {
                                                            return setOrganizationStatus({
                                                                variables: {
                                                                    orgId: obj.organizationId,
                                                                    statusKey: status.key,
                                                                    // statusReason: this.state.statusReason
                                                                }
                                                            });

                                                        } else {
                                                            this.toggleReasonModal(to, setOrganizationStatus, obj.id);
                                                            const promise = new Promise((resolve, reject) =>{
                                                                this.setState({statusPromiseResolve : resolve});
                                                            });

                                                            return promise;
                                                        }
                                                    }})),
                                                    ...(!(window.config.consul.DYCE || window.DYCE || (typeof DYCE !== 'undefined' ? DYCE : '' ))
                                                        ? [{name:i18n.t('organizations.deactivate'), callback:(obj) => disableOrg({variables:{broker : {organizationId: obj.organizationId, businessPartnerId:obj.businessPartnerId}}})}]
                                                        : [])
                                                ];



                                            }}
                                            onSelect={(row)=> this.props.history.push('/organization/' + row.businessPartnerId)}
                                            noDataView={<NoOrganisations/>}
                                        />
                                        }
                                    </Mutation>
                                    }
                                </Mutation>
                                }
                            </Query>
                            }
                        </Query>
                    </div>
                </div>
                </div>}
            </ApolloConsumer>
        )
    }

}

export default Organisations;
