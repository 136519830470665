import React, { Fragment, useEffect, useRef, useState } from 'react'
import { withApollo } from 'react-apollo';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import { FormGroup, Input, Label } from 'reactstrap';
import i18n from "../../views/Pages/Login/i18n";
import { getUsersByOrganizationAndRoles, salesOrganizationsQuery } from '../../queries/Queries';
import Loading from '../Loading/Loading';

/**
 * A functional component to display two inputs for selecting an Organization and a Sales Agent
 * Important props!!!!
 * @prop {isDisabledInput} - Disable owner organization and sales agents input
 * @prop {ownerOrgInputOnChange} - onChange event handler for owner organization input
 * @prop {salesAgentInputOnChange} - onChange event handler for sales agents input
 * @prop {client} - A bunch of different Apollo modules.
 * Apollo Client is a comprehensive state management library for JavaScript that enables you to manage both local and remote data with GraphQL. 
 * Use it to fetch, cache, and modify application data, all while automatically updating UI.
 */

function SalesAgentSelect({isDisabledInput, ownerOrgInputOnChange, salesAgentInputOnChange, client, ...rest}, props) {
    const [partnerName, setPartnerName] = useState('');
    const [businessPartner, setBusinessPartner] = useState([]);
    const [salesAgent, setSalesAgent] = useState([]);
    const [ownerOrganizationId, setOwnerOrganizationId] = useState('');
    const [salesAgentValue, setSalesAgentValue] = useState(null);
    const [loading, setLoading] = useState(false);
    const ref = useRef("typeahead");

    const clientQuery = async (query, variables) => {
        return await client.query({
            query: query,
            variables
        });
    }

    /**
     * Create query variables for salesOrganizationsQuery query
     * Set the returned data to state and use it in the owner org input
     * Accepts [partnerName] for a useEffect dependency that comes from the onSearch event of the owner organization input
     */

    useEffect(() => {
        if (partnerName) {
            const variables = {
                pageNumber: 0,
                pageSize:100,
                where:`name=='*${partnerName}*'`,
                sort:{orders:[{property:'name', direction:'ASC'}]}
            }

            clientQuery(salesOrganizationsQuery, variables)
                .then(res => setBusinessPartner(res.data.getSalesOrganizations.content))
                .catch(err => console.log(err));
        }
    }, [partnerName])

    /**
     * Create query variables for getUsersByOrganizationAndRoles query (sales agents)
     * Set the returned data to state and use it in the sales agents input
     * Accepts [ownerOrganizationId] for a useEffect dependency that comes from the onChange event of the owner organization input
     */

    useEffect(() => {
        if (ownerOrganizationId) {
            const roleKeys = ["SALESAGENT", "SALESADMIN", "SALESMANAGER"];
            const variables = {
                organizationId: ownerOrganizationId,
                roleKeys
            }

            /**
             * Set loading to true to display the <Loading /> component until the request completes.
             * Set loading to false to hide the <Loading /> component and show Sales Agent input
             */

            setLoading(true);
    
            clientQuery(getUsersByOrganizationAndRoles, variables)
                .then(res => {
                    setSalesAgent(res.data.usersByOrganizationAndRoles)
                    setLoading(false);
                })
                .catch(err => console.log(err));
        }
    }, [ownerOrganizationId])


    return (
        <Fragment>
            <FormGroup>
                <Label for="ownerOrganizationId">{i18n.t('quote.salesOrganization')}</Label>
                <AsyncTypeahead
                    ref={ref}
                    disabled={isDisabledInput}
                    onSearch={(query) => setPartnerName(query)}
                    name="ownerOrganizationId"
                    id="ownerOrganizationId"
                    cssclassName="md-12"
                    options={businessPartner}
                    filterBy={["name"]}
                    labelKey="name"
                    selectHintOnEnter={true}
                    onChange={(selectedBroker) => {
                        if (selectedBroker[0]) {
                            setOwnerOrganizationId(selectedBroker[0].organizationId);
                            ownerOrgInputOnChange(selectedBroker[0]);
                        }
                    }}
                    required={true}
                />
            </FormGroup>

            <FormGroup>
                <Label for="ownerUserId">{i18n.t('quote.salesAgent')}</Label>
                {loading
                    ? (
                        <Loading skipCurrentClass={true}/>
                    ): (
                        <Input 
                            type="select" 
                            name="ownerUserId" 
                            id="ownerUserId" 
                            placeholder="Choose Sales Agent"
                            required={true}
                            hidden={isDisabledInput || salesAgent.length === 0}
                            value={salesAgentValue}
                            onChange={(e) => {
                                setSalesAgentValue(e.target.value);
                                salesAgentInputOnChange(e);
                            }}>
                            <option value=''></option>
                            {salesAgent.map((option, index) => 
                                <option
                                    value={option.username}
                                    data-first-name={option.firstName}
                                    data-last-name={option.lastName}
                                    data-phone={option.phone}
                                    data-email={option.username}
                                    key={index}
                                >
                                    {option.firstName} {option.lastName}
                                </option>
                            )}
                        </Input> 
                    )
                }
            </FormGroup>
        </Fragment>
    )
}

export default withApollo(SalesAgentSelect)