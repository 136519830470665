import React, {Component} from 'react';
import {getBusinessPartnerShortQuery} from '../../queries/Queries'
import QueryDataGrid from "../../components/QueryDataGrid";
import Widget01 from '../../views/Widgets/Widget01';
import {Col, Row} from "reactstrap";

class BusinessPartnersList extends Component{
    constructor(props) {
        super(props);

    }


    render(){
        return (
            <div className="manage-users">
                <Row className='subheader'>
                    <Col className='col-8'>
                        <h1>Customers</h1>
                    </Col>
                </Row>
                <div className="page-cnt">
                    <Row>
                        <Col sm="12">
                            <QueryDataGrid variables={{ where: "", orderBy : "name"}}
                                           columns={[{
                                               dataField: 'name',
                                               text: 'Business Partner Name'
                                           }, {
                                               dataField: 'isMdmIslegalentity',
                                               text: 'Customer Type',
                                               formatter: (cell, row) => cell ? "Legal Entity" : "Private Estate"
                                           }, {
                                               dataField: 'isMdmIslegalentity',
                                               text: 'Customer Number',
                                               formatter: (cell, row) => cell ? row.mdmEik :row.mdmPersonalno
                                           }, {
                                               dataField: 'businessPartnerCategory.name',
                                               text: 'Category'
                                           }]}

                                           filters={[
                                               {
                                                   type: "text",
                                                   fields: ["name"],
                                                   placeholder: "Search by name",
                                                   classes:'search'
                                               }
                                           ]}
                                           keyField={'id'}
                                           dataPath={'businessPartner'}
                                           query={getBusinessPartnerShortQuery}
                                           serverSidePaging

                                           onSelect={row =>  {
                                               this.props.history.push('/view/customer/' + row.id);
                                           }}/>
                        </Col>
                    </Row>
                </div>
            </div>
        );
    }
}


export default BusinessPartnersList;