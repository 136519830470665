import React from 'react';
import BasicQueryComponent from "../BasicQueryComponent";
import {Button, Modal, ModalBody, ModalHeader} from 'reactstrap';
import axios from "../../utils/Client";


class ButtonWithModal extends BasicQueryComponent {
    constructor(props) {
        super(props);

        this.toggle = this.toggle.bind(this);
        this.state = {modalOpen : false, image:null, dimensions:{height:100,
                width:100}};
    }


    toggle() {
        this.setState({
            modalOpen: !this.state.modalOpen
        });
    }
    render() {
        return (<span><Button className={"icon ion-eye"}  onClick={(e) => {
            let win = window.open('', '_blank');
            axios.get(`${(window.config.consul.CORE_URL || window.CORE_URL || (typeof CORE_URL !== 'undefined' ? CORE_URL : '' ))}/process/${this.props.id}.png`, {
                headers: {'Authorization': "Bearer " + localStorage.token},
                responseType: 'blob'
            }).then((response) => {

                win.document.write('<body style="background-color:white"><img src="'+URL.createObjectURL(response.data)+'" /></body>');
                // win.location.href = URL.createObjectURL(response.data);
                win.focus();
            })

        }}></Button>
            <Modal isOpen={this.state.modalOpen} toggle={this.toggle} style={{maxWidth:"90%"}}  >
            <ModalHeader toggle={this.toggle}>Process Diagram</ModalHeader>
            <ModalBody>
                <div style={{overflow: "auto"}}> <img src={this.state.imageURL} /></div>
            </ModalBody>
        </Modal></span>);
            }
}

export default ButtonWithModal;