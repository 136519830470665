import React, {Component} from 'react';
import {Row, Col, Input} from 'reactstrap';

class Mpan extends Component {
    constructor(props) {
        super(props);
        this.state = {
            profileClassBeforeChange: this.props.profileClass,
            meterTimeswitchClassBeforeChange: this.props.meterTimeswitchClass,
            lineLossFactorBeforeChange: this.props.lineLossFactor
        }
        this.moveOnMax = this.moveOnMax.bind(this);
    }

    componentDidUpdate(prevProps) {
        // Check if any relevant prop has changed
        if (this.props.profileClass !== prevProps.profileClass ||
            this.props.meterTimeswitchClass !== prevProps.meterTimeswitchClass ||
            this.props.lineLossFactor !== prevProps.lineLossFactor) {
            // Update state with new prop values
            this.setState({
                profileClassBeforeChange: this.props.profileClass,
                meterTimeswitchClassBeforeChange: this.props.meterTimeswitchClass,
                lineLossFactorBeforeChange: this.props.lineLossFactor
            });
        }
    }

    moveOnMax(field, nextField) {
        if (field.value.length >= field.maxLength) {
            var el = document.getElementById(nextField)
            if (el) {
                el.focus();
            }
        }

        document.getElementById('mpan').value =
            (document.getElementById('first').value || '') +
            (document.getElementById('second').value || '') +
            (document.getElementById('third').value || '') +
            (document.getElementById('forth').value || '') +
            (document.getElementById('fifth').value || '') +
            (document.getElementById('sixth').value || '');
    }

    render() {

        return (<Row className="mpan">
            <Col className="leadingcol">
                S <Input type="hidden" name="mpan" id="mpan" ref="mpan"></Input>
            </Col>
            <Col>
                <Row>
                    <Col>
                        <Input placeholder="01" maxLength="2" id="first" value={this.props.disabled ? this.state.profileClassBeforeChange : this.props.profileClass}
                            disabled={this.props.disabled}
                            onInput={(e) => {
                                this.moveOnMax(e.nativeEvent.target, 'second')
                            }}
                            onChange={(e) => this.props.state.quote.setState({
                                quote: {
                                    ...this.props.state.quote.state.quote,
                                    supplyAddress: {
                                        ...this.props.state.quote.state.quote.supplyAddress,
                                        elProfileClass: e.target.value,
                                    }
                                }
                            })}
                        />
                    </Col>
                    <Col>
                        <Input placeholder="901" maxLength="3" id="second" value={this.props.disabled ? this.state.meterTimeswitchClassBeforeChange : this.props.meterTimeswitchClass}
                            disabled={this.props.disabled}
                            onInput={(e) => {
                                this.moveOnMax(e.nativeEvent.target, 'third')
                            }}
                            onChange={e => this.props.state.quote.setState({
                                quote: {
                                    ...this.props.state.quote.state.quote,
                                    supplyAddress: {
                                        ...this.props.state.quote.state.quote.supplyAddress,
                                        elMeterTimeswitchClass: e.target.value,
                                    }
                                }
                            })}
                        />
                    </Col>
                    <Col>
                        <Input placeholder="100" maxLength="3" id="third" value={this.props.disabled ? this.state.lineLossFactorBeforeChange : this.props.lineLossFactor}
                            disabled={this.props.disabled}
                            onInput={(e) => {
                                this.moveOnMax(e.nativeEvent.target, 'forth')
                            }}
                            onChange={e => this.props.state.quote.setState({
                                quote: {
                                    ...this.props.state.quote.state.quote,
                                    supplyAddress: {
                                        ...this.props.state.quote.state.quote.supplyAddress,
                                        elLineLossFactor: e.target.value,
                                    }
                                }
                            })}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col className="col-3">
                        <Input maxLength="2" placeholder="12" value={this.props.mpan.substr(0, 2)}
                               id="forth" onInput={(e) => {
                            this.moveOnMax(e.nativeEvent.target, 'fifth')
                        }} disabled={this.props.disabled}
                        />
                    </Col>
                    <Col className="col-6">
                        <Input maxLength="9" placeholder="23456 7890"
                               value={`${this.props.mpan.substr(2, 5)}  ${this.props.mpan.substr(7, 4)}`}
                               id="fifth" onInput={(e) => {
                            this.moveOnMax(e.nativeEvent.target, 'sixth')
                        }} disabled={this.props.disabled}
                        />
                    </Col>
                    <Col className="col-3">
                        <Input maxLength="2" placeholder="12" value={this.props.mpan.substr(11, 2)}
                               id="sixth" onInput={(e) => {
                            this.moveOnMax(e.nativeEvent.target, 'seventh')
                        }} disabled={this.props.disabled}
                        />
                    </Col>
                </Row>
            </Col>
        </Row>)
    }
}

export default Mpan;