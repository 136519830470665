import React, {Component} from 'react';
import {Button, Col, Modal, ModalBody, ModalHeader, Row} from 'reactstrap';
import i18n from "../../views/Pages/Login/i18n";
import {dateFormatter} from "../../utils/Formatters";
import QueryDataGrid from "../QueryDataGrid";

import axios from "../../utils/Client";
import Loading from "../Loading";
import ReactDOM from "react-dom";

class ProcessesTable extends Component {
    constructor(props) {
        super(props);
        this.state = {modalOpen : false, image:null, dimensions:{height:100,
                width:100}};
        this.imageFormatter = this.imageFormatter.bind(this);
        this.toggle = this.toggle.bind(this);
        this.onImgLoad = this.onImgLoad.bind(this);
    }

    onImgLoad({target:img}) {
        this.setState({dimensions:{height:img.offsetHeight,
                width:img.offsetWidth}});
    }

    imageFormatter(cell, row){
        return <Button className={"icon ion-eye"}  onClick={(e) => {
            ReactDOM.render(<Loading />,
                document.getElementById('alert'));
            this.setState({modalOpen:true});
            axios.get(`${(window.config.consul.CORE_URL || window.CORE_URL || (typeof CORE_URL !== 'undefined' ? CORE_URL : '' ))}/process/${row.id}.png`, {
                headers: {'Authorization': "Bearer " + localStorage.token},
                responseType: 'blob'
            }).then((response) => {
                let objurl = window.URL.createObjectURL(response.data);
                this.setState({imageURL:objurl});
                ReactDOM.render(null,
                    document.getElementById('alert'));
            })

        }}></Button>
    }

    toggle() {
        this.setState({
            modalOpen: !this.state.modalOpen
        });
    }
    render() {
        const id = this.props.match.params.id;
        return (<div><Row>
            <Col sm="12">
                <QueryDataGrid dataPath={'process'} keyField={'id'} query={`
                query processQuery {
                    process(startRow: 0, offset: 100, where: "c_bpartner_id = '${id}'", orderBy: "id") {
                        id
                        creationDate
                        processName
                        task
                        updated
                        __typename
                    }
                }
            ` }
                   defaultSorted={{
                       dataField: 'creationDate',
                       order: 'desc'
                   }}
                    columns={[{
                        dataField: 'processName',
                        text: i18n.t('processes.name'),
                        sort: true
                    }, {
                        dataField: 'task',
                        text: i18n.t('processes.task'),
                        sort: true
                    }, {
                        dataField: 'creationDate',
                        text: i18n.t('common.dateCreated'),
                        formatter: dateFormatter,
                        sort: true
                    }, {
                        dataField: 'updated',
                        text: i18n.t('common.dateUpdated'),
                        formatter: dateFormatter,
                        sort: true
                    }, {
                        dataField: 'id',
                        text: i18n.t('processes.diagram'),
                        formatter: this.imageFormatter,
                    }]}
                />
            </Col>
        </Row><Modal isOpen={this.state.modalOpen} toggle={this.toggle} style={{maxWidth:"90%"}}  >
            <ModalHeader toggle={this.toggle}>Process Diagram</ModalHeader>
            <ModalBody>
                <div style={{overflow: "auto"}}>{!this.state.imageURL ? <Loading/> : <img src={this.state.imageURL} onLoad={this.onImgLoad} />}</div>
            </ModalBody>
        </Modal></div>);
    }
}

export default ProcessesTable;
