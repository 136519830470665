import React from "react";
import PropTypes from "prop-types";
import gql from "graphql-tag";
import ReactDOM from "react-dom";
import {Typeahead} from "react-bootstrap-typeahead";
import {Query} from "react-apollo";
import ExpiringAlert from "../../components/ExpiringAlert";


class QuertyTypeAheadWidget extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const {
            options,
            formData,
            required,
            disabled,
            readonly,
            autofocus,
            placeholder,
            onBlur,
            onFocus,
            onChange,
            id,
            errorSchema
        } = this.props;

        const cities = gql`query getCityByName($cityName: String) {
            city(where: {name: {_ilike: $cityName}}) {
                city_id
                name
                region
            }
        }`;
        return (
            <div className="query-type">
                <label className="col-md-6 input-description query-type-label">{this.props.schema.title}{required ? '*' : ''}</label>
                <Query query={cities}>
                    {(result) => {
                        if (result.loading) return "Loading...";
                        if (result.error) ReactDOM.render(<ExpiringAlert color="danger"
                                                                         message={result.error.message}/>, document.getElementById('alert').appendChild(document.createElement("div")));
                        const {data} = result;

                        return <Typeahead ref="typeahead"
                                          options={data.city}
                                          disabled={readonly}
                                          labelKey={option => `${option.name} - ${option.region}`}
                                          selectHintOnEnter={true}
                                          placeholder={placeholder}
                                          filterBy={['name']}
                                          clearButton
                                          onChange={(event) => {
                                              onChange(event[0] ? event[0] : null);
                                          }}
                                          selected={formData && formData.city_id ? [formData] : []}
                        />
                    }
                    }
                </Query>
                <label className="col-md-6 input-description">{<br/>}</label>
                {errorSchema && errorSchema.city_id && <div>
                    <ul className="error-detail bs-callout bs-callout-info">
                        {errorSchema.city_id.__errors.map(e =>  <li className="text-danger">{e}</li>)}
                    </ul>
                </div>}
            </div>
        );
    }
}

if (process.env.NODE_ENV !== "production") {
    QuertyTypeAheadWidget.propTypes = {
        schema: PropTypes.object.isRequired,
        id: PropTypes.string.isRequired,
        placeholder: PropTypes.string,
        options: PropTypes.shape({
            rows: PropTypes.number,
        }),
        value: PropTypes.string,
        required: PropTypes.bool,
        disabled: PropTypes.bool,
        readonly: PropTypes.bool,
        autofocus: PropTypes.bool,
        onChange: PropTypes.func,
        onBlur: PropTypes.func,
        onFocus: PropTypes.func,
    };
}

export default QuertyTypeAheadWidget;
