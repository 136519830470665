import React,{ useEffect } from "react";

function Recaptcha(props) {

    useEffect(() => {
        try{
            grecaptcha.render('g-recaptcha', {
                'sitekey' : props.schema.site_key
            });
        }catch(error){/*possible duplicated instances*/}
    });
    return (
        <div className="form-group">
            <div className="col-md-12">
                <div id="g-recaptcha"></div>
            </div>
        </div>
    );
}

export default Recaptcha;