import React, {Component} from 'react';
import {withRouter} from 'react-router';
import {compose, graphql, withApollo} from "react-apollo";
import i18n from '../Pages/Login/i18n';
import Loading from '../../components/Loading';
import MaskInput from 'react-maskinput'
import {Button, Form, FormGroup, Input} from 'reactstrap';
import ReactDOM from 'react-dom';
import {getBusinessPartnerQuery, createReadingMutation} from "../../queries/Queries";
import ExpiringAlert from "../../components/ExpiringAlert";
import moment from "moment";
import { SingleDatePicker } from "react-dates";

class CreateReading extends Component {

    constructor(props) {
        super(props);

        this.state = {
            type:'COU_C',
            date: moment(),
            submittingReading: false,
            mDMBPEstate:
                this.props.estateList.length == 1
                    ? this.props.estateList[0]
                    : {
                        mDMEstate:
                            {
                                mDMMeterPointList:[]
                            }
                    },
            mDMMeterPoint:
                {
                    mDMMeterRegisterList:[]
                },
            meter:{}
        };
        this.handleSubmit = this.handleSubmit.bind(this);

    }

    handleSubmit() {
        this.setState({submittingReading:true});
        const products = this.state.mDMMeterPoint.mDMMeterRegisterList.filter(({meter}) => meter.id === this.state.meter.id).map(meterRegister => meterRegister.mDMRegister.product.id);
        const readings = this.state.mDMMeterPoint.mDMMeterRegisterList.filter(({meter}) => meter.id === this.state.meter.id).map(meterRegister => this.state['reading'+meterRegister.id]);

        this.props.createReadingMutation({
            variables: {
                bpEstateId: this.state.mDMBPEstate.id,
                estateId: this.state.mDMBPEstate.mDMEstate.id,
                meterId: this.state.meter.id,
                productId: products,
                readingDate:  this.state.date.toDate(),
                readingValue: readings,
                customerReadType: this.state.type == 'COU_C' ? 'C' : this.state.type == 'COU_I' ? 'I' : this.state.type == 'COU_A' ? 'A' : '',
                process: this.state.type == 'COU_C' ? '8' : this.state.type == 'COU_I' ? '1' : this.state.type == 'COU_A' ? '8' : ''

            },
            refetchQueries: [{query: getBusinessPartnerQuery,
                variables:{ startRow: 0, offset: 100, where: "id = '" + (this.props.match.params.id ? this.props.match.params.id : this.props.match.params.param) + "'", orderBy: "name" }}]
        }).then(({ data }) => {
            console.log('got data', data);
            this.props.toggle();
                ReactDOM.render(<ExpiringAlert color="success" message={i18n.t('readings.readingCreated')}/>, document.getElementById('alert').appendChild(document.createElement("div")));

        }).catch((error) => {
            this.props.toggle();
            ReactDOM.render(<ExpiringAlert color="danger" message={error.message}/>, document.getElementById('alert').appendChild(document.createElement("div")));
            console.log('there was an error sending the query', error.message);
        });
    }

    render() {
        return ( <div className="reading-modal">
            <Form>
            <FormGroup>
                <label>{i18n.t('readings.address')}:&nbsp;</label>
                <Input type="select" value={this.state.mDMBPEstate.id} onChange={(e) => {
                            this.setState({
                                mDMBPEstate: this.props.estateList.filter(estate => estate.id === e.target.value).reduce((accumulator, currentValue) => currentValue || accumulator, {mDMEstate:{mDMMeterPointList:[]}}),
                                mDMMeterPoint: {
                                    mDMMeterRegisterList:[]
                                },
                                meter:{}
                            })
                        }
                       }>
                    <option value="">---</option>
                    {this.props.estateList.map(estate => <option value={estate.id}>{estate.mDMEstate.locationAddress.addressLine1}</option> )}
                </Input>
            </FormGroup>
            <FormGroup>
                <label>{i18n.t('readings.meterPoint')}:&nbsp;</label>
                <Input type="select" value={this.state.mDMMeterPoint.id} onChange={(e) =>  this.setState({mDMMeterPoint: this.state.mDMBPEstate.mDMEstate.mDMMeterPointList.filter(meterPoint => meterPoint.id === e.target.value).reduce((accumulator, currentValue) => currentValue || accumulator, {mDMMeterRegisterList:[]})
                })} >
                    <option value="">---</option>
                    {this.state.mDMBPEstate.mDMEstate.mDMMeterPointList.map(meterPoint => <option value={meterPoint.id}>{meterPoint.name}</option>)}
                </Input>
            </FormGroup>
            <FormGroup>
                <label>{i18n.t('readings.meter')}:&nbsp;</label>
                <Input type="select" value={this.state.meter.id} onChange={(e)=>this.setState({meter:this.state.mDMMeterPoint.mDMMeterRegisterList.filter(({meter}) => meter.id === e.target.value).reduce((accumulator, currentValue) => currentValue || accumulator, {meter:''}).meter})} >
                    <option value="">---</option>
                    {this.state.mDMMeterPoint.mDMMeterRegisterList.map(({meter})=> meter).filter((meter, index, self) => {
                                    return self.findIndex(m => m.id === meter.id) === index;
                                }).map(meter => <option value={meter.id}>{meter.serialNumber}</option>)
                    }
                </Input>
            </FormGroup>
                {this.state.mDMMeterPoint.mDMMeterRegisterList.filter(({meter}) => meter.id === this.state.meter.id).map(mDMMeterRegister => {
                    console.log('mDMMeterRegister.meter.ammMeterReadingList', mDMMeterRegister.meter.ammMeterReadingList);
                    const lastReading = mDMMeterRegister.meter.ammMeterReadingList
                        .filter( reading => (reading.customerReadType === "Customer" || reading.customerReadType === "Initial" || reading.customerReadType === "Actual") && reading.product.id === mDMMeterRegister.mDMRegister.product.id)
                        .map( reading => ({...reading, readingdate: moment(reading.readingdate, "YYYY-MM-DDTHH:mm:ssZ")}) )
                        .sort((a,b) => b.readingdate.isBefore(a.readingdate) ? -1 : 1 )[0];
                    return (<FormGroup>
                        <div className="last-reading">
                            <label>Last Read {lastReading ? lastReading.readingdate.format("L") : "" }</label>
                            <p>{lastReading ? lastReading.newreadingmetervt : "N/A"}</p>
                        </div>
                        <label>{mDMMeterRegister.mDMRegister.product.name}:&nbsp;</label><br/>

                        <MaskInput value={this.state['reading'+ mDMMeterRegister.id]} onChange={(e) => this.setState({['reading'+ mDMMeterRegister.id] : e.target.value, ['lastReading'+ mDMMeterRegister.id] : lastReading})}
                                   alwaysShowMask
                                   maskChar={"0"}
                                   mask={"0".repeat(mDMMeterRegister.meterReadingDigitsCount)}
                                   placeholder="20491"
                                   className={'reading-input'}
                                   style={{width:mDMMeterRegister.meterReadingDigitsCount*24}}
                        />
                    </FormGroup>)
                })}
                <FormGroup>
                    <label>{i18n.t('readings.date')}:&nbsp;</label>
                    <SingleDatePicker
                        date={this.state.date} // momentPropTypes.momentObj or null
                        numberOfMonths={1}
                        onDateChange={date => {
                            this.setState({...this.state, date});
                        }}
                        isOutsideRange={day => day.isAfter(moment())}
                        focused={this.state.focused} // PropTypes.bool
                        onFocusChange={({ focused }) => this.setState({ focused })} // PropTypes.func.isRequired
                        id="task-due-date" // PropTypes.string.isRequired,
                    />
                </FormGroup>

                <FormGroup>
                    <label>{i18n.t('readings.type')}:&nbsp;</label>
                    <Input type="select" value={this.state.type} onChange={(e)=>this.setState({...this.state, type: e.target.value})} >
                        <option value="COU_C">Customer</option>
                        <option value="COU_I">Initial</option>
                        <option value="COU_A">Actual</option>
                    </Input>
                </FormGroup>


                <Button onClick={this.handleSubmit} disabled={this.state.submittingReading} color="primary">Submit</Button> &nbsp;&nbsp;&nbsp;
                <Button onClick={this.props.toggle} color="seconday">Cancel</Button>
                </Form>
        </div> )
    }
}



const LoadingComponent = () => <Loading/>

let withRouter2 = withRouter(CreateReading);
export default  compose(
    graphql(createReadingMutation, { name: 'createReadingMutation' })
    )(withRouter2);
