import React, {Component} from 'react';
import {activateMeterPoint, contractsListPagedQuery, meterPointsPagedQuery} from "../../queries/Queries";
import i18n from "../../views/Pages/Login/i18n";
import {Button, Col, Form, FormGroup, FormText, Input, Row} from "reactstrap";
import axios from "../../utils/Client";
import moment from "moment";
import {Mutation} from "react-apollo";
import ReactDOM from "react-dom";
import ExpiringAlert from "../ExpiringAlert";
import QuerySearch from "../QuerySearch";
import {SingleDatePicker} from "react-dates";
import {withRouter} from "react-router-dom";

class StartProcess extends Component {
    constructor(props) {
        super(props);
        this.state = {
            contractNumber: this.props.match.params.contractNumber,
            businessPartnerId: this.props.match.params.businessPartnerId,
            disabled: !!this.props.match.params.contractNumber,
            process: 'activate-mp'
        };
    }

    componentDidMount() {
    }

    render() {
        const loadingIcon = this.state.isLoading ? (
            <span><i style={this.state.buttonStyle} className='fa fa-circle-o-notch fa-spin'></i> </span>) : null;
        return (
            <div><Row className='subheader'>
                <Col className='col-8'>
                    <h1>Start Process</h1>
                </Col>
            </Row>

                <div className="page-cnt create-user ">
                    <Row>
                        <Col>
                            <Form>
                                <FormGroup>
                                    <FormText>Process</FormText>
                                    <Input type={"select"} onChange={(e) => this.setState({process: e.target.value})}>
                                        <option value="activate-mp">Activate Meter Point</option>
                                        <option value="contract-termination">Contract Termination</option>
                                    </Input>
                                </FormGroup>
                            </Form>

                            {this.state.process == 'contract-termination' &&
                            <Form>
                                <FormGroup>
                                    <FormText>Document number</FormText>
                                    <QuerySearch dataPath={'contractsListPaged.content'}
                                                 paginationPath={'contractsListPaged'}
                                                 query={contractsListPagedQuery}
                                                 filters={[
                                                     {
                                                         type: 'text',
                                                         fields: ['documentnumber', 'estate.mDMEstate.estateNumber',
                                                             'estate.mDMEstate.mDMMeterPointList.#.name'],
                                                         placeholder: "Search by document number",
                                                         classes: "search",
                                                         cols: 5
                                                     },
                                                     {
                                                         type: 'text',
                                                         fields: ['businessPartner.id=='
                                                         + (this.state.businessPartnerId ?
                                                             this.state.businessPartnerId :
                                                             '*')],
                                                     }
                                                 ]}
                                                 ky={'documentnumber'}
                                                 label={'documentnumber'}
                                                 value={this.state.contractNumber}
                                                 disabled={this.state.disabled}
                                                 columns={[

                                                     {
                                                         dataField: 'documentnumber',
                                                         text: 'Contract Number'
                                                     }, {
                                                         dataField: 'estate.mDMEstate.estateNumber',
                                                         text: i18n.t('Estate')
                                                     }

                                                 ]}
                                                 onChange={(contract) => {
                                                     if (contract.id !== this.state.contractNumber) {
                                                         this.setState({contractNumber: contract.documentnumber});
                                                     }
                                                 }}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <FormText> Date </FormText>
                                    <SingleDatePicker
                                        date={this.state.endDate} // momentPropTypes.momentObj or null
                                        numberOfMonths={1}
                                        onDateChange={date => {
                                            this.setState({...this.state, endDate: date});
                                        }}
                                        isOutsideRange={() => false}
                                        focused={this.state.focused} // PropTypes.bool
                                        onFocusChange={({focused}) => this.setState({focused})} // PropTypes.func.isRequired
                                        id="process-date" // PropTypes.string.isRequired,
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <Button onClick={
                                        () => axios.post(
                                            `${(window.config.consul.CORE_URL || window.CORE_URL || (typeof CORE_URL !== 'undefined' ? CORE_URL : '' ))}/bpm/crm/rest/engine/default/process-definition/key/ContractTermination/start`,
                                            {
                                                variables: {
                                                    contractNumber: {
                                                        value: this.state.contractNumber,
                                                        type: "String"
                                                    },
                                                    endDate: {
                                                        value: moment(this.state.endDate).format(
                                                            "YYYY-MM-DD[T]HH:mm:ss.SSSZZ"), type: "Date"
                                                    },
                                                    context: {
                                                        value: "CT", type: "String"
                                                    }
                                                }
                                            }
                                        )
                                            .then((response) => alert('Success'))
                                            .catch(error => alert('Error: ' + error))
                                    }>
                                        Start process
                                    </Button>
                                </FormGroup>
                            </Form>
                            }

                            {this.state.process == 'activate-mp' &&
                            <Mutation mutation={activateMeterPoint} refetchQueries={[]}>
                                {(activateMeterPoint, {data}) => {
                                    return <Form onSubmit={(event) => {
                                        event.preventDefault();
                                        this.setState({
                                            buttonStyle: {visibility: "visible"},
                                            isLoading: true
                                        });
                                        activateMeterPoint({
                                            variables:
                                                {
                                                    meterPointId: this.state.meterPointId,
                                                    endDate: this.state.endDate
                                                }
                                        }).then(response => {
                                            this.setState({buttonStyle: {visibility: "hidden", isLoading: false}});
                                            if (response.data.OB_processMeterPoint) {
                                                ReactDOM.render(<ExpiringAlert color="success"
                                                                               message={i18n.t('processes.successfulProcessStart')}/>, document.getElementById('alert').appendChild(document.createElement("div")));
                                            }
                                        }).catch((error) => {
                                            ReactDOM.render(<ExpiringAlert color="danger"
                                                                           message={'Problem activating the Meter Point or the Meter Point is already activated.'}/>, document.getElementById('alert').appendChild(document.createElement("div")));
                                            console.log('there was an error processing the query', error.message);
                                        });

                                    }}>
                                        <FormGroup>
                                            <FormText>Meter point number</FormText>
                                            <QuerySearch dataPath={'OB_meterPointPage.content'}
                                                         paginationPath={'OB_meterPointPage'}
                                                         query={meterPointsPagedQuery}
                                                         filterType='HQL'
                                                         filters={[
                                                             {
                                                                 type: 'text',
                                                                 fields: ['name'],
                                                                 placeholder: "Search by meter point name",
                                                                 classes: "search",
                                                                 cols: 5,
                                                                 expression: [
                                                                    "name in (select searchKey from MDM_Service_Instance si where si.typeSearchKey='MDM_METER_POINT_IDENTIFIER' and si.contract.mDMContractStatus.name = 'Signed' and si._computedColumns.currentStatus.status = 'DRAFT' )"
                                                                 ]
                                                             }
                                                         ]}
                                                         ky={'id'}
                                                         label={'name'}
                                                         value={this.state.meterPointId}
                                                         disabled={this.state.disabled}
                                                         columns={[

                                                             {
                                                                 dataField: 'name',
                                                                 text: 'Meter Point Number'
                                                             }

                                                         ]}
                                                         onChange={(meterPoint) => {
                                                             if (meterPoint.id !== this.state.meterPointId) {
                                                                 this.setState({meterPointId: meterPoint.id});
                                                             }
                                                         }}
                                            />
                                        </FormGroup>
                                        <FormGroup>
                                            <FormText> Date </FormText>
                                            <SingleDatePicker
                                                date={this.state.endDate} // momentPropTypes.momentObj or null
                                                numberOfMonths={1}
                                                onDateChange={date => {
                                                    this.setState({...this.state, endDate: date});
                                                }}
                                                isOutsideRange={() => false}
                                                focused={this.state.focused} // PropTypes.bool
                                                onFocusChange={({focused}) => this.setState({focused})} // PropTypes.func.isRequired
                                                id="process-date" // PropTypes.string.isRequired,
                                            />
                                        </FormGroup>
                                        <FormGroup>
                                            <Button type="submit" disabled={this.state.isLoading}
                                                    color='primary'>{loadingIcon}Start Process</Button>
                                        </FormGroup>
                                    </Form>
                                }}
                            </Mutation>
                            }
                        </Col>
                    </Row>
                </div>
                {this.state.process !== 'activate-mp' && <iframe src={`${(window.config.consul.CORE_URL || window.CORE_URL || (typeof CORE_URL !== 'undefined' ? CORE_URL : '' ))}/bpm/crm/app/cockpit/default/`}/>}
            </div>

        )
    }
}

export default withRouter(StartProcess);
