import React, {Component} from 'react';
import {withRouter} from 'react-router';
import {compose, graphql, Mutation, Query} from "react-apollo";
import ReactDOM from 'react-dom';
import TextareaAutosize from "react-textarea-autosize";
import {Col, DropdownItem, FormGroup, Row} from "reactstrap";
import Loading from "../../components/Loading";
import {Button, Input} from "reactstrap";
import gql from "graphql-tag";
import FindAddress from "../../components/FindAddress";

const GET_ZIP_CODES = gql`
    query findPostalCodeByMPRN($mprn:[String]) {
        findPostalCodeByMPRN(mprn: $mprn) {
            block
            city
            county
            dependentCity
            dependentStreet
            doubleDependentCity
            houseNumber
            houseNumberAdd
            mpan
            mprn
            postalCode
            street
            ldz
            countryCode
            gas_meter_serial_number
        }
    }
`;

const START_NOMINATION = gql`
    mutation startBulkNomination($addresses:[com_methodia_salesportal_domain_AddressResource_AddressInput], $annualQuantity: Float ) {
        startBulkNomination(addresses: $addresses, annualQuantity: $annualQuantity)
    }
`;

class BulkNomination extends Component {

    constructor(props) {
        super(props);
        this.handleAddressChoice = this.handleAddressChoice.bind(this);
        this.handlePostalCodeClick = this.handlePostalCodeClick.bind(this);
        this.validPostcode = this.validPostcode.bind(this);
        this.state = {value: "", step: "enter-meter-points", startButtonDisabled: false};
    }

    handlePostalCodeChange(mprn) {
        return function (e) {
            let res = {...this.state.result};
            res[mprn].address.postalCode = e.target.value;
            res[mprn].valid = this.validPostcode(e.target.value);
            this.setState({result: res, startButtonDisabled: !Object.values(res).map(v => v.valid).reduce((accumulator, currentValue) => accumulator && currentValue)})
        }.bind(this)
    }

    validPostcode(postcode) {
        if (!postcode) return true;
        var regex = /^[A-Z]{1,2}[0-9]{1,2}[A-Z]{0,1} ?[0-9][A-Z]{2}$/i;
        return regex.test(postcode);
    }

    handlePostalCodeClick(mprn) {
        return function (e) {
            const res = {...this.state.result}
            if (!res[mprn].showAddress) {
                for (const key in res) {
                    res[key].showAddress = false;
                }
            }

            res[mprn].showAddress = !res[mprn].showAddress;
            this.setState({result: res})
        }.bind(this)
    }

    handleAddressChoice(mprn) {
        return function (address) {
            if (Object.keys(address).length === 0) return;
            //delete(address.mprn);
            let value = this.state.value;
            let result = {...this.state.result};
            result[mprn] = {skip: false, mprn: mprn, showAddress: true, address: address};
            this.setState({result: result, value: value, step: "show-zip-codes"})
        }.bind(this)
    }

    render() {
        const mprns = this.state.value.trim().split('\n');
        if (this.props.history.location.pathname !== "/bulk-nomination/progress") {
            this.state.step = 'enter-meter-points';
        };
        switch (this.state.step) {
            case "enter-meter-points" :
                return (<div>
                        <Row className='step'>
                            <Col className='col-xl-1 col-lg-2 col-md-3 col-sm-1'/>
                            <Col className='col-xl-5 col-lg-6 col-md-8 col-sm-10 step-header'>
                                <h2>Meter Points</h2></Col>
                        </Row>
                        <Row className='step'>
                            <Col className='col-xl-1 col-lg-2 col-md-3 col-sm-1'/>
                            <Col className='col-xl-5 col-lg-6 col-md-8 col-sm-10 step-header'>
                                <Input type="select" name="select" id="exampleSelect"
                                       onChange={e => this.setState({annualQuantity: e.target.value})}
                                       className='col-xl-8 col-lg-8 col-md-10 col-sm-12'>
                                    <option>--- Please select ---</option>
                                    <option value="75000">Nomination Process</option>
                                    <option value="50000">Enquery nomination</option>
                                </Input>
                            </Col>
                        </Row>
                        <Row className='step'>
                            <Col className='col-xl-1 col-lg-2 col-md-3 col-sm-1'/>
                            <Col className='col-xl-5 col-lg-6 col-md-8 col-sm-10 step-body'>
                                <TextareaAutosize
                                    minRows={20}
                                    className='col-xl-8 col-lg-8 col-md-10 col-sm-12'
                                    placeholder="Insert Meter Points each on new line..."
                                    onChange={e => this.setState({value: e.target.value})}
                                /></Col></Row>
                        <Row className='step'>
                            <Col className='col-xl-1 col-lg-2 col-md-3 col-sm-1'/>
                            <Col className='col-xl-5 col-lg-6 col-md-8 col-sm-10 step-header'>
                                <p className="error" style={{display: this.state.value.indexOf(" ") === -1 ? "none" : "block"}}>Please remove all the spaces from the meter point list</p>
                                <Button disabled={this.state.value == "" || this.state.value.indexOf(" ") !== -1} onClick={() => {
                                    if (this.state.value.indexOf(" ") === -1) {
                                        this.setState({step: "load-zip-codes"});
                                        this.props.history.push("/bulk-nomination/progress");
                                    }
                                }
                                }>Start</Button>
                            </Col>
                        </Row>
                    </div>
                )
                break;


            case "load-zip-codes" :
                return (<Query query={GET_ZIP_CODES} variables={{mprn: mprns}}>
                    {({loading, error, data}) => {
                        if (loading) return (<Loading/>);
                        if (error) return `Error! ${error.message}`;

                        this.state.result = {}
                        mprns.forEach((e) => this.state.result[e] = {mprn: e, skip: true, valid: true, address: {}})

                        if (data.findPostalCodeByMPRN) data.findPostalCodeByMPRN.map(record =>
                            this.state.result[record.mprn] = {
                                address: record,
                                skip: !record.postalCode,
                                showAddress: !record.postalCode,
                                mprn: record.mprn,
                                valid: true
                            }
                        )
                        this.setState({step: "show-zip-codes"})
                        return null;
                    }}
                </Query>);
                break;

            case "show-zip-codes":
                return <div>
                    <Row className='step'>
                        <Col className='col-xl-5 col-lg-6 col-md-8 col-sm-12 step-header offset-xl-1 offset-lg-2'>
                            <h2>Meter Points</h2></Col>
                    </Row>
                    <Row className='step'>
                        <Col className='col-xl-2 col-lg-2 col-md-3 col-sm-3 offset-xl-1 offset-lg-2'><h6>MPRN</h6></Col>
                        <Col className='col-xl-2 col-lg-2 col-md-3 col-sm-3'><h6>Post code</h6></Col>
                        <Col className='col-xl-2 col-lg-2 col-md-3 col-sm-3'><h6>Message</h6></Col>
                        {/*<Col className='col-xl-2 col-lg-2 col-md-3 col-sm-3'><h6>Action</h6></Col>*/}
                    </Row>
                    {Object.values(mprns).map(mprn => {
                        const record = this.state.result[mprn];
                        return (
                            <div>
                                <hr/>
                                <Row className='step'>
                                    <Col className='col-xl-2 col-lg-2 col-md-3 col-sm-3 offset-xl-1 offset-lg-2'><Input
                                        value={record.mprn}/></Col>
                                    <Col className='col-xl-2 col-lg-2 col-md-3 col-sm-3'><Input
                                        value={record.address.postalCode}
                                        onChange={this.handlePostalCodeChange(record.mprn)}
                                        style={{ borderColor : record.valid ? "green" : "red"}}/></Col>

                                    <Col className='col-xl-2 col-lg-2 col-md-3 col-sm-3'>
                                        <p className="error">
                                            {!record.valid && "Please enter valid Postal Code"}
                                        </p>

                                    </Col>
                                </Row>
                                {record.showAddress && <Row>
                                    <Col
                                        className='col-xl-5 col-lg-6 col-md-8 col-sm-12 offset-xl-1 offset-lg-2 step-body'>
                                        <FormGroup>
                                            <FindAddress
                                                name="supplyAddress"
                                                address={record.address}
                                                onChange={this.handleAddressChoice(record.mprn)}
                                            />
                                        </FormGroup>

                                    </Col>
                                </Row>
                                }</div>
                        )
                    }, this)}
                    <hr/><Row className='step'>
                        <Col className='col-xl-5 col-lg-6 col-md-8 col-sm-12 step-header offset-xl-1 offset-lg-2'>
                            <Mutation mutation={START_NOMINATION}
                                      onCompleted={(data) => this.setState({step: "results", resultData: data, startButtonDisabled: false})}>
                                {(start, {data}) => {
                                    return (<Button disabled={this.state.startButtonDisabled} onClick={() => {
                                        this.setState({startButtonDisabled: true});
                                        const addresses = []
                                        for (const key in this.state.result) {
                                            if (this.state.result[key].address.postalCode) {
                                                addresses.push({
                                                    //...this.state.result[key].address,
                                                    // we put mprn in houseNumberAdd
                                                    houseNumberAdd: this.state.result[key].mprn,
                                                    postalCode: this.state.result[key].address.postalCode,
                                                    mprn: this.state.result[key].mprn,
                                                    countryCode: this.state.result[key].address.countryCode,
                                                    city: "DEFAULT TERRITORY"
                                                })
                                            }
                                        }
                                        start({
                                            variables: {
                                                addresses: addresses,
                                                annualQuantity: parseFloat(this.state.annualQuantity)
                                            }
                                        });
                                    }}>Start</Button>)
                                }}</Mutation>
                        </Col>
                    </Row>

                </div>;
            case "results" :
                return (<div>
                    <Row className='step'>
                        <Col className='col-xl-5 col-lg-6 col-md-8 col-sm-12 step-header offset-xl-1 offset-lg-2'><h2>Nomination has
                            started...</h2>
                        </Col>
                    </Row>
                    <Row className='step'>
                        <Col className='col-xl-5 col-lg-6 col-md-8 col-sm-12 offset-xl-1 offset-lg-2'>
                            <Button onClick={() => this.setState({step: "enter-meter-points"})}>Enter more meter
                                points</Button>
                        </Col></Row>
                </div>)

                break
        }

    }
}

const LoadingComponent = () => <Loading/>
let withRouter2 = withRouter(BulkNomination);
export default (withRouter2); //compose()
