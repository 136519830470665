import React from "react";
import PropTypes from "prop-types";
import PreviewSwitcher from "../PreviewSwitcher/PreviewSwitcher";
import AceEditor from 'react-ace';
import 'brace/mode/jsx';
import 'brace/theme/github';
import {Button} from 'reactstrap';

function JSXEditor(props) {

    const {
        id,
        options,
        placeholder,
        value,
        required,
        disabled,
        readonly,
        autofocus,
        onChange,
        onBlur,
        onFocus,
        formContext,
    } = props;
    /*const _onChange = ({ target: { value } }) => {
        return onChange(value === "" ? options.emptyValue : value);
    };*/
    const _onChange = (value) => {
        return onChange(value === "" ? options.emptyValue : value);
    };


    const exampleParams = formContext.getLayout && formContext.getLayout.exampleParams ? JSON.parse(formContext.getLayout.exampleParams) : {};

    let errorObj = {};
    let error = false;
    try {
        const acorn = require('acorn-jsx/inject')(require('acorn'));
        acorn.parse(value, {
            plugins: {jsx: true}
        });
    } catch (e) {
        error = true;
        errorObj = e;
    }
    let annotations = [];

    if (errorObj && errorObj.loc) {
        annotations = [{
            row: errorObj.loc.line - 1,
            column: errorObj.loc.column + 1,
            text: "Error",
            type: "error"
        }]
    }

    return (
        <div>
            <AceEditor
                mode="jsx"
                theme="github"
                onChange={_onChange}
                name="{id}"
                editorProps={{$blockScrolling: true}}
                value={value}
                width="100%"
                annotations={annotations}
            />
            <div style={{border: "1px solid black", padding: "20px"}}>
                {error ? "The preview is currently not available, because there is an error in your code. Please check the editor above and fix it." : (<PreviewSwitcher buttonLabel="Open Preview" className="modal-lg" jsx={error ? value : value} layouts={formContext.getLayout.uiConfiguration.uiLayouts} configuration={formContext.getLayout.uiConfiguration} exampleParams={exampleParams} />)}
            </div>
        </div>
    );
    /*
    <textarea
            id={id}
            className="form-control"
            value={typeof value === "undefined" ? "" : value}
            placeholder={placeholder}
            required={required}
            disabled={disabled}
            readOnly={readonly}
            autoFocus={autofocus}
            rows={options.rows}
            onBlur={onBlur && (event => onBlur(id, event.target.value))}
            onFocus={onFocus && (event => onFocus(id, event.target.value))}
            onChange={_onChange}
        />
     */
}

JSXEditor.defaultProps = {
    autofocus: false,
    options: {},
};

if (process.env.NODE_ENV !== "production") {
    JSXEditor.propTypes = {
        schema: PropTypes.object.isRequired,
        id: PropTypes.string.isRequired,
        placeholder: PropTypes.string,
        options: PropTypes.shape({
            rows: PropTypes.number,
        }),
        value: PropTypes.string,
        required: PropTypes.bool,
        disabled: PropTypes.bool,
        readonly: PropTypes.bool,
        autofocus: PropTypes.bool,
        onChange: PropTypes.func,
        onBlur: PropTypes.func,
        onFocus: PropTypes.func,
    };
}
export default JSXEditor;