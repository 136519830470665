import React from "react";
import PropTypes from "prop-types";
import gql from "graphql-tag";
import ReactDOM from "react-dom";
import {AsyncTypeahead} from "react-bootstrap-typeahead";
import {Query, withApollo} from "react-apollo";
import ExpiringAlert from "../../components/ExpiringAlert";

class AsyncSelect extends React.Component{
    constructor() {
        super();
        this.state = {
            isLoading: false,
            filter: "~"

        }
    }

    render() {
        const vats = gql`query ($vatNumber: String){
        client(where:{vat_number:{_ilike:$vatNumber}}){
        vat_number
        }
        }`;

        return <Query query={vats} variables={{vatNumber: this.state.filter}}>
            {(result) => {
                if (result.error) ReactDOM.render(<ExpiringAlert color="danger"
                                                                 message={result.error.message}/>, document.getElementById('alert').appendChild(document.createElement("div")));
                const {data} = result;
                return <AsyncTypeahead id={"search"} ref="typeahead"  isLoading={this.state.isLoading}
                   cssclassName="md-12"
                   options={!result.loading ? result.data.client : []}
                   labelKey="vat_number"
                   placeholder={this.props.placeholder}
                   filterBy = {['vat_number']}
                   clearButton
                   selectHintOnEnter={true}
                   onSearch={(query) => {
                       this.setState({filter: '%' + query + '%'}, () => {this.props.onUpdate(query)});
                   }}
                   onChange={(selected) => {
                       this.props.onChange(selected[0] ? selected[0].vat_number : null)
                   }}
                   selected={this.props.value? [{vat_number: this.props.value}] : []}
                />
            }
            }
        </Query>
    }

}


function QuertyAsyncTypeAheadWidgetEIK(props) {
    const {
        options,
        value,
        required,
        disabled,
        readonly,
        autofocus,
        onBlur,
        onFocus,
        onChange,
        onPropertyChange,
        id,
    } = props;

    const details = gql`query MyQuery ($vatNumber: String) {
            client (where: {vat_number:{_eq: $vatNumber}}) {
                name
                address
                email
                fax
                first_name
                last_name
                middle_name
                phone
                city {
                    city_id
                    name
                    region
                }
                city_id
                vat_number
            }
        }`
    // Generating a unique field name to identify this set of radio buttons
    const name = Math.random().toString();
    const { enumOptions, enumDisabled, inline } = options;
    return (
        <AsyncSelect placeholder={props.placeholder} value={value} onChange={(event) => {
            if (event) {
                this.props.client.query({
                    query: details,
                    variables: { vatNumber: event }
                }).then(({data}) => {
                    if (!data.client[0]) return;
                    const companyData = {
                        company: {

                        },
                        customer : {

                        },
                        place_of_supply : {

                        }
                    };

                    if (data.client[0].name) companyData.company.company_name = data.client[0].name;
                    if (data.client[0].vat_number) companyData.company.vat_number = data.client[0].vat_number;

                    if (data.client[0].first_name) companyData.customer.name = data.client[0].first_name;
                    if (data.client[0].middle_name) companyData.customer.middle_name = data.client[0].middle_name;
                    if (data.client[0].last_name) companyData.customer.surname = data.client[0].last_name;
                    if (data.client[0].phone) companyData.customer.phone_number = data.client[0].phone;
                    if (data.client[0].email) companyData.customer.email = data.client[0].email;

                    if (data.client[0].city && data.client[0].city.region) companyData.place_of_supply.area = data.client[0].city.region;
                    if (data.client[0].city && data.client[0].city.name) companyData.place_of_supply.locality = data.client[0].city.name;
                    if (data.client[0].address.street) companyData.place_of_supply.street = data.client[0].address.street;
                    if (data.client[0].address.street_number) companyData.place_of_supply.street_number = data.client[0].address.street_number;

                    if (data) this.setState(companyData)
                })
            }
            //onChange(event ? event : null);
        }}  onUpdate={(value) => onChange(value)} />
    );
}

if (process.env.NODE_ENV !== "production") {
    QuertyAsyncTypeAheadWidgetEIK.propTypes = {
        schema: PropTypes.object.isRequired,
        id: PropTypes.string.isRequired,
        placeholder: PropTypes.string,
        options: PropTypes.shape({
            rows: PropTypes.number,
        }),
        value: PropTypes.string,
        required: PropTypes.bool,
        disabled: PropTypes.bool,
        readonly: PropTypes.bool,
        autofocus: PropTypes.bool,
        onChange: PropTypes.func,
        onBlur: PropTypes.func,
        onFocus: PropTypes.func,
    };
}
export default QuertyAsyncTypeAheadWidgetEIK;
