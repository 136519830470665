import React,{Component} from 'react';
import i18n from "../../views/Pages/Login/i18n";
import {Query, withApollo} from "react-apollo";
import gql from "graphql-tag";
import _ from "lodash";
import {withRouter} from "react-router-dom";
import BasicQueryComponent from "../BasicQueryComponent";


class QueryBackButton extends BasicQueryComponent {

    constructor(props) {
        super(props);
        this.state = {};
    }


    render() {
        const findPath = (ob, searchKey) => {
            const path = [];
            const checkForOnlyOneKey = (obj) => (Object.keys(obj).length === 1);
            const checkForKey = (obj) => (obj[searchKey] === undefined);
            const isObject = (key) => typeof key === 'object';
            let timesEnter = 0;
            let found = false;
            let enteredPath = [];
            const clearPath = () => {
                for (let i = 0; i <= timesEnter; i++) {
                    path.pop();
                }
                    timesEnter = 0;
            }
            const clearEnteredPath = () => {
                for (let i = 0; i < enteredPath.length; i++) {
                    path.pop();
                }
                enteredPath = [];
            }

            const checkInnerObject = (obj , parentKey) => {
                    path.push(parentKey);
                const objectKeys = Object.keys(obj);
                for (let i = 0; i <= objectKeys.length; i++) {
                    if(!found){
                        if (!checkForKey(obj)) {
                            path.push(searchKey);
                            found = true;
                            break;
                        }else if (isObject(obj[objectKeys[i]])) {
                            timesEnter++;
                            checkInnerObject(obj[objectKeys[i]],objectKeys[i]);
                            enteredPath.push(objectKeys[i]);
                        }else {
                            clearEnteredPath();
                        }
                    }else {
                        break;
                    }
                }
            }
            if (checkForKey(ob)) {
                if (!checkForOnlyOneKey(ob)) {
                    const objectKeys = Object.keys(ob);
                    for (let i = 0; i < Object.keys(ob).length; i++) {
                        if (isObject(ob[objectKeys[i]]) && !found) {
                            clearPath();
                            checkInnerObject(ob[objectKeys[i]],objectKeys[i]);
                        }else if(found){
                            break;
                        }
                    }
                } else {
                        const objectKeys = Object.keys(ob);
                        if (isObject(ob[objectKeys[0]])) {
                            clearPath();
                            checkInnerObject(ob[objectKeys[0]],objectKeys[0]);
                        }
                    }
                return path;
            } else {
                return [];
            }
        }


        let variables;
        if (this.props.match.params["variables"]) {
            variables = JSON.parse(this.props.match.params["variables"][this.props.queryKey]);
        } else {
            variables = this.props.variables;
            if (variables) {
                const compiled = _.template(JSON.stringify(variables));
                variables = JSON.parse(compiled(this.props.match.params));
            }
        }

        const query = gql`${this.props.queries[this.props.queryKey]}`;
        return(
            <Query query={query} variables={variables} >
                {(result) => {
                    if(result.data && this.props.match){
                        let dataToDisplay = result.data;
                        const path = findPath(dataToDisplay, this.props.searchKey);
                        if(path.length === 0 && dataToDisplay[this.props.searchKey]){
                            dataToDisplay = dataToDisplay[this.props.searchKey];
                        }else if (path.length !== 0){
                            for (let i = 0; i < path.length; i++) {
                                dataToDisplay = dataToDisplay[path[i]];
                            }
                        }else { console.error("Make sure that this key exists in object you are giving! (QueryBackButton) "); dataToDisplay = "";}
                        return (
                            <button onClick={() => this.props.history.goBack()} className="btn btn-default btn btn-secondary"><i className="icon ion-arrow-left-b"></i>&nbsp; {i18n.t('businessPartner.invoice')}: &nbsp;&nbsp; {dataToDisplay} </button>
                        )
                    }else {
                        return null;
                    }
                }}
            </Query>
        )
    }
}

export default withApollo(withRouter(QueryBackButton));
//<QueryDownloadButton match={match} queryKey={"invoiceDocumentNo"}  queries={queries} variables={{ where: "id = '<%=billid%>'" }}/>
//<QueryBackButton match={match} queryKey={"invoiceDocumentNo"}  queries={queries} variables={{ startRow: 0, offset: 10, where: "id = '<%=billid%>'", orderBy: "name" }} />