import React, { useState } from 'react';
import {withApollo} from "react-apollo";
import JsonSchemaForm from 'react-jsonschema-form';
import {Button} from "reactstrap";
import {completeTaskMutation, getTasksQuery} from "../../queries/Queries";

const JsonSchemaFormA = (props) => {

    const handleSubmit = (data) => {
        const vars = [{name: props.taskDetails.outputVariableName || "formData", value: data, type: 'Json'}];
        props.client.mutate({
            variables: {task: {id: props.taskDetails.id, variables: vars}, service: props.taskDetails.service},
            mutation: completeTaskMutation,
            refetchQueries: [{query: getTasksQuery}]
        });
    };

    return (
        <div>
            <JsonSchemaForm {...props} onSubmit={handleSubmit}>
                <Button type="submit">Save and Continue</Button>
            </JsonSchemaForm>
        </div>
    )
}

export default withApollo(JsonSchemaFormA);