import React, {Component, Fragment} from 'react';
import {Button, Card, CardBody, Col, Row} from 'reactstrap';

class TariffOverview extends Component {
    constructor(props) {
        super(props);

        this.totalAmountGas = this.totalAmountGas.bind(this);
        this.totalAmountElectricity = this.totalAmountElectricity.bind(this);
        this.checkElectricityUnit = this.checkElectricityUnit.bind(this);
        this.totalAmountRate = this.totalAmountRate.bind(this);
    }

    checkElectricityUnit (rate) {
        return rate.sc !== null && rate.sc !== ''
            && (rate.dayRate || rate.nightRate || rate.singleRate || rate.eveningWeekendRate) !== null
            && (rate.dayRate || rate.nightRate || rate.singleRate || rate.eveningWeekendRate) !== '';
    }

    totalAmountRate (tariff, rate, commission) {
        return Object.keys(tariff).length > 0 && tariff[rate]
            ? (parseFloat(tariff[rate]) + parseFloat(commission)).toFixed(3) + ' p/kWh'
            : 'Price pending'
    }

    selectConsumptionSplit(rates) {
        let consumptionSplit = null;
        if(rates.nightRate && rates.dayRate && rates.eveningWeekendRate){
            consumptionSplit = window.config.consul.CONSUMPTION_THIRD_SPLIT;
            return {
                nightRate: consumptionSplit ? rates.nightRate * (consumptionSplit.night / 100) : rates.nightRate * (23.55 / 100),
                dayRate: consumptionSplit ? rates.dayRate * (consumptionSplit.day / 100) : rates.dayRate * (46.87 / 100),
                eveningWeekendRate: consumptionSplit ? rates.eveningWeekendRate * (consumptionSplit.eveningWeekend / 100) : rates.eveningWeekendRate * (29.58 / 100)
            };
        }else if (rates.nightRate && rates.dayRate){
            consumptionSplit = window.config.consul.CONSUMPTION_FIRST_SPLIT;
            return {
                nightRate: consumptionSplit ? rates.nightRate * (consumptionSplit.night / 100) : rates.nightRate * (27.01 / 100),
                dayRate: consumptionSplit ? rates.dayRate * (consumptionSplit.day / 100) : rates.dayRate * (72.99 / 100)
            };
        }else if(rates.dayRate && rates.eveningWeekendRate){
            consumptionSplit = window.config.consul.CONSUMPTION_SECOND_SPLIT;
            return {
                dayRate: consumptionSplit ? rates.dayRate * (consumptionSplit.day / 100) : rates.dayRate * (55.24 / 100),
                eveningWeekendRate: consumptionSplit ? rates.eveningWeekendRate * (consumptionSplit.eveningWeekend / 100) : rates.eveningWeekendRate * (44.76 / 100)
            };
        } else {
            return rates
        }
    }

    totalAmountGas(aq, tariff, commission, commissionSC) {
      const sc =
        parseFloat(tariff.sc ? tariff.sc : 0) +
        parseFloat(commissionSC ? commissionSC : 0);
      const unitRate =
        parseFloat(tariff.unitRate ? tariff.unitRate : 0) +
        parseFloat(commission ? commission : 0);

      return tariff.sc !== null && tariff.sc !== ""
        ? ((sc * 365) / 100 + (unitRate * aq) / 100).toLocaleString(undefined, {
            style: "currency",
            currency: "GBP",
            minimumFractionDigits: 2,
          })
        : "Price pending";
    }

    totalAmountElectricity(aq, tariff, commission, commissionSC, agreedCapacity = 0) {
        const isHHMeter =
            this.props.quote.isHHMeter ||
            (Object.hasOwn(this.props.quote, "agreedCapacity") &&
                Object.hasOwn(this.props.quote, "hasSignedAgentContract"));

        const sc =
            parseFloat(tariff.sc ? tariff.sc : 0) +
            parseFloat(commissionSC ? commissionSC : 0);

        const rates = {
        ...(tariff.hasOwnProperty("dayRate") && tariff.dayRate !== null
            ? { dayRate: parseFloat(tariff.dayRate) + parseFloat(commission) }
            : 0),
        ...(tariff.hasOwnProperty("nightRate") && tariff.nightRate !== null
            ? { nightRate: parseFloat(tariff.nightRate) + parseFloat(commission) }
            : 0),
        ...(tariff.hasOwnProperty("eveningWeekendRate") &&
        tariff.eveningWeekendRate !== null
            ? {
                eveningWeekendRate:
                parseFloat(tariff.eveningWeekendRate) + parseFloat(commission),
            }
            : 0),
        ...(tariff.hasOwnProperty("singleRate") && tariff.singleRate !== null
            ? { singleRate: parseFloat(tariff.singleRate) + parseFloat(commission) }
            : 0),
        };

        const selectedConsumptionSplit = this.selectConsumptionSplit(rates);
        const summaryOfRates = Object.values(selectedConsumptionSplit).reduce(
        (acc, curr) => {
            return (acc += curr);
        },
        0
        );

        let hhMeterAdditionalRates = 0;
        if (isHHMeter) {
        const hhRates = {
            kVaCharge:
                tariff.hasOwnProperty("kVaCharge") && tariff.kVaCharge !== null
                    ? parseFloat(tariff.kVaCharge)
                    : 0,
            meteringCharge:
                tariff.hasOwnProperty("meteringCharge") && tariff.meteringCharge !== null
                    ? parseFloat(tariff.meteringCharge)
                    : 0,
        };        
        hhMeterAdditionalRates =
            (hhRates.kVaCharge * agreedCapacity + hhRates.meteringCharge) * 365;
        }      

        return tariff.sc !== null && tariff.sc !== ""
            ? (
                    (sc * 365 + summaryOfRates * aq + hhMeterAdditionalRates) /
                    100
                ).toLocaleString(undefined, {
                    style: "currency",
                    currency: "GBP",
                    minimumFractionDigits: 2,
                    })
            : "Price pending";
    }

    render() {
        const quote = this.props.quote;
        const tariff = quote.tariffName;
        const products = quote.products;
        let contDuration = quote.contractDuration;
        let {commissiongasUnitRate, commissiongasStandingCharge, commissionelectricity, commissionelectricityStandingCharge} = this.props.quote;
        let scValue = 'Price pending';
        const aq = quote.annualgas ? quote.annualgas : (quote.aq ? quote.aq : 0);
        const aqEl = quote.annualelectricity ? quote.annualelectricity : (quote.aq ? quote.aq : 0);

        if (!commissiongasUnitRate && !commissionelectricity) {
            commissiongasUnitRate = products ? products.filter(({name}) => name === 'Gas').reduce((acc, curr) => curr.commissionUnitRate || 0, {commissionUnitRate: 0}) : 0;
            commissiongasStandingCharge = products ? products.filter(({name}) => name === 'Gas').reduce((acc, curr) => curr.commissionStandingCharge || 0, {commissionStandingCharge: 0}) : 0;
            commissionelectricity = products ? products.filter(({name}) => name === 'Electricity').reduce((acc, curr) => curr.commissionelectricityStandingCharge, {commissionStandingChargeElectricity: 0}) : 0;
            commissionelectricityStandingCharge = products ? products.filter(({name}) => name === 'Electricity').reduce((acc, curr) => curr.commissionelectricityStandingCharge, {commissionelectricityStandingCharge: 0}) : 0;
        }

        // Check if contractDuration has been set, if not get it from tarrifName.term
        if (contDuration === undefined || contDuration === null || contDuration === "") {
            contDuration = tariff.term;
        }

        if (Object.keys(tariff).length > 0 && tariff.sc) {
            const commission = this.props.quoteType === 'gas' || this.props.quote.services === 'gas'
                ? commissiongasStandingCharge
                : commissionelectricityStandingCharge
            scValue = (parseFloat(tariff.sc) + parseFloat(commission)).toFixed(3) + ' p/day';
        }

        const rateValues = {
            unitRate: this.totalAmountRate(tariff, 'unitRate', commissiongasUnitRate),
            dayRate: this.totalAmountRate(tariff, 'dayRate', commissionelectricity),
            nightRate: this.totalAmountRate(tariff, 'nightRate', commissionelectricity),
            singleRate: this.totalAmountRate(tariff, 'singleRate', commissionelectricity),
            eveningWeekendRate: this.totalAmountRate(tariff, 'eveningWeekendRate', commissionelectricity),
        };

        return (<div>
            <Row>
                <Col className='col-6'>
                    <Card className="quote-preview-box">
                        <CardBody style={{marginBottom: '-2em'}}>
                            <Row>
                                <Col>
                                    <h4>{this.props.quoteType === 'gas' || this.props.quote.services === 'gas' ? 'GAS' : 'Electricity'}</h4>
                                </Col>
                                <Col className="col-12">
                                    &nbsp;
                                </Col>
                                <Col className="col-12">
                                    <table className="rates">
                                        <tbody>
                                        {(this.props.quoteType === 'gas' || this.props.quote.services === 'gas') &&
                                            <tr>
                                                <td>Unit Rate</td>
                                                <td>{rateValues.unitRate}</td>
                                            </tr>
                                        }
                                        {(this.props.quoteType === 'electricity' || this.props.quote.services === 'electricity') &&
                                            <Fragment>
                                                {tariff.dayRate !== undefined && tariff.dayRate !== null &&
                                                    <tr>
                                                        <td>Day Rate</td>
                                                        <td>{rateValues.dayRate}</td>
                                                    </tr>
                                                }
                                                {(tariff.nightRate !== undefined && tariff.nightRate !== null) &&
                                                    <tr>
                                                        <td>Night Rate</td>
                                                        <td>{rateValues.nightRate}</td>
                                                    </tr>
                                                }
                                                {(tariff.singleRate !== undefined  && tariff.singleRate !== null) &&
                                                    <tr>
                                                        <td>Single Rate</td>
                                                        <td>{rateValues.singleRate}</td>
                                                    </tr>
                                                }
                                                {(tariff.eveningWeekendRate !== undefined  && tariff.eveningWeekendRate !== null) &&
                                                    <tr>
                                                        <td>Evening/Weekend Rate</td>
                                                        <td>{rateValues.eveningWeekendRate}</td>
                                                    </tr>
                                                }
                                                {(tariff.kVaCharge !== undefined  && tariff.kVaCharge !== null) &&
                                                    <tr>
                                                        <td>kVa Charge</td>
                                                        <td>{tariff.kVaCharge !== '' ? parseFloat(tariff.kVaCharge).toFixed(3) + ' p/kVa/day' : 'Price pending'}</td>
                                                    </tr>
                                                }
                                                {(tariff.meteringCharge !== undefined  && tariff.meteringCharge !== null) &&
                                                    <tr>
                                                        <td>Metering Charge</td>
                                                        <td>{tariff.meteringCharge !== '' ? parseFloat(tariff.meteringCharge).toFixed(3) + ' p/day' : 'Price pending'}</td>
                                                    </tr>
                                                }
                                            </Fragment>
                                        }
                                        <tr>
                                            <td>Standing Charge</td>
                                            <td>{scValue}</td>
                                        </tr>
                                        <tr>
                                            <td>Contract length</td>
                                            <td>{parseInt(contDuration) || 0} months</td>
                                        </tr>
                                        <tr>
                                            <td>Total annual cost</td>
                                            <td>
                                                {this.props.quoteType === 'gas' || this.props.quote.services === 'gas' || (this.props.quote.products && this.props.quote.products[0].name === 'Gas')
                                                  ? this.totalAmountGas(aq, tariff, commissiongasUnitRate, commissiongasStandingCharge)
                                                  : this.totalAmountElectricity(
                                                      aqEl,
                                                      tariff,
                                                      commissionelectricity,
                                                      commissionelectricityStandingCharge,
                                                      this.props.quote.agreedCapacity
                                                    )
                                                }
                                            </td>
                                        </tr>
                                        {(window.config.consul.DYCE || window.DYCE || (typeof DYCE !== 'undefined' ? DYCE : '')) &&
                                            <tr>
                                                <td>Unit Rate Uplift</td>
                                                {this.props.quoteType === 'gas' || this.props.quote.services === 'gas' ?
                                                    (
                                                        <td>{tariff.unitRate !== null && tariff.unitRate !== '' ? parseFloat(commissiongasUnitRate).toFixed(3) + ' p/kWh' : 'Price pending'}</td>
                                                    ) : (
                                                        <td>{this.checkElectricityUnit(tariff)? parseFloat(commissionelectricity).toFixed(3) + ' p/kWh' : 'Price pending'}</td>
                                                    )
                                                }
                                            </tr>}
                                        {(window.config.consul.DYCE || window.DYCE || (typeof DYCE !== 'undefined' ? DYCE : '')) &&
                                            <tr>
                                                <td>Standing Charge Uplift</td>
                                                {this.props.quoteType === 'gas' || this.props.quote.services === 'gas' ?
                                                    (
                                                       <td>{tariff.sc !== null && tariff.sc !== '' ? parseFloat(commissiongasStandingCharge).toFixed(3) + ' p/day' : 'Price pending'}</td>
                                                    ) : (
                                                        <td>{this.checkElectricityUnit(tariff) ? parseFloat(commissionelectricityStandingCharge).toFixed(3) + ' p/kWh' : 'Price pending'}</td>
                                                    )
                                                }
                                            </tr>}
                                        </tbody>
                                    </table>
                                    {Object.keys(tariff).length > 0 && tariff.name && tariff.name.toLowerCase().includes('advanced') && window.config.consul.UPFRONT_PAYMENTS_MSG_ENABLED &&
                                        <blockquote>This is a discounted advanced saver tariff – a payment of one months energy usage will be taken shortly after go live date.
                                            Please inform your customer. More information can be found
                                            <Button color='primary' style={{
                                                position: 'relative',
                                                bottom: '0.1em',
                                                marginLeft: '0.3em',
                                                padding: '0 0.5em 0 0.5em'
                                            }}>
                                                <a style={{textDecoration: 'none', color: 'white'}} target={'_blank'}
                                                   href={'https://dyce-energy.co.uk/faqs/'}>HERE</a>
                                            </Button>
                                        </blockquote>
                                    }
                                    {window.config.consul.SMART_METER_MSG_ENABLED &&
                                        <blockquote style={{marginTop: '1em'}}>Prices are subject to the installation of a
                                        smart meter, if the meter is eligible. Where installation is refused we reserve
                                        the right to amend contract prices to our Smart Default Rate*</blockquote>
                                    }
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </Row> 
            <Row>
                <Col className='col-6'>
                    {(window.config.consul.DYCE || window.DYCE || (typeof DYCE !== 'undefined' ? DYCE : '')) && 
                        !(window.config.consul.OSSO || window.OSSO || (typeof OSSO !== 'undefined' ? OSSO : '')) &&
                        !(window.config.consul.METHODIA || window.METHODIA || (typeof METHODIA !== 'undefined' ? METHODIA : '')) &&
                            <blockquote>*Prices are exclusive of CCL and VAT<br/>
                                *Prices are subject to credit approval and Dyce Energy acceptance.<br/>
                                *Prices are for online billing only; paper billing will be charged @ £2 per invoice per
                                month.<br/>
                                *Prices are subject to market movement and Dyce Energy acceptance
                            </blockquote>
                    }

                    {(window.config.consul.METHODIA || window.METHODIA || (typeof METHODIA !== 'undefined' ? METHODIA : '')) || 
                        (
                            !(window.config.consul.DYCE || window.DYCE || (typeof DYCE !== 'undefined' ? DYCE : '')) &&
                            !(window.config.consul.OSSO || window.OSSO || (typeof OSSO !== 'undefined' ? OSSO : ''))
                        ) &&
                            <blockquote>
                                *Prices shown are exclusive of CCL & VAT<br/>
                                *Prices shown are subject to Credit Approval<br/>
                                *Price shown are subject to accepting our Terms and Conditions provided with the contract
                            </blockquote>
                    }
                </Col>
            </Row>
        </div>)
    }
}

export default TariffOverview;