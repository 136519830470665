import React from 'react';
import { Step } from 'react-albus';

const StepA = (props) => {

  function recursiveMap(children, extraProps) {
    return React.Children.map(children, child => {
      if (!React.isValidElement(child)) {
        return child;
      }

      if (child.props.children) {
        child = React.cloneElement(child, {
          children: recursiveMap(child.props.children, extraProps),
          ...extraProps
        });
      }

      return React.cloneElement(child, extraProps);
    });
  }

  const {children, ...properties} = props;
  return (
    <Step {...properties} render={({ next, previous, step, steps }) => (
      recursiveMap(children, {next: next, previous: previous, step: step, steps: steps})
    )}/>
  )
}

export default StepA;
