import React from "react";
import {Col, Row, NavItem, NavLink, Nav, TabContent} from "reactstrap";
import i18n from '../../views/Pages/Login/i18n';
import classnames from 'classnames';
import { withRouter, matchPath } from 'react-router'

class NavTabs extends React.Component {
    constructor(props) {
        super(props);
        this.toggle = this.toggle.bind(this);
        this.tabKey = 'tab_' + this.props.tabKey || 'tab_default';



        for (const key in this.props.items) {
            const item = this.props.items[key];
            if (item.path) {
                const match = matchPath(location.hash.substr(1), {
                    path: "/view/:name" + item.path,
                    exact: false,
                    strict: false
                })
                if (match) {
                    sessionStorage[this.tabKey] = item.key;
                };
            }
        }

        this.state = {
            activeTab: sessionStorage[this.tabKey] || '1'
        };
    }

    toggle(tab) {
        if (this.state.activeTab !== tab) {
            sessionStorage[this.tabKey] = tab;
            this.setState({
                activeTab: tab
            });
        }
    }

    render() {

        const navItems = [];
        for (const key in this.props.items) {
            const item = this.props.items[key];
            navItems.push(<NavItem key={item.key}><NavLink className={classnames({active: this.state.activeTab === item.key})}
                                            onClick={() => {
                                                this.toggle(item.key);
                                            }}>{i18n.t(item.title)}</NavLink></NavItem>)
        }
        return (<div style={{width:"100%"}}>
            <div className='bp-nav-items'>
                <Nav tabs>
                    {navItems}
                </Nav>
            </div>
            <TabContent activeTab={this.state.activeTab} className='bp-tab-content'>
                {this.props.children}
            </TabContent></div>);
    }
}

export default withRouter(NavTabs);