import React, {Component, Fragment} from 'reactn';
import {Link} from 'react-router-dom';
import {graphql, compose} from "react-apollo";
import classnames from 'classnames';
import Loading from "../../components/Loading"
import i18n from '../Pages/Login/i18n';
import {
    TabContent,
    TabPane,
    Nav,
    NavItem,
    NavLink,
    Card,
    Button,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    CardTitle,
    CardBody,
    CardHeader,
    CardText,
    Row,
    Col,
    Form,
    FormGroup,
    Label,
    InputGroup,
    Input,
    FormText,
    Modal,
    ModalHeader,
    ModalBody
} from 'reactstrap';
import NotesAndAttachments from "../../components/NotesAndAttachments";
import QuotesGrid from "../../components/QuotesGrid/QuotesGrid";
import AgreementsGrid from "../../components/AgreementsGrid/AgreementsGrid";
import CommissionReport from "../../components/CommissionReport/CommissionReport";
import Tariffs from "../../components/Tariffs/Tariffs";
import SalesAgentsGrid from "../../components/SalesAgentsGrid/SalesAgentsGrid";
import {withRouter} from 'react-router';
import moment from "moment";
import {getBusinessPartnerQuery, brokerBalance, getContractQuery} from "../../queries/Queries";

class BrokerBalance extends Component {
    render() {
        let brokerBalance = this.props.brokerBalance.OB_brokerBalance;
        return (
        <Fragment>
            <Col className="col-sm-6">
                <FormGroup>
                    <FormText>Balance</FormText>
                    <Label>{brokerBalance ? (brokerBalance.totalAmount || brokerBalance.totalAmount === 0 ? i18n.t('currency') + brokerBalance.totalAmount : "") : ""}</Label>
                </FormGroup>
            </Col>
        </Fragment>
        )
    }
}

class Organisation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeTab: '1'
        };
        this.toggle = this.toggle.bind(this);

    }

    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        }
    }

    render() {

        const BrokerBalanceQueryComponent = graphql(brokerBalance, {
            name: 'brokerBalance',
            options: props => {
                return ({
                variables: {
                    page: {
                        pageNumber: 0,
                        pageSize: 3
                    },
                    specification: {
                        where: "id is not null"
                    },
                    businessPartnerId: this.props.match.params.id ? this.props.match.params.id : this.global.salesOrganization,
                    from: moment().subtract(2, 'years').format('DD-MM-YYYY'),
                    to: moment().format('DD-MM-YYYY')
                }
            })},
        })(BrokerBalance);


        if (this.props.getBusinessPartner.businessPartner && !this.props.getBusinessPartner.businessPartner.length) {
            return (<div className="organization">
                <div>There are no organizations.</div>
            </div>)
        } else if (this.props.getBusinessPartner.businessPartner) {
            let businessPartner = this.props.getBusinessPartner.businessPartner[0];
            let contract = businessPartner.mDMContractList.filter(({mDMContractStatus}) => mDMContractStatus.name.includes("Draft") || mDMContractStatus.name.includes("Signed"))[0];
            const organizationId = this.props.match.params.id ? this.props.match.params.id : this.global.salesOrganization;
            return (
                <div className="organization">
                    <Row className='subheader'>
                        <Col className='col-8'>
                            <h1><img width="64" height="64"
                                     src={(window.config.consul.CORE_URL || window.CORE_URL || (typeof CORE_URL !== 'undefined' ? CORE_URL : '')) + '/logo/' + organizationId}
                                     style={{borderRadius: "50%", marginRight: "20px"}}/>{businessPartner.name}</h1>
                        </Col>
                    </Row>

                    <div className='bp-nav-items'>
                        <Nav tabs>
                            <NavItem>
                                <NavLink className={classnames({active: this.state.activeTab === '1'})}
                                         onClick={() => {
                                             this.toggle('1');
                                         }}>{i18n.t('organization.account')}</NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink className={classnames({active: this.state.activeTab === '2'})}
                                         onClick={() => {
                                             this.toggle('2');
                                         }}>{i18n.t('organization.quotes')}</NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink className={classnames({active: this.state.activeTab === '3'})}
                                         onClick={() => {
                                             this.toggle('3');
                                         }}>{i18n.t('organization.agreements')}</NavLink>
                            </NavItem>
                            {this.global.permissions.includes('SalesOrganizations.TariffManagement') && <NavItem>
                                <NavLink className={classnames({active: this.state.activeTab === '4'})}
                                         onClick={() => {
                                             this.toggle('4');
                                         }}>{i18n.t('organization.tariffs')}</NavLink>
                            </NavItem>}
                            {this.global.permissions.includes('SalesOrganizations.AgentManagement') && <NavItem>
                                <NavLink className={classnames({active: this.state.activeTab === '5'})}
                                         onClick={() => {
                                             this.toggle('5');
                                         }}>{i18n.t('organization.agents')}</NavLink>
                            </NavItem>}
                            {(this.global.permissions.includes('SalesOrganizations.CommissionReportManagementAny')
                                || this.global.permissions.includes('SalesOrganizations.CommissionReportManagementChildren') && !this.global.ultimateParentOrganization) &&
                            <NavItem>
                                <NavLink className={classnames({active: this.state.activeTab === '6'})}
                                         onClick={() => {
                                             this.toggle('6');
                                         }}>{i18n.t('organization.reports')}</NavLink>
                            </NavItem>}
                        </Nav>
                    </div>


                    <TabContent activeTab={this.state.activeTab} className='bp-tab-content'>
                        {this.state.activeTab === '1' && <TabPane tabId="1">
                            <Row>
                                <Col className="col-lg-6 col-md-6 col-sm-12">
                                    <Card>
                                        <CardHeader>
                                            <Row>
                                                <Col className="col-12">
                                                    Details
                                                </Col>
                                            </Row>
                                        </CardHeader>
                                        <CardBody>
                                            <Row>
                                                <Col className="col-sm-6">
                                                    <FormGroup>
                                                        <FormText>Company Number</FormText>
                                                        <Label>{businessPartner.mdmEik}</Label>
                                                    </FormGroup>
                                                </Col>
                                                <Col className="col-sm-6">
                                                    <FormGroup>
                                                        <FormText>Contract name</FormText>
                                                        <Label>{contract ? contract.marketingProduct.name : ''}</Label>
                                                    </FormGroup>
                                                </Col>
                                                <Col className="col-sm-6">
                                                    <FormGroup>
                                                        <FormText>Category</FormText>
                                                        <Label>{businessPartner.businessPartnerCategory.name}</Label>
                                                    </FormGroup>
                                                </Col>
                                                <Col className="col-sm-6">
                                                    <FormGroup>
                                                        <FormText>Date Signed</FormText>
                                                        <Label>{contract ? moment(contract.signdate).toDate().toLocaleDateString() : ''}</Label>
                                                    </FormGroup>
                                                </Col>
                                                <Col className="col-sm-6">
                                                    <FormGroup>
                                                        <FormText>Status</FormText>
                                                        <Label>{businessPartner.isActive ? 'Active' : 'Deactivated'}</Label>
                                                    </FormGroup>
                                                </Col>
                                                {(window.config.consul.BILLING_FOR_BROKERS || window.BILLING_FOR_BROKERS || (typeof BILLING_FOR_BROKERS !== 'undefined' && BILLING_FOR_BROKERS))
                                                && this.global.permissions.includes("BillingForBrokers.ViewBalance")
                                                && <BrokerBalanceQueryComponent/>
                                                }
                                            </Row>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                            <Row>
                                <NotesAndAttachments noteList={businessPartner.noteList}
                                                     attachmentList={businessPartner.attachmentList}
                                                     recordId={businessPartner.id}
                                                     tableName={"BusinessPartner"}
                                                     refetchQueries={[{
                                                         query: getBusinessPartnerQuery,
                                                         variables: {
                                                             startRow: 0,
                                                             offset: 10,
                                                             where: "id = '" + this.global.salesOrganization + "'",
                                                             orderBy: "name"
                                                         }
                                                     }]}/>
                            </Row>
                        </TabPane>}
                        {this.state.activeTab === '2' && <TabPane tabId="2">
                            <QuotesGrid organizationId={organizationId}
                                        username={(this.global.permissions.includes('Quotes.ViewAnyQuote') || this.global.permissions.includes('Quotes.ViewAnyOwnOrganizationQuote')) ? null : this.global.username}/>
                        </TabPane>}
                        {this.state.activeTab === '3' && <TabPane tabId="3">
                            <AgreementsGrid organizationId={organizationId}
                                            username={(this.global.permissions.includes('Agreements.ViewAnyAgreement') || this.global.permissions.includes('Agreements.ViewAnyOwnOrganizationAgreement')) ? null : this.global.username}
                                            currentUser={this.props.currentUser}
                                            />
                        </TabPane>}
                        {this.state.activeTab === '4' && <TabPane tabId="4">
                            <Tariffs/>
                        </TabPane>}
                        {this.state.activeTab === '5' && <TabPane tabId="5">
                            <SalesAgentsGrid currentUser={this.props.currentUser} organizationId={organizationId} />
                        </TabPane>}
                        {this.state.activeTab === '6' && <TabPane tabId="6">
                            <CommissionReport organizationId={organizationId}/>
                        </TabPane>}

                    </TabContent>

                </div>
            )
        } else {
            return <Loading/>;
        }
    }

}

export default graphql(getBusinessPartnerQuery, {
    name: 'getBusinessPartner',
    options: props => ({
        variables: {
            startRow: 0,
            offset: 10,
            where: "id = '" + (props.match.params.id ? props.match.params.id : props.salesOrganization) + "'",
            orderBy: "name"
        }
    }),
})(withRouter(Organisation));