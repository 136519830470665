import React from "react";
import PropTypes from "prop-types";
import i18n from "../Pages/Login/i18n";
import QuerySearch from "../../components/QuerySearch";
import gql from "graphql-tag";
import {getUsers} from "../../queries/Queries";
import ReactDOM from "react-dom";
import {Typeahead} from "react-bootstrap-typeahead";
import {Query} from "react-apollo";
import ExpiringAlert from "../../components/ExpiringAlert";

function QuertyTypeAheadWidget2(props) {
    const {
        options,
        value,
        required,
        disabled,
        readonly,
        autofocus,
        onBlur,
        onFocus,
        onChange,
        id,
    } = props;

    // Generating a unique field name to identify this set of radio buttons
    const name = Math.random().toString();
    const { enumOptions, enumDisabled, inline } = options;
    // checked={checked} has been moved above name={name}, As mentioned in #349;
    // this is a temporary fix for radio button rendering bug in React, facebook/react#7630.
    const point_of_supply = gql`query getPointOfSupplyByTypeAndCity($cityId: String, $type: String) {
          city_point_of_supply(where: {city_id: {_eq: $cityId}, point_of_supply: {type: {_eq: $type}}}) {
            point_of_supply {
              name
            }
          }
        }`;
    return (
        <Query query={point_of_supply} variables={{cityId: props.formContext["place-of-supply"].locality.city_id, type: props.formContext.service.toLowerCase()}} >
            {(result) => {
                if (result.loading) return "Loading...";
                if (result.error) ReactDOM.render(<ExpiringAlert color="danger"
                                                                 message={result.error.message}/>, document.getElementById('alert').appendChild(document.createElement("div")));
                const {data} = result;
                console.log(data);
                let selectedValue = props.value;
                if (data.city_point_of_supply.length === 1) {
                    selectedValue = data.city_point_of_supply[0].point_of_supply[0].name;
                    props.onChange(selectedValue);
                }
                return <Typeahead ref="typeahead"
                                  options={data.city_point_of_supply}
                                  readonly={readonly}
                                  labelKey={option => {console.log(option); return `${option.point_of_supply[0].name}`}}
                                  selectHintOnEnter={true}
                                  placeholder={props.placeholder}
                                  filterBy = {['point_of_supply[0].name']}
                                  clearButton
                                  onChange={(event) => {
                                      props.onChange(event[0] && event[0].point_of_supply ? event[0].point_of_supply[0].name : null);
                                  }}
                                  selected={selectedValue ? [ {point_of_supply: [{name: selectedValue}]}] : []}
                />
            }
            }
        </Query>
    );
}

if (process.env.NODE_ENV !== "production") {
    QuertyTypeAheadWidget2.propTypes = {
        schema: PropTypes.object.isRequired,
        id: PropTypes.string.isRequired,
        placeholder: PropTypes.string,
        options: PropTypes.shape({
            rows: PropTypes.number,
        }),
        value: PropTypes.string,
        required: PropTypes.bool,
        disabled: PropTypes.bool,
        readonly: PropTypes.bool,
        autofocus: PropTypes.bool,
        onChange: PropTypes.func,
        onBlur: PropTypes.func,
        onFocus: PropTypes.func,
    };
}
export default QuertyTypeAheadWidget2;
