import axios from "axios";
import React from 'react';
import ReactDOM from "react-dom";
import ExpiringAlert from '../components/ExpiringAlert';
import i18n from '../views/Pages/Login/i18n';

const client = axios.create();
client.interceptors.response.use(undefined, err => {
    if (err.response.status === 302) {
        const expiringAlert = <ExpiringAlert color="danger" message={i18n.t('error.redirect')}/>
        ReactDOM.render(expiringAlert, document.getElementById('alert'));
        return Promise.reject(i18n.t('error.redirect'));
    }
    return Promise.resolve(err);
});

export default client;