import React, { Component } from 'react';
import i18n from "../Pages/Login/i18n";
import {
    Col,
    Row,
    Button,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Dropdown
} from "reactstrap";
import QuotesGrid from "../../components/QuotesGrid/QuotesGrid";
import { Link } from 'react-router-dom';

class Quotes extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dropDownOpen: false,
            type: '',
        }
        this.toggle = this.toggle.bind(this);
    }

    toggle() {
        this.setState({dropDownOpen: !this.state.dropDownOpen})
    }

    render() {

        return (
            <div className={"organization"}>
                <Row className='subheader'>
                    <Col className='col-8'>
                        <h1>{i18n.t('Quotes')}</h1>
                    </Col>
                    <Col className="col-4">
                            <Dropdown isOpen={this.state.dropDownOpen} className='pull-right' color="primary" toggle={this.toggle}>
                                <DropdownToggle className='pull-right' color="primary">
                                    <i className="icon ion-plus-round"></i>&nbsp;&nbsp;{i18n.t('quotes.newQuote')}
                                </DropdownToggle>
                                <DropdownMenu>
                                    <Link style={{textDecoration: 'none'}} to={{pathname: "/create-quote", quoteType: 'gas'}}><DropdownItem>&nbsp;&nbsp;Gas</DropdownItem></Link>
                                    <Link style={{textDecoration: 'none'}} to={{pathname: "/create-quote", quoteType: 'electricity'}}><DropdownItem>&nbsp;&nbsp;Electricity</DropdownItem></Link>
                                </DropdownMenu>
                            </Dropdown>
                    </Col>
                </Row>
                <div className="page-cnt">
                     <QuotesGrid {...this.props}/>
                </div>
            </div>
        )
    }
}

export default Quotes;
