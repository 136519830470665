import React from "react";
import { Query } from 'react-apollo';
import gql from "graphql-tag";
import {withRouter} from "react-router-dom";
import _ from "underscore";

import {dateFormatter, rolesFormatter, prefCommChannelFormatter, priceFormatter} from "../../utils/Formatters";
import Loading from "../Loading"
import SignContractButton from "../SignContractButton";
import jsonata from "./../../../node_modules/jsonata/jsonata-es5"

class QuerySignContractButton extends React.Component {

    constructor(props) {
        super(props);
        this.setParents = this.setParents.bind(this);
        this.dateFormatter = dateFormatter.bind(this);
        this.rolesFormatter = rolesFormatter.bind(this);
        this.prefCommChannelFormatter = prefCommChannelFormatter.bind(this);
        this.priceFormatter = priceFormatter.bind(this);
    }

    setParents(obj, parents){
        let newObj = JSON.parse(JSON.stringify(obj));
        const ownProps = Object.keys(newObj);
        for (let ownProp of ownProps) {
            if (newObj[ownProp] instanceof Object || newObj[ownProp] instanceof Array) {
                let newparents = parents.slice();
                newparents.unshift(obj);
                newObj[ownProp] = this.setParents(newObj[ownProp], newparents);
            }
        }
        newObj.__parents = parents;
        return newObj;
    }

    getDescendantProp (obj, desc) {
        if(desc) {
            var arr = desc.replace(']', '').split(/\[|\./);
            while (arr.length && (obj = obj[arr.shift()])) ;
            return obj;
        }
    }

    render() {
        let variables;
        let info;

        if (this.props.match.params["variables"]) {
            variables = JSON.parse(this.props.match.params["variables"][this.props.queryKey]);
        } else {
            variables = this.props.variables;
            if (variables) {
                const compiled = _.template(JSON.stringify(variables));
                variables = JSON.parse(compiled(this.props.match.params));
            }
        }

        if (this.props.match.params["info"]) {
            info = JSON.parse(this.props.match.params["info"][this.props.queryKey]);
        } else {
            info = this.props.info;
            if (info) {
                const compiled = _.template(JSON.stringify(info));
                info = JSON.parse(compiled(this.props.match.params));
            }
        }

        // console.log("this.props.queries.queryKey : " + JSON.stringify(this.props.queries[this.props.queryKey]));
        // console.log("Info are : " + JSON.stringify(info));

        return (<Query query={gql`${this.props.queries[this.props.queryKey]}`} variables={variables}>
            {(result) => {
                if (result.loading) return (<Loading />);//<Loading/>;
                if (result.error) return <div>{result.error} </div>;
                const { data } = result;

                if(Object.keys(data).length == 0){
                    // setTimeout(() => { this.props.client.resetStore(); }, 0);
                }
                let expr = null;
                if (this.props.expression) {
                    const compiled = _.template(this.props.expression);
                    expr = compiled(this.props.match.params);
                }
                const expression = expr ? jsonata(expr) : null;
                const transformedData = expression ? expression.evaluate(data) : data;
                const dataWithParents = this.setParents(transformedData, []);
                for (let x of this.props.info) {
                    if ( x.expression ) {
                        x.value = this.getDescendantProp(dataWithParents, x.value);
                        x.expression = false;
                        if(x.localExpression){
                            const localExpr = jsonata(x.localExpression);
                            x.value = localExpr.evaluate(x.value);
                        }
                    }
                }

                for (let x of this.props.info) {
                    if ( x.formatter) {
                        x.value = this[x.formatter](x.value, x.value);
                    }
                }
                const { children } = this.props;
                const { queries, queryKey, variables, title, ...newProps } = this.props;
                //console.log("dataWithParents is :" + JSON.stringify(dataWithParents));
                //console.log("this.props finally is : "  + JSON.stringify(this.props));
                return (
                        <SignContractButton {...newProps} data={dataWithParents} />
                        )
            }}</Query>);
    }
}
export default withRouter(QuerySignContractButton);
