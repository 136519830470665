import React from 'react';
import ButtonWithModal from '../components/ButtonWithModal';
import axios from "./Client";
import jsonata from "./../../node_modules/jsonata/jsonata-es5"
import fileDownload from "js-file-download";
import {Button} from "reactstrap";
import GridDropDownMenu from "../components/GridDropDownMenu";
import {Link} from "react-router-dom";
import _ from "underscore";
import {Mutation} from "react-apollo";
import gql from "graphql-tag";
import moment from "moment";

export function priceCustomFormatter(cell, row) {

    const findCurrency = function (row) {
        let result = null;
        if (row.currency) {
            result = row.currency;
        } else {
            const properties = Object.keys(row);
            for (let prop of properties) {
                if (!result && (row[prop] instanceof Object || row[prop] instanceof Array)) {
                    result = findCurrency(row[prop]);
                }
            }
        }
        return result;
    }
    const currency = row ? findCurrency(row) : {iSOCode: 'GBP'};
    const signDigits = this.significantDigits ? {
        maximumSignificantDigits: this.significantDigits,
        minimumSignificantDigits: this.significantDigits
    } : {};
    return cell ? cell.toLocaleString(undefined, {
        style: "currency",
        currency: currency && currency.iSOCode ? currency.iSOCode : 'GBP', ...signDigits
    }) : "";
}


export function priceFormatter(cell, row, rowIndex, formatExtraData) {
    if (isNaN(Number(cell))) return cell;
    cell = Number(cell);

    if (formatExtraData === 'pence') {
        return `${parseFloat((cell * 100).toFixed(2))}p`;
    }

    const findObject = function (row, objectName) {
        let result = null;
        if (row[objectName]) {
            result = row[objectName];
        } else {
            const properties = Object.keys(row);
            for (let prop of properties) {
                if (!result && (row[prop] instanceof Object || row[prop] instanceof Array)) {
                    result = findObject(row[prop], objectName);
                }
            }
        }
        return result;
    };
    const currency = row ? findObject(row, 'currency') : {iSOCode: 'GBP'};
    const subUnit = row ? findObject(row, 'mdmCoins') : 'p';
    const useSubUnit = row ? findObject(row, 'isMdmDisplayCoins') : false;
    if (!useSubUnit) {
        const fractionalDigits = Math.floor(cell) !== cell ? Math.max(("" + Math.round(cell * Math.pow(10, 10)) / Math.pow(10, 10)).split(".")[1].length, 2) : 2;
        return cell.toLocaleString(undefined, {
            style: "currency",
            currency: currency && currency.iSOCode ? currency.iSOCode : 'GBP',
            minimumFractionDigits: fractionalDigits
        });
    } else {
        return (cell * Math.pow(10, currency.standardPrecision)).toFixed(2) + subUnit;
    }
}

export function agreementsPriceFormatter(cell, row) {
    const findObject = function (row, objectName) {
        let result = null;
        if (row[objectName]) {
            result = row[objectName];
        } else {
            const properties = Object.keys(row);
            for (let prop of properties) {
                if (!result && (row[prop] instanceof Object || row[prop] instanceof Array)) {
                    result = findObject(row[prop], objectName);
                }
            }
        }
        return result;
    };
    const currency = row ? findObject(row, 'currency') : {iSOCode: 'GBP'};
    const subUnit = row ? findObject(row, 'mdmCoins') : 'p';
    const useSubUnit = row ? findObject(row, 'isMdmDisplayCoins') : false;
    if (!useSubUnit) {
        const fractionalDigits = cell && Math.floor(cell) !== cell ? Math.max(("" + Math.round(cell * Math.pow(10, 10)) / Math.pow(10, 10)).split(".")[1].length, 2) : 2;
        return cell ? cell.toLocaleString(undefined, {
            style: "currency",
            currency: currency && currency.iSOCode ? currency.iSOCode : 'GBP',
            minimumFractionDigits: fractionalDigits
        }) : "";
    } else {
        return (cell * Math.pow(10, currency.standardPrecision)).toFixed(3) + subUnit;
    }
}

export function dateFormatter(cell, row) {
    return (
        cell ? new Date(cell).toLocaleDateString() : null
    );
}

export function dateTimeFormatter(cell, row) {
    return (
        cell ? new Date(cell).toLocaleString() : null
    );
}

export function periodFormatter(cell, row) {
    return (
        cell ? new Date(cell.startingDate).toLocaleDateString() + ' – ' + new Date(cell.endingDate).toLocaleDateString() : null
    );
}

export function productFormatter(cell, row) {
    return (
        cell.map(item => item.bOMProduct.name).join(", ")
    );
}


export function invoiceEstateFormatter(cell, row) {
    return (
        cell.map(item => item.serviceInstance.estate.consumernumber).join(", ")
    );
}


export function reportedAmountFormatter(cell, row) {
    if (row) {
        var reportedAmount = row.mDMMeterMeasurement && row.mDMMeterMeasurement.reportedamount ? row.mDMMeterMeasurement.reportedamount : 0;
        return reportedAmount + row.product.uOM.name;
    }
}

export function rolesFormatter(cell, row) {
    return row.map(contact => contact.cRMContactRole.name).join(', ');
}

export function prefCommChannelFormatter(cell, row) {
    return row.cRMCommunicationChannelList.map(channel => channel.preferredChannel).join(', ');
}

export function imageFormatter(cell, row) {
    return <ButtonWithModal id={row.id}/>
}

export function noteIconFormatter(cell, row) {
    return row.noteList.length > 0 ? <i className="icon ion-android-document" title={"Notes"}></i> : null
}

export function attachmentIconFormatter(cell, row) {
    return row.attachmentList.length > 0 ? <i className="icon ion-android-attach" title={"Attachments"}></i> : null
}

export function paymentCompleteFormatter(cell, row) {
    return cell
        ? <svg width="20" height="20" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd">
            <path d="M21 6.285l-11.16 12.733-6.84-6.018 1.319-1.49 5.341 4.686 9.865-11.196 1.475 1.285z"/>
        </svg>
        : null;
}

export function downloadFormatter(cell, row, rowIndex, formatExtraData) {
    return <i className={"icon ion-android-download"} style={{fontSize: "18px", color: "#0DAEFF", cursor: 'pointer'}} onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        let win = null;
        if (!(window.navigator && window.navigator.msSaveOrOpenBlob)) { // for IE
            win = window.open('', '_blank');
            win.document.write("Loading invoice...")
        };
        if(formatExtraData.version === 'v2'){
            let path;
            if(formatExtraData) {
                if(formatExtraData.expression) {
                    const expression = formatExtraData && formatExtraData.expression ? jsonata(formatExtraData.expression) : null;
                    path = expression ? expression.evaluate(row) : 'invoice';
                }else {
                    path = formatExtraData;
                }
            }else {
                path = 'invoice';
            }

            axios.post(`stitch/print/v2/${path}/print`, {"DOCUMENT_ID":`${row.id}`},{
                headers: {'Authorization': "Bearer " + localStorage.token},
                responseType: 'blob'
            }).then((response) => {
                if (window.navigator && window.navigator.msSaveOrOpenBlob) { // for IE
                    window.navigator.msSaveOrOpenBlob(response.data, row.id + '.pdf');
                } else {
                    win.location.href = URL.createObjectURL(response.data);
                    win.focus();
                }
            })
        }else {
            axios.get(`${(window.config.consul.CORE_URL || window.CORE_URL || (typeof CORE_URL !== 'undefined' ? CORE_URL : ''))}/${formatExtraData ? formatExtraData : 'invoice'}/${row.id}`, {
                headers: {'Authorization': "Bearer " + localStorage.token},
                responseType: 'blob'
            }).then((response) => {
                if (window.navigator && window.navigator.msSaveOrOpenBlob) { // for IE
                    window.navigator.msSaveOrOpenBlob(response.data, row.id + '.pdf');
                } else {
                    win.location.href = URL.createObjectURL(response.data);
                    win.focus();
                }
            })
        }
    }}>
    </i>
}

export function cdnDownloadFormatter(cell, row, rowIndex, formatExtraData) {
    return <i className={"icon ion-android-download"} style={{fontSize: "18px", color: "#0DAEFF", cursor: "pointer"}}
              onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();

                  if (typeof REST_URL === 'undefined') {
                      var REST_URL = "";
                  }
                  axios.get(`${REST_URL}/cdn/cdn/resource/${row.urn}`, {
                      headers: {'Authorization': "Bearer " + localStorage.token},
                      responseType: 'blob'
                  }).then((response) => {
                      const expression = formatExtraData && formatExtraData.expression ? jsonata(formatExtraData.expression) : null;
                      const fileName = expression ? expression.evaluate(row) : row.fileName;
                      fileDownload(response.data, fileName);
                      //fileDownload(response.data, row.id +'.xlsx');
                  })
              }}>
    </i>
}

export function jsonValueFormatter(cell, row, rowIndex, formatExtraData) {
    return formatExtraData.paths.map(path => {
        let pathArray = path.split('.');
        let value = JSON.parse(cell);
        while (pathArray.length > 0) {
            value = value[pathArray[0]];
            pathArray = pathArray.slice(1);
        }
        return value;
    }).reduce(eval(formatExtraData.reducer), null);
}

export function billingDocumentTypeFormatter(cell, row) {
    return cell ? (cell.transactionDocument.isReversal ? 'Invoice' : 'Credit Memo') : '';
}

export function checkBoxFormatter(cell, row) {
    return cell ?<svg width="20" height="20" xmlns="http://www.w3.org/2000/svg" fillRule="evenodd" clipRule="evenodd"><path d="M21 6.285l-11.16 12.733-6.84-6.018 1.319-1.49 5.341 4.686 9.865-11.196 1.475 1.285z"/></svg>: null
}

export function disableFormatter(cell, row) {
    if(row.status === "Active") {
        return <Button className={"btn btn-primary pull-right"} color="primary"
                       onClick={(e) => {
                           e.preventDefault();
                           e.stopPropagation();

                           axios.put(`${(window.config.consul.TARIFFS_URL || window.TARIFFS_URL || (typeof TARIFFS_URL !== 'undefined' ? TARIFFS_URL : '' ))}/tariff/${row.id}/disable`, {
                               headers: {'Authorization': "Bearer " + localStorage.token},
                               responseType: 'blob'
                           }).then((response) => {
                               // Refresh the page
                               window.location.reload();
                           })
                       }}> Disable </Button>
    }
    return null;
}

export function menuFotmatter(cell, row, rowIndex, formatExtraData) {
    return (<GridDropDownMenu formatExtraData={formatExtraData} row={row}/>)
}

export const commissionDue = (cell, row, rowIndex, formatExtraData) => {
    // This code is used by sales-portal-dyce. If we change it, we must also fix it in ui_layouts => name = 'commission-report'
    const filterDate = moment.utc(document.querySelector('.commission-report-date #issueDateEnd').value, 'DD-MM-YYYY');
    let lastPaymentDate = row.lastPaymentDate ? moment.utc(row.lastPaymentDate, 'YYYY-MM-DD') : null;

    if (row.docType !== 'order' && lastPaymentDate && (lastPaymentDate.month() === filterDate.month() && lastPaymentDate.year() === filterDate.year())) {
        return 'Y';
    } else {
        return 'N'
    }
}

export function jsonataFormatter(cell, row, rowIndex, formatExtraData) {
    const expression = jsonata(formatExtraData);
    const transformedData = expression ? expression.evaluate(row) : row;
    return (JSON.stringify(transformedData || "")).replace(/,/g, ', ').replace(/[\[\]\"]/g, '');
}

export function hiddenColFormatter(cell, row, rowIndex, formatExtraData) {
    return (<div style={{display: 'none', padding: 0}}>{cell}</div>)
}

export function iconFormatter(cell, row, rowIndex, formatExtraData) {
    const image = formatExtraData[0];
    const type = formatExtraData[1].type;

    let result = '';
    if(formatExtraData[1].hideWhen && eval(formatExtraData[1].hideWhen)){
        return <div></div>;
    }
    if (image.startsWith("<svg")) {
        if (type === 'link') {
            const compile = _.template(formatExtraData[1].url);
            result = <Link to={compile(row)}>
                <div style={{height: '20px', width: '20px'}}>
                    <img src={`data:image/svg+xml;utf8,${image}`}/>
                </div>
            </Link>;
        }
        if (type === 'query') {
            const compile = _.template(JSON.stringify(formatExtraData[1].variables));
            let variables = compile(row);
            const updateKeys = formatExtraData[1].updateKeys;
            const refetchQueries = [];
            for(let i = 0; i < updateKeys.length; i++){
                const refetchQuery = updateKeys[i] && sessionStorage[updateKeys[i]] && JSON.parse(sessionStorage[updateKeys[i]]);
                if (refetchQuery) {
                    refetchQuery.query = refetchQuery.query && gql`${refetchQuery.query}`;
                    refetchQueries.push(refetchQuery);
                }
            }
            result = <Mutation mutation={gql`${formatExtraData[1].mutation}`} variables={JSON.parse(variables)} refetchQueries={refetchQueries}>
                {mutation => (
                    <button onClick={mutation} style={{"background-color": "transparent" , border: "none", outline: "none"}}>
                        <div style={{height: '20px', width: '20px'}}>
                            <img src={`data:image/svg+xml;utf8,${image}`}/>
                        </div>
                    </button>
                )}
            </Mutation>
        }
    } else if (image.endsWith(".svg")) {
        result = <Link to={compile(row)}><img style={{height: '20px', width: '20px'}} src={"/img/categories/" + image}
                                              alt="icon"/></Link>;
    } else {
        result = <Link to={compile(row)}><img src={"/img/categories/" + image} alt="text describing the image"/></Link>;
    }
    return result;
}

export function downloadLinkFormatter(cell, row, rowIndex, formatExtraData) {
    const link = cell;
    return <a href={link} target="_blank" download>
        <i className={"icon ion-android-download"} style={{fontSize: "18px", color: "#0DAEFF"}}></i>
    </a>
}
