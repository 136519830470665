import React, {Component, Fragment} from 'react';
import {Card, CardBody, CardHeader, Col, Label, Row} from 'reactstrap';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import 'react-accessible-accordion/dist/fancy-example.css';
import {Accordion, AccordionItem, AccordionItemBody, AccordionItemTitle} from "react-accessible-accordion";
import TariffOverview from "../TariffOverview/TariffOverview";
import {withRouter} from "react-router-dom";
import AddressOverview from "../AddressOverview/AddressOverview";
import i18n from "../../views/Pages/Login/i18n";


class QuoteOverview extends Component {
    render() {
        const quote = this.props.quote;
        const isHHMeter = 
            quote.hasOwnProperty("agreedCapacity") && 
            quote.hasOwnProperty("hasSignedAgentContract");

        return (
            <Row>
                <Col className='col-12'>
                    {!(window.config.consul.DYCE || window.DYCE || (typeof DYCE !== 'undefined' ? DYCE : '' )) ?
                        <Accordion className="overview" accordion={false}>
                            <AccordionItem>
                                <AccordionItemTitle>
                                    {i18n.t('quote.companyDetails')}
                                    <i className="icon ion-chevron-down"></i>
                                </AccordionItemTitle>
                                <AccordionItemBody>
                                    <Row>
                                        <Col className="col-sm-6">
                                            <label>Company Name</label>
                                            <p>{quote.clientName}</p>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="col-sm-6">
                                            <label>Reference Number</label>
                                            <p>{quote.companyNumber}</p>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="col-sm-6">
                                            <label>The client agreed to provide their data</label>
                                            <p>{quote.dataAgreement ? 'Yes' : 'No'}</p>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="col-sm-6">
                                            <label>Quote Type</label>
                                            <p style={{textTransform: "capitalize"}}>{quote.quotetype}</p>
                                        </Col>
                                    </Row>
                                    {(window.config.consul.DYCE || window.DYCE || (typeof DYCE !== 'undefined' ? DYCE : '' )) && <Row>
                                        <Col className="col-sm-6">
                                            <label>Supply address</label>
                                            <AddressOverview address={quote.supplyAddress}/>
                                        </Col>
                                    </Row>
                                    }
                                </AccordionItemBody>
                            </AccordionItem>
                            {!(window.config.consul.DYCE || window.DYCE || (typeof DYCE !== 'undefined' ? DYCE : '' )) &&
                            <AccordionItem>
                                <AccordionItemTitle>
                                    Supply Address
                                    <i className="icon ion-chevron-down"></i>
                                </AccordionItemTitle>
                                <AccordionItemBody>
                                    <Row>
                                        <Col className="col-sm-6">
                                            <label>Supply address</label>
                                            <AddressOverview address={quote.supplyAddress}/>
                                        </Col>
                                    </Row>
                                </AccordionItemBody>
                            </AccordionItem>
                            }
                            <AccordionItem>
                                <AccordionItemTitle>
                                    Selected Services
                                    <i className="icon ion-chevron-down"></i>
                                </AccordionItemTitle>
                                <AccordionItemBody>
                                    <Row>
                                        <Col className="col-sm-6">
                                            <label>Selected Services:</label>
                                            <p style={{textTransform: "capitalize"}}>{quote.services}</p>
                                        </Col>
                                    </Row>
                                    {quote.services.includes('gas') && <Row>
                                        <Col className="col-sm-6">
                                            <label>MPRN:</label>
                                            <p>{quote.mprn}</p>
                                        </Col>
                                    </Row>
                                    }
                                    {quote.services.includes('gas') && <Row>
                                        <Col className="col-sm-6">
                                            <label>Annual Usage in kWh (AQ) for Gas:</label>
                                            <p>{quote.annualgas}</p>
                                        </Col>
                                    </Row>
                                    }
                                    {quote.services.includes('electricity') && <Row>
                                        <Col className="col-sm-6">
                                            <label>MPAN:</label>
                                            <p>{quote.mpan}</p>
                                        </Col>
                                    </Row>
                                    }
                                    {quote.services.includes('electricity') && <Row>
                                        <Col className="col-sm-6">
                                            <label>Annual Usage in kWh (EAC) for Electricity:</label>
                                            <p>{quote.annualelectricity}</p>
                                        </Col>
                                    </Row>
                                    }
                                    <Row>
                                        <Col className="col-sm-6">
                                            <label>Contract duration:</label>
                                            <p>{quote.contractDuration}</p>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="col-sm-6">
                                            <label>Sale type:</label>
                                            <p>{quote.saleType}</p>
                                        </Col>
                                    </Row>
                                </AccordionItemBody>
                            </AccordionItem>
                            <AccordionItem>
                                <AccordionItemTitle>
                                    Tariff details
                                    <i className="icon ion-chevron-down"></i>
                                </AccordionItemTitle>
                                <AccordionItemBody>
                                    {quote.services.includes('gas') && !(window.config.consul.DYCE || window.DYCE || (typeof DYCE !== 'undefined' ? DYCE : '' )) && <Row>
                                        <Col className="col-sm-6">
                                            <label>Green Gas:</label>
                                            <p>{quote.greengas ? 'Yes' : 'No'}</p>
                                        </Col>
                                    </Row>
                                    }
                                    {quote.services.includes('gas') && !(window.config.consul.DYCE || window.DYCE || (typeof DYCE !== 'undefined' ? DYCE : '' )) && <Row>
                                        <Col className="col-sm-6">
                                            <label>AMR:</label>
                                            <p>{quote.amr ? 'Yes' : 'No'}</p>
                                        </Col>
                                    </Row>
                                    }
                                    <Row>
                                        <Col className="col-sm-12">
                                            <TariffOverview quote={quote}/>
                                        </Col>
                                    </Row>
                                </AccordionItemBody>
                            </AccordionItem>
                        </Accordion>
                        :
                        <div>
                            <Card className={'overview'}>
                            <CardHeader>
                                {i18n.t('quote.companyDetails')}
                            </CardHeader>
                            <CardBody>
                                <Row>
                                    <Col className="col-sm-6">
                                        <label>Company Name</label>
                                        <p>{quote.clientName}</p>
                                    </Col>
                                    <Col className="col-sm-6">
                                        <label>Reference Number</label>
                                        <p>{quote.companyNumber}</p>
                                    </Col>
                                </Row>
                                <Row>
                                    {
                                        /*disable Contact Details section display for quotes older than 29.06.2023*/
                                        quote.primaryContact && (
                                            <Col sm={6}>
                                                <label>Contact Details</label>
                                                <Row>
                                                    <Col className="col-sm-3">
                                                        <p>First name</p>
                                                    </Col>
                                                    <Col>
                                                        {quote.primaryContact.firstName
                                                            ? quote.primaryContact.firstName
                                                            : ""}
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col className="col-sm-3">
                                                        <p>Last name</p>
                                                    </Col>
                                                    <Col>
                                                        {quote.primaryContact.lastName ? quote.primaryContact.lastName : ""}
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col className="col-sm-3">
                                                        <p>Email</p>
                                                    </Col>
                                                    <Col>
                                                        {quote.primaryContact.email ? quote.primaryContact.email : ""}
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col className="col-sm-3">
                                                        <p>Phone number</p>
                                                    </Col>
                                                    <Col>
                                                        {quote.primaryContact.phone ? quote.primaryContact.phone : ""}
                                                    </Col>
                                                </Row>
                                            </Col>
                                        )
                                    }
                                    <Col sm={6}>
                                        <label>Quote Source</label>
                                        <Row>
                                            <Col className="col-sm-3">
                                                <p>
                                                    {quote.utmParams && quote.utmParams.utmSource
                                                        ? quote.utmParams.utmSource
                                                        : ""}
                                                </p>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="col-sm-6">
                                        <label>Quote Type</label>
                                        <p style={{textTransform: "capitalize"}}>{quote.quotetype}</p>
                                    </Col>
                                    <Col className="col-sm-6">
                                        <label>The client agreed to provide their data</label>
                                        <p>{quote.dataAgreement ? 'Yes' : 'No'}</p>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="col-sm-6">
                                        <label>Supply address</label>
                                        <AddressOverview address={quote.supplyAddress}/>
                                    </Col>
                                </Row>
                                    <Row>
                                        <Col className="col-sm-6">
                                            <label>Selected Services:</label>
                                            <p style={{textTransform: "capitalize"}}>{quote.services}</p>
                                        </Col>
                                    </Row>
                                    {quote.services.includes('gas') && <div><Row>
                                        <Col className="col-sm-6">
                                            <label>MPRN:</label>
                                            <p>{quote.mprn}</p>
                                        </Col>
                                        <Col className="col-sm-6">
                                            <label>Annual Usage in kWh (AQ) for Gas:</label>
                                            <p>{quote.annualgas}</p>
                                        </Col>
                                    </Row>
                                    </div>
                                    }
                                    {quote.services.includes("electricity") && (
                                            <Fragment>
                                                <Row>
                                                    <Col className="col-sm-6">
                                                        <label>MPAN:</label>
                                                        <p>{quote.mpan}</p>
                                                    </Col>
                                                    <Col className="col-sm-6">
                                                        <label>Annual Usage in kWh (EAC) for Electricity:</label>
                                                        <p>{quote.annualelectricity}</p>
                                                    </Col>
                                                </Row>
                                                {isHHMeter && (
                                                    <Row>
                                                        <Col className="col-sm-6">
                                                            <label>
                                                                Does the client have a signed contract with a Meter Operator or
                                                                Data Aggregator/Data Collector?
                                                            </label>
                                                            <p>{quote.hasSignedAgentContract ? "Yes" : "No"}</p>
                                                        </Col>
                                                        <Col className="col-sm-6">
                                                            <label>kVa Capacity:</label>
                                                            <p>{quote.agreedCapacity || "N/A"}</p>
                                                        </Col>
                                                    </Row>
                                                )}
                                            </Fragment>
                                        )
                                    }
                                    {quote.services.includes('gas') && !(window.config.consul.DYCE || window.DYCE || (typeof DYCE !== 'undefined' ? DYCE : '' )) &&
                                    <Fragment>
                                        <Row>
                                            <Col className="col-sm-6">
                                                <label>Green Gas:</label>
                                                <p>{quote.greengas ? 'Yes' : 'No'}</p>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col className="col-sm-6">
                                                <label>AMR:</label>
                                                <p>{quote.amr ? 'Yes' : 'No'}</p>
                                            </Col>
                                        </Row>
                                    </Fragment>
                                    }

                                    <Row>
                                        <Col className="col-sm-6">
                                            <label>Sale type:</label>
                                            <p>{quote.saleType}</p>
                                        </Col>
                                    </Row>
                                <Row>
                                    <Col className="col-sm-6">
                                        <label>Current Supplier Contract End Date:</label>
                                        <p>{quote.endDate ? new Date(quote.endDate).toLocaleDateString() : null}</p>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="col-sm-6">
                                        <label>Proposed Supply Start Date:</label>
                                        <p>{quote.startDate ? new Date(quote.startDate).toLocaleDateString() : null}</p>
                                    </Col>
                                </Row>
                                    <Row>
                                        <Col className="col-sm-12">
                                            <TariffOverview quote={quote}/>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </div>
                    }
                </Col>
            </Row>
        )
    }
}

export default withRouter(QuoteOverview);