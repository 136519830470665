import React, {Component} from 'react';
import {withRouter} from 'react-router';
import _ from "underscore";
import Mutation from "react-apollo/Mutation";
import {signContract} from "../../queries/Queries";
import ReactDOM from "react-dom";
import ExpiringAlert from "../ExpiringAlert";
import i18n from "../../views/Pages/Login/i18n";



class SignContractButton extends Component {

    constructor(props) {
        super(props);
    }

    handleSubmit(signContract, contractId) {

        const signContractMutation = () => {
            console.log("signContractMutation called");
            signContract({
                variables: {
                    contractId: contractId
                }
            }).then(({data}) => {
                console.log('got data', data);
                ReactDOM.render(<ExpiringAlert color="success" message={"Successfully signed contract!"} />, document.getElementById('alert'));
            }).catch((error) => {
                ReactDOM.render(<ExpiringAlert color="danger"
                                               message={error.graphQLErrors[0].message}/>,
                        document.getElementById('alert').appendChild(document.createElement("div")));
                console.log('there was an error sending the query', error);
            });
        }

        signContractMutation();
    }

    render() {
        let info;

        if (this.props.match.params["info"]) {
            info = JSON.parse(this.props.match.params["info"][this.props.queryKey]);
        } else {
            info = this.props.info;
            if (info) {
                const compiled = _.template(JSON.stringify(info));
                info = JSON.parse(compiled(this.props.match.params));
            }
        }

        const contractId = this.props.match.params.contractId;
        const contractStatus = info[0].value;
        // Render the button only if the agreement(contract) status is "Draft Contract"
        var isDraftStatus;
        if(contractStatus === "Draft Contract") {
            isDraftStatus = true;
        } else {
            isDraftStatus = false;
        }


        return (
                <Mutation mutation={signContract}>
                    {(signContractMutation, data) =>
                            (isDraftStatus && <div className={"btn btn-primary"} style={{cursor: "pointer"}} onClick={() => {
                                console.log("Contract clicked " + contractId);
                                // Call the graphql mutation signContract (in Openbravo)
                                this.handleSubmit(signContractMutation, contractId);
                                //
                            }
                            }>
                                <i className="icon ion-android-checkbox-outline"></i> &nbsp;&nbsp;Sign Contract
                            </div>)
                    }
                </Mutation>
        )
    }

}

export default withRouter(SignContractButton)