import React from 'react';
import {Step, Steps, Wizard} from 'react-albus';
import FormA from './FormA';
import StepA from './StepA';
import Navigation from './Navigation';
import JsxParser from "react-jsx-parser";
import {uiConfigurationQuery} from "../../queries/Queries";
import {Query} from "react-apollo";
import Loading from "../../components/Loading";
import './sf.scss';
import './sf-metro.scss';
import JsonEditorA from "./JsonEditorA";
import 'jsoneditor-react/es/editor.min.css';
import tokiAddressValidation from './tokiAddressValidation';
import JsonSchemaFormA from './JsonSchemaFormA';

class SwitchingForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {context: {}};
        this.mySetState = this.mySetState.bind(this);
    }

    mySetState(context) {
        return function (formData, next) {
            context.data = formData;
            next && next();
        }
    }

    render() {
        return (<Query query={uiConfigurationQuery}>
            {(confResult) => {
                if (confResult.loading) return <Loading/>;
                if (confResult.error) {
                    document.cookie = 'token=; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
                    confResult.client.resetStore();
                    localStorage.clear();
                    this.props.history.push('/');
                    return <h1>Cockpit Core is down. Please contact your system administrator.</h1>
                }
                let logo = <div></div>;
                if(confResult.data.getUIConfiguration.uiLayouts.find(l => l.name === 'switching-form-logo')){
                    logo = <JsxParser
                        jsx={confResult.data.getUIConfiguration.uiLayouts.find(l => l.name === 'switching-form-logo').jsx}/>
                }
                if (confResult.data.getUIConfiguration) {
                    console.log('switching form props', JSON.stringify(this.props));
                    return (
                        <div>
                            {logo}
                            <div className="container switching-form">
                                <div className="row pad-t">
                                    <div className="col-md-8 offset-md-2 switching-form-content">
                                        <JsxParser
                                            components={{Steps, Step, Navigation, Wizard, FormA, StepA, JsonEditorA, JsonSchemaFormA}}
                                            bindings={{...this.props, tokiAddressValidation}}
                                            jsx={confResult.data.getUIConfiguration.uiLayouts.find(l => l.name === (this.props.layout || this.props.match.params.name || 'switching-form')).jsx}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                }
            }}</Query>)
    }
}


export default SwitchingForm;
