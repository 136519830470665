import React, { Component } from 'react';
import { withRouter } from "react-router";
import { Query } from "react-apollo";
import BootstrapTable from 'react-bootstrap-table-next';
import i18n from '../../views/Pages/Login/i18n';
import { agreementsPriceFormatter } from "../../utils/Formatters";
import { Card, Button, CardTitle, CardHeader, CardBody, Row, Col, Form, FormGroup, Label, FormText } from 'reactstrap';
import Loading from '../Loading';
import { agreementQuery } from "../../queries/Queries"
import paginationFactory from "react-bootstrap-table2-paginator";
import AgreementOverview from "../AgreementOverview";
import moment from "moment";

class ContractsDetailLocal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            page: 1, 
            sizePerPage: 5,
        };
    }

    render() {
        return <div style={{padding:"20px"}} className="agreemen-details"><Query query={agreementQuery} variables={{id: this.props.match.params.contractid}} >
            {({loading, error, data}) => {
                if (loading) return <Loading/>;
                if (error) return `Error! ${error.message}`;
                const currencyInfo = {currency:{iSOCode:'GBP', standardPrecision: 0}, mdmCoins:'p', isMdmDisplayCoins: true};
                const currencyInfoNoDecimal = {currency:{iSOCode:'GBP', standardPrecision: 0}, mdmCoins:'p', isMdmDisplayCoins: true};

                const dataForTable =
                    data.getAgreement.products[0].name === "Contract for Gas" ||
                    data.getAgreement.products[0].name === "Gas"
                        ? [
                                {
                                    name: "Gas",
                                    unit: "kWh",
                                    standardPrice: data.getAgreement.tariffName.unitRate,
                                    currencyInfo,
                                },
                                {
                                    name: "Standing charges Gas",
                                    unit: "day",
                                    standardPrice: data.getAgreement.tariffName.sc,
                                    currencyInfoNoDecimal,
                                },
                                {
                                    name: "Gas Commission",
                                    unit: "kWh",
                                    standardPrice: data.getAgreement.products.reduce((acc, curr) =>
                                        curr.name === "Gas" ? curr : null
                                    ).commissionUnitRate,
                                    currencyInfo,
                                },
                                {
                                    name: "Standing charges Gas Commission",
                                    unit: "day",
                                    standardPrice: data.getAgreement.products.reduce((acc, curr) =>
                                        curr.name === "Gas" ? curr : null
                                    ).commissionStandingCharge,
                                    currencyInfoNoDecimal,
                                },
                        ]
                        : [
                                data.getAgreement.tariffName.hasOwnProperty("dayRate") &&
                                    data.getAgreement.tariffName.dayRate !== null && {
                                        name: "Day Rate",
                                        unit: "kWh",
                                        standardPrice: data.getAgreement.tariffName.dayRate,
                                        currencyInfo,
                                    },
                                data.getAgreement.tariffName.hasOwnProperty("nightRate") &&
                                    data.getAgreement.tariffName.nightRate !== null && {
                                        name: "Night Rate",
                                        unit: "kWh",
                                        standardPrice: data.getAgreement.tariffName.nightRate,
                                        currencyInfo,
                                    },
                                data.getAgreement.tariffName.hasOwnProperty("singleRate") &&
                                    data.getAgreement.tariffName.singleRate !== null && {
                                        name: "Single Rate",
                                        unit: "kWh",
                                        standardPrice: data.getAgreement.tariffName.singleRate,
                                        currencyInfo,
                                    },
                                data.getAgreement.tariffName.hasOwnProperty("eveningWeekendRate") &&
                                    data.getAgreement.tariffName.eveningWeekendRate !== null && {
                                        name: "Evening/Weekend Rate",
                                        unit: "kWh",
                                        standardPrice: data.getAgreement.tariffName.eveningWeekendRate,
                                        currencyInfo,
                                    },
                                data.getAgreement.tariffName.hasOwnProperty("kVaCharge") &&
                                    data.getAgreement.tariffName.kVaCharge !== null && {
                                        name: "kVa Charge",
                                        unit: "kVa/day",
                                        standardPrice: data.getAgreement.tariffName.kVaCharge,
                                        currencyInfo,
                                    },
                                data.getAgreement.tariffName.hasOwnProperty("meteringCharge") &&
                                    data.getAgreement.tariffName.meteringCharge !== null && {
                                        name: "Metering Charge",
                                        unit: "day",
                                        standardPrice: data.getAgreement.tariffName.meteringCharge,
                                        currencyInfo,
                                    },
                                data.getAgreement.tariffName.hasOwnProperty("sc") &&
                                    data.getAgreement.tariffName.sc !== null && {
                                        name: "Standing charges Electricity",
                                        unit: "day",
                                        standardPrice: data.getAgreement.tariffName.sc,
                                        currencyInfoNoDecimal,
                                    },
                                data.getAgreement.products.reduce((acc, curr) =>
                                    curr.name === "Electricity" ? curr : {}
                                ).commissionelectricityStandingCharge !== null && {
                                    name: "Standing charges Electricity Commission",
                                    unit: "day",
                                    standardPrice: data.getAgreement.products.reduce((acc, curr) =>
                                        curr.name === "Electricity" ? curr : null
                                    ).commissionelectricityStandingCharge,
                                    currencyInfoNoDecimal,
                                },
                                {
                                    name: "Electricity Commission",
                                    unit: "kWh",
                                    standardPrice: data.getAgreement.products.reduce((acc, curr) =>
                                        curr.name === "Electricity" ? curr : null
                                    ).commission,
                                    currencyInfo,
                                },
                        ].filter((obj) => obj.name !== undefined);

                return (<div className="agreement-details">
                    <Row>
                        <Col sm="12" className="header">
                            <Button onClick={this.props.history.goBack} className="btn btn-default">
                                <i className="icon ion-arrow-left-b"></i>{i18n.t('businessPartner.contract')}:&nbsp;&nbsp;{data.getAgreement.documentNumber}
                            </Button>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm="4">
                            <Card>
                                <CardHeader>
                                    <CardTitle>{i18n.t('businessPartner.agreementDetails')}</CardTitle>
                                </CardHeader>
                                <CardBody>
                                    <Row>
                                        <Col>
                                            <Form>
                                                <FormGroup>
                                                    <FormText>{i18n.t('businessPartner.agreementStatus')}</FormText>
                                                    <Label>{i18n.t(data.getAgreement.status.name)}</Label>
                                                </FormGroup>
                                                <FormGroup>
                                                    <FormText>{i18n.t('businessPartner.agreementNumber')}</FormText>
                                                    <Label>{data.getAgreement.documentNumber}</Label>
                                                    <Label></Label>
                                                </FormGroup>
                                                <FormGroup>
                                                    <FormText></FormText>
                                                    <FormText>{i18n.t('contract.tariff')}</FormText>
                                                    <Label>{data.getAgreement.tariffName.name}</Label>
                                                </FormGroup>
                                                <FormGroup>
                                                    <FormText></FormText>
                                                    <FormText>{i18n.t('businessPartner.agreementDuration')}</FormText>
                                                    <Label>{data.getAgreement.contractDuration}</Label>
                                                </FormGroup>
                                                {!(window.config.consul.DYCE || window.DYCE || (typeof DYCE !== 'undefined' ? DYCE : '' )) && <FormGroup>
                                                    <FormText>{i18n.t('businessPartner.billingAccount')}</FormText>
                                                    <Label></Label>
                                                    {/* TODO OGI - Add Payment method */}
                                                </FormGroup>
                                                }
                                            </Form>
                                        </Col>
                                        <Col>
                                            <Form>
                                                <FormGroup>
                                                    <FormText>{i18n.t('businessPartner.agreementSignDate')}</FormText>
                                                    <Label>{data.getAgreement.signDate ? new Date(data.getAgreement.signDate).toLocaleDateString(): ''}</Label>
                                                </FormGroup>
                                                <FormGroup>
                                                    <FormText>{i18n.t('businessPartner.agreementStartDate')}</FormText>
                                                    <Label>{new Date(data.getAgreement.startDate).toLocaleDateString()}</Label>
                                                </FormGroup>
                                                <FormGroup>
                                                    <FormText>{i18n.t('businessPartner.agreementEndDate')}</FormText>
                                                    <Label>{moment(data.getAgreement.startDate).add(data.getAgreement.contractDuration, 'months').add(-1, 'days').toDate().toLocaleDateString()}</Label>
                                                </FormGroup>
                                                <FormGroup>
                                                    <FormText>{i18n.t('businessPartner.paymentMethod')}</FormText>
                                                    <Label>{data.getAgreement.paymentMethod}</Label>
                                                    {/* TODO OGI - Add Payment method */}
                                                </FormGroup>
                                                {!(window.config.consul.DYCE || window.DYCE || (typeof DYCE !== 'undefined' ? DYCE : '' )) && <FormGroup>
                                                    <FormText>{i18n.t('businessPartner.billingAccountStatus')}</FormText>
                                                    <Label></Label>
                                                    {/* TODO OGI - Add Payment method */}
                                                </FormGroup>
                                                }
                                            </Form>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col sm="8">
                            <Card>
                                <CardHeader>
                                    <CardTitle>{i18n.t('businessPartner.contractProducts')}</CardTitle>
                                </CardHeader>
                                <CardBody>
                                    <div  className="invoice-table">
                                        <BootstrapTable
                                            keyField={'id'}
                                            data={
                                                dataForTable
                                            }
                                            columns={[ {
                                                dataField: 'name',
                                                text: i18n.t('product.name'),
                                                sort: true
                                            }, {
                                                dataField: 'unit',
                                                text: i18n.t('product.unit'),
                                                sort: true
                                            }, {
                                                dataField: 'standardPrice',
                                                text: i18n.t('price'),
                                                sort: true,
                                                align: "right",
                                                headerAlign: "right",
                                                formatter: agreementsPriceFormatter
                                            }]}
                                            pagination={paginationFactory({
                                                page: this.state.page,
                                                onPageChange: (page, sizePerPage) => {
                                                    this.state.page =  page;
                                                },
                                                sizePerPageList:[ {
                                                    text:"5", value: 5
                                                }, {
                                                    text:"10", value: 10
                                                }, {
                                                    text:"20", value: 20
                                                } ]
                                            })}
                                            bordered={false}
                                        />
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <AgreementOverview agreement={data.getAgreement}/>
                </div>)
            }
            }
        </Query></div>
    }
}


export default withRouter(ContractsDetailLocal);
