import React from 'react';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import {Query} from 'react-apollo';
import {Input} from "reactstrap";
import {getBusinessPartnerShortQuery} from "../queries/Queries"
import Textarea from "react-textarea-autosize";

const uischema = {
    id : {
        "ui:widget" : "hidden"
    },
    name : {
        "ui:widget": (props) => {
            return (
                <div >
                    <span className="task-status icon ion-android-checkbox primary"></span>
                           <Textarea  disabled
                           className={props.schema.classes}
                           value={props.value}
                           required={props.required}
                           placeholder={props.schema.placeholder}
                           autoFocus ={true}
                           onChange={(event) => {
                               props.onChange(event.target.value)
                           }}/>
                </div>
            );
        },
        "classNames":'task-title',
    },
    process : {
        "ui:readonly": true
    },
    assignee : {
        "ui:readonly": true
    },
    customer : {
            "ui:widget": (props) => {
                return <Query query={getBusinessPartnerShortQuery} variables={{
                    orderBy: "name",
                    where: `id = '${props.value}'`
                }}>{({ loading, error, data }) => {
                    if (loading) return "Loading...";
                    if (error) return `Error! ${error.message}`;

                    return (<Input disabled value={data.businessPartner[0] ? data.businessPartner[0].name : ""}/>)
                }}</Query>
            }
    },
    status : {
        "ui:readonly": true
    },
    priority : {
        "ui:readonly": true
    },
    dueDate : {
        "ui:readonly": true
    },
    description : {
        "ui:widget" : (props)=>{
            return <Textarea disabled value={props.value ? props.value : ""}  placeholder="Task Description"  onChange={(event) => {
                props.onChange(event.target.value)
            }}/>
        },
        "classNames":'description',
    },

    "ui:order": ["id", "name", "process", "assignee", "customer", "status", "dueDate", "priority", "description"],
    "classNames":'readonly',

}
export default uischema;