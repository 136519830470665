import React, { Component } from 'react';
import axios from "../../utils/Client";
import {t} from "i18next";


class PasswordPolicy extends Component {
  constructor(props){
    super(props);
    this.state = {policy: null};
  }
  render() {
    if(!this.state.policy) {
      axios.get(`${(window.config.consul.CORE_URL || window.CORE_URL || (typeof CORE_URL !== 'undefined' ? CORE_URL : '' ))}/password-policy`)
          .then((response) => {
            this.setState({policy : Object.entries(response.data).filter(pair => pair[1] > 0)});
          });
    }
    const min = t("profile.minimum");
    const lower = t("profile.lowercaseLetters");
    const upper = t("profile.uppercaseLetters");
    const special = t("profile.specialChars");
    const digits = t("profile.digits");
    const length = t("profile.length");

    return <span style={{fontSize: 'small', color:'gray'}}> {this.state.policy ? t("profile.passwordpolicy") + ":" + this.state.policy.map(pair => pair[0].replace(/([A-Z])/g, ' $1').replace('min', min).toLowerCase().replace('lowercase letters', lower).replace('uppercase letters', upper).replace('special chars', special).replace('digits', digits).replace('length', length)+': '+pair[1]).join(', ') : null}</span>
  }
}

export default PasswordPolicy;
