import React, {Component} from 'react';
import {Accordion, AccordionItem, AccordionItemBody, AccordionItemTitle} from "react-accessible-accordion";
import {Col, Row} from "reactstrap";
import Form from "react-jsonschema-form";
import axios from "../../utils/Client";
import fileDownload from "js-file-download";
import moment from "moment";
import {withRouter} from "react-router-dom";
import widgets from "../FormWidget";
import _ from "underscore";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import Loader from "react-loader-spinner";

class ConfigurableReport extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: props.data ? this.applyTemplateToObject(props.data, props) : {},
            loading: false
        };

        this.onSubmit = this.onSubmit.bind(this);
        this.onFormDataChange = this.onFormDataChange.bind(this);
    }

    applyTemplateToObject(obj, variables) {
        if (typeof obj === "string") {
            const compiled = _.template(obj);
            return compiled(variables);
        }
        for (var i in obj) {
            if (obj.hasOwnProperty(i)) {
                obj[i] = this.applyTemplateToObject(obj[i], variables);
            }
        }
        return obj;
    };

    onSubmit() {
        if (this.props.graphParams && this.props.view) {
            const path = this.props.graphParams.map(p => this.state.data[p]).join("/");
            this.props.history.push('view/' + this.props.view + '/' + path)
        } else {
            let reportParams = "layout=" + this.props.layout;
            let reportType = this.props.type && this.props.type == "dataflows" ? "dataflows" : "other";
            let submittedParams = this.state.data;
            for (let key in submittedParams) {
                reportParams += '&' + key + '=' + submittedParams[key];
            }
            let format = this.props.format ? this.props.format : 'xls';
            reportParams += '&format=' + format;
            if (reportType == "dataflows") {
                this.setState({loading: true});
                axios.post((window.config.consul.CORE_URL || window.CORE_URL || (typeof CORE_URL !== 'undefined' ? CORE_URL : '' )) + '/dataflows/manual/file/ui ', submittedParams).then((response) => {
                    axios.post((window.config.consul.CORE_URL || window.CORE_URL || (typeof CORE_URL !== 'undefined' ? CORE_URL : '' )) + '/dataflows/manual/file/content', response.data.formData).then((response) => {
                        this.setState({loading: false});
                        fileDownload(response.data, this.props.title + moment(new Date()).format('YYYYMMDD-HHmm') + '.' + format);
                    });
                });
            } else {
                axios.get(`${(window.config.consul.CORE_URL || window.CORE_URL || (typeof CORE_URL !== 'undefined' ? CORE_URL : '' ))}/report/${this.props.title}?${reportParams}`, {
                    headers: {'Authorization': "Bearer " + localStorage.token},
                    responseType: 'blob'
                }).then((response) => {
                    fileDownload(response.data, this.props.title + moment(new Date()).format('YYYYMMDD-HHmm') + '.' + format);
                })
            }
        }
    }

    onFormDataChange({formData}) {
        this.state.data = formData;
    }

    render() {
        const form = <Row>
            <Col className="col-sm-6">
                <Form schema={this.props.schema}
                      widgets={widgets.widgets}
                      uiSchema={this.props.uiSchema || {}}
                      formData={this.state.data}
                      onChange={this.onFormDataChange}
                      noHtml5Validate={true}
                      showErrorList={false}
                      onSubmit={this.onSubmit}
                >
                    <div className="buttons">
                        <button type="submit"
                                className="btn btn-primary">{this.props.actionText || "Download"}
                        </button>
                    </div>
                </Form>
            </Col>
        </Row>;
        return this.props.naked ? form : (
            <div className="report-item">
                <Accordion>
                    <AccordionItem>
                        <AccordionItemTitle>
                            {this.props.title}
                            <i className="icon ion-chevron-down"/>
                        </AccordionItemTitle>
                        <AccordionItemBody>
                            {form}
                            {this.state.loading ?
                                <Loader type="ThreeDots" color="#00BFFF" height={80} width={80}/> : <div></div>}
                        </AccordionItemBody>
                    </AccordionItem>
                </Accordion>
            </div>
        );
    }
}

export default withRouter(ConfigurableReport);