import React, {Component} from 'react';
import GenericView from "../../components/GenericView";

class ServiceRequests extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="service-requests">
                <div id='serviceRequestsTable'>
                    <GenericView
                        layout="service-requests"
                        configuration={this.props.configuration}
                    />
                </div>
                <div id='processedServiceRequestsTable'>
                    <GenericView
                        layout="processed-service-requests"
                        configuration={this.props.configuration}
                    />
                </div>
            </div>

        )
    }
}

export default ServiceRequests;