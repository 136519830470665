import JSXEditor from "./JSXEditor";
import DatePicker from "./DatePicker";
import MonthPicker from "./MonthPicker";
import VendorPicker from "./VendorPicker";

const widgets = {
    jsxEditor: JSXEditor,
    datePicker: DatePicker,
    monthPicker: MonthPicker,
    vendorPicker: VendorPicker
};

const fields = {};

export default {widgets, fields};
