import React, { Component } from 'react';
import i18n from '../Pages/Login/i18n';
import { Form, FormGroup, Row, Col, Label, Input } from 'reactstrap';
import moment from "moment";
import 'react-dates/initialize';
import { SingleDatePicker } from 'react-dates';
import {Mutation, Query} from "react-apollo";
import {createOpenbravoSalesOrganization, organizationsQuery, salesOrganizationsQuery} from "../../queries/Queries";
import axios from "../../utils/Client";
import Select from "react-select";


class CreateOrganisation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            focusedsignDate: false,
            additionalinformation: false,
            organization: {
                avatarUrl: '',
                address: {}
            }
        };
        this.onChangeAvatar = this.onChangeAvatar.bind(this);
        this.onChangeFile = this.onChangeFile.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.onChange = this.onChange.bind(this);
    }
    onChangeFile(url, name, data) {
        console.log(url, name, data);
        var current = this.state.organization;
        var reader = new FileReader();
        var _this = this;
        reader.readAsDataURL(data);
        reader.onload = function () {
            current[name] = reader.result;
            current[name + "Filename"] = data.name;
            _this.setState({
                organization: current
            })
        };
        reader.onerror = function (error) {
            console.log('Error: ', error);
        };

    }
    onChangeAvatar(url, name, data) {
        var current = this.state.organization;
        current[name] = data;
        current.avatarUrl = url;
        this.setState({
            organization: current
        })
    }
    handleSubmit() {

    }
    onChange(name, data) {
        var current = this.state.organization;
        current[name] = data;
        this.setState({
            organization: current
        })
    }

    onChangeAddress(name, data) {
        let current = this.state.organization.address;
        current[name] = data;
        this.setState({
            organization: {
                ...this.state.organization,
                address: current
            }
        })
    }

    render() {
        let {avatarUrl, ...organization} = this.state.organization;
        organization.active = true;
        organization = {...organization, parent: {organizationId: organization.parent}};
        const categoryOptions = this.state.organization.parent ?
            (<option value="Sub Broker">Sub Broker</option>) :
                [(<option value="" disabled selected={true}>Select a category</option>),
                    (<option value="Broker">Broker</option>),
                    (<option value="Broker Lead">Broker Lead</option>)];
        if (this.state.organization.parent) {
            organization.category = "Sub Broker";
        }
        return (
            <div className="organization">
                <Row className='subheader'>
                    <Col className='col-8'>
                        <h1><i className="icon-atom ion-organization-default" ></i>{i18n.t('organizations.createOrganization')}</h1>
                    </Col>
                </Row>

                <div className="page-cnt create-user ">
                    <Row>
                        <Col xs="12" sm="12" md="10" lg="8" xl="6">
                            <Form name={"createOrganization"}>
                                <FormGroup>
                                    <Row>
                                        <Col xs="4">
                                            <Label className='user-label'>
                                                {i18n.t('organizations.salesOrganization')}
                                            </Label>
                                        </Col>
                                        <Col xs="8">
                                            <Input type="text" value={this.state.organization.name}
                                                placeholder={i18n.t('user.namePlaceholder')}
                                                   name={"name"}
                                                onChange={(e) => this.onChange("name", e.target.value)} maxlength={(window.config.consul.DYCE || window.DYCE || (typeof DYCE !== 'undefined' ? DYCE : '' )) ? "50" : ""} required/>
                                        </Col>
                                    </Row>
                                </FormGroup>
                                <FormGroup>
                                    <Row>
                                        <Col xs="4">
                                            <Label className='user-label'>
                                                {i18n.t('organizations.number')}
                                            </Label>
                                        </Col>
                                        <Col xs="8">
                                            <Input type="text" value={this.state.organization.organizationNumber}
                                                // required = {(window.config.consul.BILLING_FOR_BROKERS || window.BILLING_FOR_BROKERS || (typeof BILLING_FOR_BROKERS !== 'undefined' && BILLING_FOR_BROKERS))}
                                                placeholder={i18n.t('user.numberPlaceholder')}
                                                onChange={(e) => this.onChange("organizationNumber", e.target.value)} />
                                        </Col>
                                    </Row>
                                </FormGroup>
                                <FormGroup>
                                    <Row>
                                        <Col xs="4">
                                            <Label className='user-label'>
                                                {i18n.t('organizations.category')}
                                            </Label>
                                        </Col>
                                        <Col xs="8">
                                            <Input type="select" value={this.state.organization.category} placeholder="" defaultValue=""
                                                   name={"category"}
                                                onChange={(e) => this.onChange("category", e.target.value)} required>{' '}
                                                {categoryOptions}
                                            </Input>
                                        </Col>
                                    </Row>
                                </FormGroup>
                                <FormGroup>
                                    <Row>
                                        <Col xs="4">
                                            <Label className='user-label'>
                                                {i18n.t('organizations.parent')}
                                            </Label>
                                        </Col>
                                        <Col xs="8">
                                            <Query query={organizationsQuery}>
                                                {(result) =>
                                                    <Select value={this.state.organization.parent}
                                                            isLoading={result.loading}
                                                            options={result.loading
                                                                ? [{value: null, label: "Loading...", disabled: true}]
                                                                : result.data.getOrganizations
                                                                    .map((o) => ({value: o.organizationId, label: o.name}))}
                                                            onChange={(e) => {
                                                                this.onChange("parent", e == null ? null : e.value)
                                                            }
                                                            }/>
                                                }
                                            </Query>
                                        </Col>
                                    </Row>
                                </FormGroup>
                                    <FormGroup>
                                        <Row>
                                            <Col xs="4">
                                                <Label className='user-label'>
                                                    Partner Reference Number
                                                </Label>
                                            </Col>
                                            <Col xs="8">
                                                <Input type="text" value={this.state.organization.partnerReferenceNumber}
                                                       placeholder={i18n.t('user.refNumber')}
                                                       required
                                                       onChange={(e) => this.onChange("partnerReferenceNumber", e.target.value)}/>
                                            </Col>
                                        </Row>
                                    </FormGroup>
                                    <FormGroup>
                                        <Row>
                                            <Col xs="4">
                                                <Label className='user-label'>
                                                    Address
                                                </Label>
                                            </Col>
                                            <Col sm="4" className="col-8" style={{marginBottom: "10px"}}>
                                                <Input type="text" value={this.state.organization.address.country}
                                                       placeholder={i18n.t('address.country')}
                                                       onChange={(e) => this.onChangeAddress("country", e.target.value)} />
                                            </Col>
                                            <Col sm="4" className="col-8" style={{marginBottom: "10px"}}>
                                                <Input type="text" value={this.state.organization.address.zipCode}
                                                       placeholder={i18n.t('address.zipCode')}
                                                       required
                                                       onChange={(e) => this.onChangeAddress("zipCode", e.target.value)} />
                                            </Col>
                                            <Col xs="4">
                                            </Col>
                                            <Col sm="4" className="col-8" style={{marginBottom: "10px"}}>
                                                <Input type="text" value={this.state.organization.address.town}
                                                       placeholder={i18n.t('address.town')}
                                                       required
                                                       onChange={(e) => this.onChangeAddress("town", e.target.value)} />
                                            </Col>
                                            <Col sm="4" className="col-8" style={{marginBottom: "10px"}}>
                                                <Input type="text" value={this.state.organization.address.locality}
                                                       placeholder={i18n.t('address.locality')}
                                                       onChange={(e) => this.onChangeAddress("locality", e.target.value)} />
                                            </Col>
                                            <Col xs="4">
                                            </Col>
                                            <Col sm="4" className="col-8" style={{marginBottom: "10px"}}>
                                                <Input type="text" value={this.state.organization.address.block}
                                                       placeholder={i18n.t('address.block')}
                                                       onChange={(e) => this.onChangeAddress("block", e.target.value)} />
                                            </Col>
                                            <Col sm="4" className="col-8" style={{marginBottom: "10px"}}>
                                                <Input type="text" value={this.state.organization.address.entrance}
                                                       placeholder={i18n.t('address.entrance')}
                                                       onChange={(e) => this.onChangeAddress("entrance", e.target.value)} />
                                            </Col>
                                            <Col xs="4">
                                            </Col>
                                            <Col sm="4" className="col-8" style={{marginBottom: "10px"}}>
                                                <Input type="text" value={this.state.organization.address.floor}
                                                       placeholder={i18n.t('address.floor')}
                                                       onChange={(e) => this.onChangeAddress("floor", e.target.value)} />
                                            </Col>
                                            <Col sm="4" className="col-8" style={{marginBottom: "10px"}}>
                                                <Input type="text" value={this.state.organization.address.apartment}
                                                       placeholder={i18n.t('address.apartment')}
                                                       onChange={(e) => this.onChangeAddress("apartment", e.target.value)} />
                                            </Col>
                                            <Col xs="4">
                                            </Col>
                                            <Col sm="4" className="col-8" style={{marginBottom: "10px"}}>
                                                <Input type="text" value={this.state.organization.address.streetNumber}
                                                       placeholder={i18n.t('address.streetNumber')}
                                                       onChange={(e) => this.onChangeAddress("streetNumber", e.target.value)} />
                                            </Col>
                                            <Col sm="4" className="col-8" style={{marginBottom: "10px"}}>
                                                <Input type="text" value={this.state.organization.address.street}
                                                       placeholder={i18n.t('address.street')}
                                                       required
                                                       onChange={(e) => this.onChangeAddress("street", e.target.value)} />
                                            </Col>
                                        </Row>
                                    </FormGroup>

                                <FormGroup>
                                    <Row >
                                        <Col xs="4">
                                            <Label className='user-label'>
                                                {i18n.t('organizations.logo')}
                                            </Label>
                                        </Col>
                                        <Col xs={"8"}>
                                            <img src={this.state.organization.avatarUrl? this.state.organization.avatarUrl : `${(window.config.consul.CORE_URL || window.CORE_URL || (typeof CORE_URL !== 'undefined' ? CORE_URL : '' ))}/images/placeholder`} width={"40"} height={"40"} className="avatar" />
                                            <Input
                                                type='file' label='Upload'
                                                onChange={(e) => {
                                                    this.onChangeAvatar(URL.createObjectURL(e.target.files[0]), "avatar", e.target.files[0]);
                                                }}
                                            />
                                        </Col>
                                    </Row>
                                </FormGroup>
                                <FormGroup>
                                    <Row>
                                        <Col xs="4">

                                        </Col>
                                        <Col xs="8">
                                        <p>
                                            <Input type="checkbox" name="mail" checked={this.state.additionalinformation} onChange={(e)=>{this.setState({additionalinformation: !this.state.additionalinformation})}} id="mail" />
                                            <label for="mail" className="css-label"><i className="icon ion-android-checkbox"/>{i18n.t('organizations.createOrganizationAddContractInfo')}</label>
                                        </p>
                                        </Col>
                                    </Row>
                                </FormGroup>
                                {this.state.additionalinformation &&
                                <div>
                                    <FormGroup>
                                        <Row>
                                            <Col xs="4">
                                                <Label className='user-label'>
                                                    Date Signed
                                                </Label>
                                            </Col>
                                            <Col xs="8">
                                                <SingleDatePicker date={this.state.organization.signDate ? moment(this.state.organization.signDate) : null} // momentPropTypes.momentObj or null
                                                                  numberOfMonths={1}
                                                                  openDirection="up"
                                                                  onDateChange={date => {
                                                                      this.onChange('signDate', date.format("YYYY-MM-DDT00:00:00.000[Z]"));
                                                                  }}
                                                                  focused={this.state.focusedsignDate} // PropTypes.bool
                                                                  onFocusChange={({ focused }) => this.setState({ focusedsignDate: focused })} // PropTypes.func.isRequired
                                                                  id="focusedsignDate-end-date" // PropTypes.string.isRequired,
                                                                  showDefaultInputIcon={true}
                                                                  inputIconPosition="after"

                                                                  displayFormat="DD/MM/YY"
                                                                  isOutsideRange={() => false}

                                                />
                                            </Col>
                                        </Row>
                                    </FormGroup>
                                    <FormGroup>
                                        <Row>
                                            <Col xs="4">
                                                <Label className='user-label'>
                                                    Attach File
                                                </Label>
                                            </Col>
                                            <Col xs="8">
                                                <Input type="file"
                                                       onChange={(e) => {
                                                           this.onChangeFile(URL.createObjectURL(e.target.files[0]), "attachment", e.target.files[0]);
                                                       }} />
                                            </Col>
                                        </Row>
                                    </FormGroup>
                                </div> }
                            </Form>
                        </Col>
                    </Row>
                    <Row className="btn-cnt">
                        <Col xs="12" sm="12" className="spacer"></Col>
                        <Col xs="4" sm="4" md="2" lg="2" xl="2"></Col>
                        <Col xs="8" sm="8">
                            <Mutation mutation={createOpenbravoSalesOrganization} refetchQueries={[{query:salesOrganizationsQuery},{query:organizationsQuery}]}>
                                {(createSalesOrganization, {data}) => (
                                    <Input className='btn btn-primary' type='submit' value={i18n.t('organizations.addOrganization')} onClick={()=> {
                                        if (document.createOrganization.reportValidity()) {
                                            if(!(window.config.consul.DYCE || window.DYCE || (typeof DYCE !== 'undefined' ? DYCE : '' )) || (window.config.consul.OSSO || window.OSSO || (typeof OSSO !== 'undefined' ? OSSO : '' )) || !(window.config.consul.BILLING_FOR_BROKERS || window.BILLING_FOR_BROKERS || (typeof BILLING_FOR_BROKERS !== 'undefined' && BILLING_FOR_BROKERS))) {
                                                delete organization['address'];
                                            }
                                            if (organization.avatar) {
                                                const data = new FormData();
                                                data.append('image', organization.avatar);
                                                delete organization.avatar;
                                                const config = {
                                                    headers: {'Authorization': "Bearer " + localStorage.token}
                                                };
                                                axios.post(`${(window.config.consul.CORE_URL || window.CORE_URL || (typeof CORE_URL !== 'undefined' ? CORE_URL : '' ))}/images`, data, config).then(response => {
                                                    organization.avatarUrl = `${(window.config.consul.CORE_URL || window.CORE_URL || (typeof CORE_URL !== 'undefined' ? CORE_URL : '' ))}/images/` + response.data;
                                                    createSalesOrganization({variables: {salesOrganization: organization}});
                                                });
                                            } else {
                                                createSalesOrganization({variables: {salesOrganization: organization}});
                                            }
                                            this.props.history.push('/organizations');
                                        }
                                    }
                                    } />
                                )}
                            </Mutation>
                            <Input className="btn btn-secondary" type="submit" value={i18n.t('header.cancel')} onClick={() => this.props.history.push('/organizations')} />
                        </Col>
                    </Row>
                </div>
            </div>
        )
    }

}

export default CreateOrganisation;
