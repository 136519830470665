import React from "react";

function SvgImageContainer(props) {
    const {
        options,
        value,
        required,
        disabled,
        readonly,
        autofocus,
        onBlur,
        onFocus,
        onChange,
        type,
        id
    } = props;

    const img = (props.schema.img);
    const width = (props.schema.width);
    return (
        <div className="two-columns-group" id={id}>
            <div className="form-group">
                <label htmlFor={props.label} className="col-md-12 col-form-label"></label>
                <div className="card-image">
                    <img width={width} src={img}/>
                </div>
            </div>
        </div>
    );
}

export default SvgImageContainer;