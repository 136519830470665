import React from "react";
import PropTypes from "prop-types";
import {Query} from "react-apollo";
import {getVendorsQuery} from "../../queries/Queries";
import {Typeahead} from "react-bootstrap-typeahead";
import ReactDOM from "react-dom";
import i18n from "../../views/Pages/Login/i18n";

class VendorPicker extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const {
            options,
            formData,
            required,
            disabled,
            readonly,
            autofocus,
            placeholder,
            onBlur,
            onFocus,
            onChange,
            id,
            errorSchema
        } = this.props;

        return (
            <Query query={getVendorsQuery} variables={{
                orderBy: "name",
                where: `lower(businessPartnerCategory.name) like '%vendor%'`
            }}>
                {(result) => {
                    if (result.loading) return "Loading...";
                    if (result.error) ReactDOM.render(<ExpiringAlert color="danger"
                                                                     message={result.error.message}/>, document.getElementById('alert').appendChild(document.createElement("div")));
                    const {data} = result;

                    return <Typeahead ref="typeahead"
                                      options={data.OB_businessPartner}
                                      labelKey="name"
                                      selectHintOnEnter={true}
                                      placeholder={i18n.t('search.searchSupplier')}
                                      clearButton
                                      onChange={(event) => {
                                          this.props.onChange(event[0] ? event[0].name : null);
                                      }}
                    />
                }
                }
            </Query>
        );


    }
}

if (process.env.NODE_ENV !== "production") {
    VendorPicker.propTypes = {
        schema: PropTypes.object.isRequired,
        id: PropTypes.string.isRequired,
        placeholder: PropTypes.string,
        options: PropTypes.shape({
            rows: PropTypes.number,
        }),
        value: PropTypes.string,
        required: PropTypes.bool,
        disabled: PropTypes.bool,
        readonly: PropTypes.bool,
        autofocus: PropTypes.bool,
        onChange: PropTypes.func,
        onBlur: PropTypes.func,
        onFocus: PropTypes.func,
    };
}

export default VendorPicker;
