import React, {Component} from 'react';

import {Query} from "react-apollo";
import {Button, Card, CardBody, Col, Row} from 'reactstrap';
import {dateFormatter, periodFormatter, priceFormatter} from "../../utils/Formatters";
import QueryDataGrid from "../QueryDataGrid";
import i18n from '../../views/Pages/Login/i18n';
import {withRouter} from 'react-router';
import Loading from '../Loading';
import NotesAndAttachments from "../../components/NotesAndAttachments"
import axios from "../../utils/Client";
import {getBusinessPartnerQuery, getInvoiceQuery} from "../../queries/Queries"

class QueryBillsDetail extends Component {

    downloadInvoice() {
        console.log('download');
    }

    render() {
        var invoiceItems = [];
        return <Query query={getInvoiceQuery} variables={{ where: "id = '" + this.props.match.params.billid + "'", orderBy: "id" }}>
            {({ loading, error, data }) => {
                if (loading) return <Loading/>;
                if (error) return `Error! ${error.message}`;
            return <div className="invoice-details">
                <Row>
                    <Col sm="12" className="header">
                        <Button onClick={() => this.props.history.push('/view/customer/'+this.props.match.params.param)} className="btn btn-default"><i className="icon ion-arrow-left-b"></i>{i18n.t('businessPartner.invoice')}:&nbsp;&nbsp;{data.invoice.documentNo}</Button>
                    </Col>
                </Row>
                <Row>
                    <Col sm="12">
                        <Card>
                            <CardBody>
                                <Row>
                                    <Col>
                                        <table className="invoice-stats">
                                            <tbody>
                                            <tr>
                                                <td>
                                                    {i18n.t('businessPartner.Invoicedate')}
                                                </td>
                                                <td>
                                                    {dateFormatter(data.invoice.invoiceDate,null)}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    {i18n.t('businessPartner.Duedate')}
                                                </td>
                                                <td>
                                                    {dateFormatter(data.invoice.fINPaymentScheduleList[0].dueDate)}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    {i18n.t('businessPartner.Period')}
                                                </td>
                                                <td>
                                                    {periodFormatter(data.invoice.billPeriod)}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    {i18n.t('businessPartner.Payment')}
                                                </td>
                                                <td className={(data.invoice.isPaymentComplete) ? 'paid' : 'unpaid'}>
                                                    {data.invoice.isPaymentComplete ? 'Paid' : 'Unpaid'}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    {i18n.t('businessPartner.outstanding')}
                                                </td>
                                                <td>
                                                    {priceFormatter(data.invoice.outstandingAmount, data.invoice) }
                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </Col>
                                    <Col>
                                        <Button color='info' className="pull-right" onClick={(e) => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                            let win = null;
                                            if (!(window.navigator && window.navigator.msSaveOrOpenBlob)) { // for IE
                                                win = window.open('', '_blank');
                                                win.document.write("Loading invoice...");
                                            }
                                            axios.get(`${(window.config.consul.CORE_URL || window.CORE_URL || (typeof CORE_URL !== 'undefined' ? CORE_URL : '' ))}/invoice/${this.props.match.params.billid}`, {
                                                headers: {'Authorization': "Bearer " + localStorage.token},
                                                responseType: 'blob'
                                            }).then((response) => {
                                                if (window.navigator && window.navigator.msSaveOrOpenBlob) { // for IE
                                                    window.navigator.msSaveOrOpenBlob(response.data, row.documentNumber+'.pdf');
                                                } else {
                                                    win.location.href = URL.createObjectURL(response.data);
                                                    win.focus();
                                                }
                                            })

                                        }} ><i className="icon ion-android-download"></i>{i18n.t('businessPartner.Download')}</Button>
                                    </Col>
                                </Row>
                                <Row >
                                    <QueryDataGrid className="invoice-table" dataPath={'invoice.invoiceLineList'} keyField={'id'} query={`
                                            query invoiceQuery {
                                            invoice(startRow: 0, offset: 100, where: "id = '${this.props.match.params.billid}'", orderBy: "id") {
                                                        id
                                                        invoiceLineList(startRow: 0, offset: 100, where: "id is not null", orderBy: "id") {
                                                            id
                                                            product{
                                                                name
                                                                mdmPurpose
                                                            }
                                                            invoicedQuantity
                                                            uOM{
                                                                name
                                                            }
                                                            unitPrice
                                                            lineNetAmount
                                                            invoice{
                                                                currency{
                                                                    symbol
                                                                    iSOCode
                                                                }
                                                            }
                                                        }
                                                        noteList{
                                                            id
                                                            note
                                                            creationDate
                                                            updated
                                                            createdBy{
                                                                name
                                                            }
                                                            updatedBy{
                                                                name
                                                            }
                                                        }
                                                        attachmentList{
                                                            id
                                                            name
                                                            creationDate
                                                            updated
                                                            text
                                                            createdBy{
                                                                name
                                                            }
                                                            updatedBy{
                                                                name
                                                            }
                                                        }
                                            }
                                        }

                                        ` }
                                        columns={[{
                                            dataField: 'product.mdmPurpose',
                                            text: 'Type',
                                            //sort: true
                                        }, {
                                            dataField: 'product.name',
                                            text: 'Product Name',
                                            sort: true
                                        }, {
                                            dataField: 'invoicedQuantity',
                                            text: 'Quantity',
                                            sort: true
                                        }, {
                                            dataField: 'uOM.name',
                                            text: 'Unit',
                                            sort: true
                                        }, {
                                            dataField: 'unitPrice',
                                            text: 'Price',
                                            sort: true,
                                            align: "right",
                                            headerAlign: "right",
                                            formatter: priceFormatter
                                        }, {
                                            dataField: 'lineNetAmount',
                                            text: 'Total',
                                            sort: true,
                                            align: "right",
                                            headerAlign: "right",
                                            formatter: priceFormatter
                                        }]}

                                    />
                                </Row>
                                <div className="subtotals">
                                    <Row>
                                        <Col sm="6">
                                            Subtotal:</Col>
                                        <Col sm="6">
                                            {priceFormatter(data.invoice.invoiceTaxList[0].taxableAmount, data.invoice)}
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col sm="6">
                                            {i18n.t('invoice.discount')}:
                                            </Col>
                                        <Col className="">

                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col sm="6">
                                            {i18n.t('invoice.vat')}T:</Col>
                                        <Col sm="6">
                                            {priceFormatter(data.invoice.invoiceTaxList[0].taxAmount, data.invoice)}
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm="6">
                                            {i18n.t('invoice.total')}: </Col>
                                        <Col sm="6">
                                            {priceFormatter(data.invoice.grandTotalAmount, data.invoice)}
                                        </Col>
                                    </Row>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                    <Row>
                        <NotesAndAttachments noteList={data.invoice.noteList} attachmentList={data.invoice.attachmentList} recordId={this.props.match.params.billid} tableName={"Invoice"}
                                             refetchQueries={[{query: getBusinessPartnerQuery, variables: { startRow: 0, offset: 10, where: "id = '" + this.props.match.params.id + "'", orderBy: "name" }},
                                             {query:getInvoiceQuery, variables: { startRow: 0, offset: 10, where: "id = '" + this.props.match.params.billid + "'", orderBy: "id" }}]}
                                             />
                    </Row>
            </div>
            }}

        </Query>
    }
}




export default withRouter(QueryBillsDetail);
