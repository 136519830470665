import React,{Component} from 'react';
import i18n from "../../views/Pages/Login/i18n";
import {Query, withApollo} from "react-apollo";
import gql from "graphql-tag";
import axios from "../../utils/Client";
import fileDownload from "js-file-download";
import {Button} from "reactstrap";
import _ from "lodash";
import BasicQueryComponent from "../BasicQueryComponent";
import {withRouter} from "react-router-dom";


class QueryDownloadButton extends BasicQueryComponent {

    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        let variables;
        if (this.props.match.params["variables"]) {
            variables = JSON.parse(this.props.match.params["variables"][this.props.queryKey]);
        } else {
            variables = this.props.variables;
            if (variables) {
                const compiled = _.template(JSON.stringify(variables));
                variables = JSON.parse(compiled(this.props.match.params));
            }
        }
        const query = gql`${this.props.queries[this.props.queryKey]}`
        return(
            <Query query={query} variables={variables} >
                {(result) => {
                    if(result.data && this.props.match){
                        return(
                            <Button color='info' className="pull-right" onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                axios.get(`${(window.config.consul.CORE_URL || window.CORE_URL || (typeof CORE_URL !== 'undefined' ? CORE_URL : '' ))}/${this.props.type}/${this.props.match.params[this.props.idToDownloadName]}`, {
                                    headers: {'Authorization': "Bearer " + localStorage.token},
                                    responseType: 'blob'
                                }).then((response) => {
                                    fileDownload(response.data, this.props.match.params[this.props.idToDownloadName]+".pdf");
                                })
                            }} ><i className="icon ion-android-download"></i>{i18n.t('businessPartner.Download')}</Button>
                        )
                    }else {
                        return null;
                    }
                }}
                    </Query>
        )
    }
}

export default withApollo(withRouter(QueryDownloadButton));