import React, { Component } from "react";

class SidebarFooter extends Component {
	constructor(props) {
		super(props);
		this.trustBoxRef = React.createRef();
	}

	componentDidMount() {
		// If window.Trustpilot is available it means that we need to load the TrustBox from our ref.
		// If it's not, it means the script you pasted into <head /> isn't loaded just yet.
		// When it is, it will automatically load the TrustBox.
		if (!!window.config.consul.TRUSTBOX_WIDGET_ENABLED && window.Trustpilot) {
			window.Trustpilot.loadFromElement(this.trustBoxRef.current, true);
		}
	}

	render() {
		return window.config.consul.OSSO ||
			window.OSSO ||
			(typeof OSSO !== "undefined" ? OSSO : "") ? (
			<div className="sidebar-footer">
				<a
					href={"https://www.ossogas.co.uk/terms-and-conditions/"}
					target={"_blank"}
				>
					Privacy & Cookie Policy
				</a>{" "}
			</div>
		) : window.config.consul.DYCE ||
		  window.DYCE ||
		  (typeof DYCE !== "undefined" ? DYCE : "") ? (
			<div className="sidebar-footer">
				{!!window.config.consul.TRUSTBOX_WIDGET_ENABLED && (
					<div
						ref={this.trustBoxRef} // We need a reference to this element to load the TrustBox in componentDidMount.
						className="trustpilot-widget" // Renamed this to className.
						data-locale="en-GB"
						data-template-id={
							window.config.consul.TRUSTBOX_WIDGET_CONFIG.templateId
						}
						data-businessunit-id={
							window.config.consul.TRUSTBOX_WIDGET_CONFIG.businessUnitId
						}
						data-style-height="50px"
						data-style-width="100%"
						data-theme="dark"
					>
						<a
							href={window.config.consul.TRUSTBOX_WIDGET_CONFIG.href}
							target="_blank"
							rel="noopener noreferrer"
						>
							Trustpilot
						</a>
					</div>
				)}
				<a
					href={window.config.consul.TERMS_AND_CONDITIONS_URL}
					target={"_blank"}
				>
					Privacy & Cookie Policy
				</a>{" "}
			</div>
		) : null;
	}
}

export default SidebarFooter;
