import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { withApollo } from 'react-apollo';
import { AsyncTypeahead, Highlighter, Menu, MenuItem } from 'react-bootstrap-typeahead';
import { Link } from 'react-router-dom'
import {
    Button,
    Col,
    Form,
    FormGroup,
    InputGroup,
    InputGroupButton,
} from 'reactstrap';

import 'react-bootstrap-typeahead/css/Typeahead.css';
import JsxParser from 'react-jsx-parser';
import Widget01 from '../../views/Widgets/Widget01';
import _ from 'lodash';
import { withRouter } from 'react-router';
import i18n from '../../views/Pages/Login/i18n';
import ExpiringAlert from "../ExpiringAlert";
import jsonata from "./../../../node_modules/jsonata/jsonata-es5"


class Search extends Component {

    constructor(props) {
        super(props);
        this.state = { isLoading: false };

        this.escapeQuote = this.escapeQuote.bind(this);
    }

    escapeQuote(str) {
        let expression = "(\\'\\%([^,]*\\'[^,]*)?\\%\\'){1}(,(\\'\\%([^,]*\\'[^,]*)?\\%\\'))*";
        let searchParts = str.split(" or ");
        let result = "";

        searchParts.forEach(part => {
            if (part.match(expression) && part.match(expression) != null) {
                let subStr = part.match(expression)[0];
                part = part.replace(subStr, subStr.replace(/(')(.*)(')/, '\"$2\"'));
            }
            result = result + " or " + part;
        });
        result = result.replace(/^ or/, '');
        return result;
    }

    searchHandler(query) {
        if (query.includes("'")){
            query = query.split("'").join("''");
        }
        query = query.toLowerCase();
        let whereClause = null;
        if(this.props.where){
            whereClause =  _.template(this.props.where)({ query });
            whereClause = this.escapeQuote(whereClause);
        }

        this.setState({ isLoading: true });
        this.setState({ query: query });
        this.props.client.query({
            query: this.props.query,
            fetchPolicy: 'no-cache', // skip the cache
            variables: {
                orderBy: this.props.orderBy,
                where: whereClause
            }
        }).then((data) => {
            const options = data.data[Object.keys(data.data)[0]];
            let transformedOptions = options;
            if (this.props.jsonata) {
                //const jsonata = require("jsonata");
                const expression = jsonata(this.props.jsonata);
                transformedOptions = expression.evaluate(options);
            }
            const finalOptions = transformedOptions.map( option => ({name: option.name,
                                                                    matches: Object.keys(option).map( key => (Array.isArray(option[key])
                                                                        ? option[key].map( value => ({name: key, value: value}))
                                                                        : [{name: key, value: option[key] }]) ).reduce((a, b) => a.concat(b), []).filter( match => match.name === 'id' || match.value.toLowerCase().indexOf(query.toLowerCase()) > -1 )}));

            this.setState({
                isLoading: false,
                options: finalOptions,
                longestNameLength : Math.max(...transformedOptions.map(({name}) => name.length))
            })

        }).catch((error) => {
            ReactDOM.render(<ExpiringAlert color="danger" message={error.message}/>, document.getElementById('alert').appendChild(document.createElement("div")));
        });
    }

    render() {
        return (
            <div className="animated fadeIn margin-t-30 margin-l-30 search-users-header">
                <Form action="" method="post" className="form-horizontal">
                    <FormGroup row>
                        <Col md="12">
                            <InputGroup>
                                <i className='icon ion-ios-search-strong' />
                                <AsyncTypeahead id={"search"} placeholder={i18n.t('search.searchInput')} isLoading={this.state.isLoading}
                                    onSearch={this.searchHandler.bind(this)} cssclassName="md-12"
                                    ref={(ref) => this._typeahead = ref}
                                    options={this.state.options}
                                    filterBy={()=>true}
                                    delay="300"
                                    labelKey="name"
                                    selectHintOnEnter={true}
                                    onChange={selected=>{
                                        if(selected.length > 0) {
                                            this.props.history.push("/view/customer/" + selected[0].matches.filter( ({name}) => name === 'id' )[0].value);
                                            setTimeout(() => this._typeahead.getInstance().clear(), 1);
                                        }
                                    }}
                                    /*onKeyDown={(event) => { if(event.keyCode == 13){
                                        this.props.history.push("/bpl/" + this.refs.typeahead.getInstance().state.text)
                                    }}}*/
                                    renderMenuItemChildren={(option, props, index) => {
                                        return (<div onClick={(e)=>{
                                            this.props.history.push("/view/customer/" + option.matches.filter( ({name}) => name === 'id' )[0].value)
                                            setTimeout(() => this._typeahead.getInstance().clear(), 1);
                                        } } ><span className="user-col" style={{display: "inline-block", height:"42px", verticalAlign: "top"}}><i className="icon ion-person"></i></span>
                                        <span className="user-col" style={{width:this.state.longestNameLength*8+"px", overflow:"hidden", paddingTop: "4px", textOverflow: "ellipsis", display: "inline-block", height:"38px", verticalAlign:"top"}}><Highlighter search={props.text}>{option.name}</Highlighter></span>
                                            <div style={{display: "inline-block", verticalAlign: "top"}}>{option.matches.filter( ({name}) => name !== 'id' && name !== 'name' ).map( match => {
                                                return <span><span className="user-col" style={{ overflow:"hidden", paddingTop: "4px", textOverflow: "ellipsis", display: "inline-block", height:"38px", verticalAlign:"top"}}>{i18n.t('search.'+match.name)}: <Highlighter search={props.text}>{match.value}</Highlighter></span><br/></span>
                                            })}</div>
                                        </div>)
                                    }}
                                     />
                            </InputGroup>
                        </Col>
                    </FormGroup>
                </Form>
            </div>
        )
    }
}

export default withRouter(withApollo(Search));