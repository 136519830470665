import React, {Component} from 'react';
import SidebarMinimizer from './../SidebarMinimizer';

class SidebarHeader extends Component {

  render() {
    //return null
    // Uncomment following code lines to add Sidebar Header
     return (
       <div className="sidebar-header">
            <div className='cockpit-logo'></div>
            <SidebarMinimizer/>
       </div>
        
     )
  }
}

export default SidebarHeader;
