import React from "react";

function InputField(props) {
    const {
        options,
        value,
        required,
        disabled,
        readonly,
        autofocus,
        onBlur,
        onFocus,
        onChange,
        type,
        id,
    } = props;
    const rootIndex = id.lastIndexOf("_");
    const len = id.length;
    const realId = id.substring(rootIndex + 1, len);
    const additionalProperties = {};
    if (props.schema.maxLength) {
        additionalProperties.maxLength = props.schema.maxLength;
    }

    return (
        <div className="two-columns-group" id={id}>
            <div className="form-group">
                <label htmlFor={props.label}
                       className="col-md-12 col-form-label">{props.schema.title}{required ? '*' : ''}</label>
                <div className="col-md-12 cs-tooltip">
                    {props.schema.textarea ?
                        <textarea className="form-control" id={id}
                                  name={realId} label={id}
                                  placeholder={props.schema.placeholder ? props.schema.placeholder : ""}
                                  type={props.schema.type}
                                  readOnly={props.readonly}
                                  onChange={(event) => props.onChange(event.target.value)}
                                  value={props.value} {...additionalProperties}/>
                        :
                        <input className="form-control" id={id}
                               name={realId} label={id}
                               placeholder={props.schema.placeholder ? props.schema.placeholder : ""}
                               type={props.schema.type}
                               readOnly={props.readonly}
                               onChange={(event) => props.onChange(event.target.value)}
                               value={props.value} {...additionalProperties}/>}

                    {props.schema.hint ?
                        <span class="cs-tooltiptext" dangerouslySetInnerHTML={{__html: props.schema.hint}}></span> : ''}
                </div>
            </div>
            {props.schema.description ?
                <label className="col-md-12 input-description">{props.schema.description}</label> : ""}
        </div>
    );
}

export default InputField;