import React, { Component } from 'react';

import { TabContent, TabPane, Nav, NavItem, NavLink, Card, Button, CardTitle, CardText, Row, Col, Form, FormGroup, Label, Input, FormText } from 'reactstrap';
import i18n from "../../views/Pages/Login/i18n";
import PasswordPolicy from "../PasswordPolicy";


class PasswordInputMatch extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {
        return <div>
            <Row>
                <Col xs="12">
                    <FormGroup tag="fieldset">
                        <Row>
                            <Col sm="12" xs="12">
                                <Label className='user-label'>
                                    {i18n.t('header.password')}:
                                </Label>
                            </Col>
                            <Col sm="12" xs="12">
                                <Input type="password" value={this.props.password}
                                    className='create-user-input'
                                    placeholder={i18n.t('header.passwordPlaceholder')}
                                    onChange={(e) => this.props.handleChange("password", e.target.value)} />
                            </Col>
                        </Row>
                        <Row>
                            <Col sm="12">
                                <label className='info-text-label'>
                                    <PasswordPolicy/>
                                </label>
                            </Col>
                        </Row>
                    </FormGroup>
                    <FormGroup tag="fieldset">
                        <Row>
                            <Col sm="12" xs="12">
                                <Label className='user-label'>
                                    {i18n.t('header.confirmPassword')}:
                                </Label>
                            </Col>
                            <Col sm="12" xs="12">
                                <Input type="password" value={this.state.confirmpassword}
                                    className='create-user-input'
                                    placeholder={i18n.t('header.passwordPlaceholder')}
                                    onChange={(e) => this.setState({ confirmpassword: e.target.value })} />
                            </Col>
                        </Row>
                        <Row >
                            <Col className='col-5'>
                            </Col>
                            <Col className='padding-l-0'>
                                <Row>
                                    <Col className='col-4'>

                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </FormGroup>
                </Col>
            </Row>
            <Row className="btn-cnt">
                <Col xs="12" sm="12" className="spacer"></Col>
                <Col xs="4" sm="4" md="2" lg="2" xl="2"></Col>
                <Col xs="12" sm="12">
                    <Input className='btn btn-primary' type='button' value={i18n.t('profile.passwordconfirm')} onClick={
                        (e) => {
                            if (this.props.password && this.props.password === this.state.confirmpassword) {
                                this.props.handleConfirm(e);
                            } else {
                                alert(i18n.t('header.passwordsNoMatch'));
                            }
                        }} />
                </Col>
            </Row>
        </div>
    }
}



export default PasswordInputMatch;
