import React from 'react';

export const isValidEmail = email => /^[\w.~+'-]+@([\w-]+\.){1,2}[A-Za-z]{2,63}$/i.test(email);


//all the logic for tariff name transformations
export const setTariffName = (tariff, service, isAdvancedChecked, isHHMeter) => {
    
    const bespoke = tariff.bespokePrice;
    //check if the tariff has 'Advanced' in the name - different from isAdvancedChecked, which is true if the checkbox has been checked
    const advancedInName = tariff.name && tariff.name.toLowerCase().includes('advanced');

    // Get the first letter of the product name
    let productFirstLetter = ['dayRate', 'nightRate', 'singleRate', 'eveningWeekendRate'].reduce((acc, curr) => {
        if (tariff.hasOwnProperty(curr)) acc = acc.concat(curr.slice(0, 1).toUpperCase());
        if (tariff.hasOwnProperty(curr) && curr === 'singleRate') acc = acc.concat('R');
        return acc;
    }, '');

    let tariffName = tariff.name;
    
    if (bespoke && !isHHMeter && !advancedInName &&!isAdvancedChecked) {
        // BROKER-383 - tariff name transformation for tariffs that are non HH, have no 'Advanced' in the name and are bespoke
        if(productFirstLetter === 'DNE') {
            productFirstLetter = 'MR'
        } else if (productFirstLetter !=='SR') {
            productFirstLetter = `TR ${productFirstLetter}`
        }
        service === 'electricity'
            ? tariffName = `Bespoke price ${productFirstLetter}`
            : tariffName = 'Bespoke price';
    } else if ((advancedInName || isAdvancedChecked) && !bespoke) {
        //DU-366 - tariff name transformation for tariffs that either have 'Advanced' in name or have advanced checkbox and are not bespoke 
        service === 'electricity'
            ? tariffName = `Advanced Fixed ${productFirstLetter}`
            : tariffName = 'Advanced Saver Tariff Gas';
    } else if ((bespoke && (isAdvancedChecked || advancedInName) && !isHHMeter)) {
        //DU-366 - tariff name transformation for bespoke tariffs that have the advanced checkbox checked or have 'Advanced' in name
        service === 'electricity'
            ? tariffName = `Bespoke Advanced Tariff ${productFirstLetter}`
            : tariffName = 'Bespoke Advanced Saver Tariff Gas';
    } else if (bespoke && isAdvancedChecked && isHHMeter) {
        //DU-366 - tariff name transformation for bespoke tariffs that have the advanced checkbox checked and are HH
        tariffName = 'Bespoke Advanced HH Tariff';
    } else if  (bespoke && isHHMeter ) {
        //BROKER-383 - tariff name transformation for tariffs that are bespoke and HH
        tariffName = 'Bespoke HH';
    }

    return tariffName;

}