import React, {Component} from 'reactn';
import {Redirect, Route, Switch} from 'react-router-dom';
import {Query} from 'react-apollo';
import {Button, Container, Modal, ModalBody, ModalHeader} from 'reactstrap';
import Header from '../../components/Header/';
import Sidebar from '../../components/Sidebar/';
import Footer from '../../components/Footer/';

import BulkNomination from '../../views/BulkNomination/'
import Dashboard from '../../views/Dashboard/';
import SalesAgents from '../../views/SalesAgents/';
import CreateAgreement from '../../views/CreateAgreement/';
import CreateQuote from '../../views/CreateQuote/';
import Agreements from '../../views/Agreements/';
import Organisation from '../../views/Organisation/';
import Tariffs from '../../views/TariffsView/';
import Organisations from '../../views/Organisations/';
import CreateOrganisation from '../../views/CreateOrganisation/'
import Quotes from '../../views/Quotes/';
import Users from '../../views/Users/';
import CreateUser from '../../views/CreateUser/';
import EditUser from '../../views/EditUser/';
import ViewUser from '../../views/ViewUser/';
import BusinessPartner from '../../views/BusinessPartner';
import BusinessPartnersList from '../../views/BusinessPartnersList';
import ChangePassword from '../../views/ChangePassword'
import GenericView from '../../components/GenericView'
import Loading from '../../components/Loading'
import Translations from '../../views/Translations';
import Transports from '../../views/Transports';
import Tasks from '../../views/Tasks';
import Roles from "../../views/Roles";
import {
    createSalesUserMutation,
    createUserMutation,
    getUserQuery,
    uiConfigurationQuery,
    updateSalesUserMutation,
    updateUserMutation
} from "../../queries/Queries"
import Processes from "../../views/Processes";
import Systems from "../../views/Systems"
import QuoteOverviewWrapper from "../../views/QuoteOverviewWrapper";
import ContractsDetail from "../../components/ContractsDetail";
import ContractsDetailLocal from "../../components/ContractsDetailLocal";
import UsefulLinks from "../../views/UsefulLinks";
import StartProcess from "../../components/StartProcess";
import BPMNEditor from "../../views/BPMNEditor";
import CamundaLinks from "../../views/CamundaLinks";
import ConfigurableReports from "../../components/ConfigurableReports";
import ServiceRequests from "../../views/ServiceRequests";
import PurchaseInvoice from "../../components/PurchasInvoice/PurchaseInvoice";

class AsyncLoad extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            component: null
        }
    }

    componentDidMount() {
        let props = this.props;
        if (props.module === 'cockpit-ui-dataflows') {
            import("cockpit-ui-dataflows").then(Components => {
                this.setState({component: Components[props.name]});
            }).catch((error) => {
                console.log(error.toLocaleString());
            });
        }
    }

    render() {
        const C = this.state.component;
        return C ? <C {...this.props.componentProps}/> : null;
    }
}

const DataflowsRouter = (props) => <AsyncLoad module={'cockpit-ui-dataflows'} name={'DataflowsRouter'}
                                              componentProps={props}/>;

class Full extends Component {

    constructor(props) {
        super(props);
        this.state = {showLogoutWarning: false};
        this.getDefaultPath = this.getDefaultPath.bind(this);
        this.canAccess = this.canAccess.bind(this);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!this.state.timeout || this.state.timeout.pathname !== this.props.location.pathname) {
            const resetTimeouts = () => {
                if (this.state.timeout) {
                    clearTimeout(this.state.timeout.logoutTimeout);
                    clearTimeout(this.state.timeout.warningTimeout);
                    localStorage.removeItem('warningMessage');
                }

                const timeUntilLogout = 1000 * 60 * 30;
                const timeUntilWarning = 1000 * 60 * 29;

                // window.addEventListener("storage", e =>
                //     this.setState({ store: "Store value changed: " + e.newValue })
                // );
                localStorage.setItem('currentLocation', new Date().getTime().toString());

                const logoutTimeout = setTimeout(() => {
                    if (this.state.timeout && this.state.timeout.currentTimestamp === localStorage.getItem('currentLocation')) {
                        localStorage.setItem('logout', 'true');
                        this.props.history.push('/logout');
                        localStorage.removeItem('warningMessage');
                    }
                }, timeUntilLogout);
                const warningTimeout = setTimeout(() => {
                    if (this.state.timeout && this.state.timeout.currentTimestamp === localStorage.getItem('currentLocation')) {
                        localStorage.setItem('warningMessage', 'true')
                        this.setState({showLogoutWarning: true})
                    }
                }, timeUntilWarning);

                const syncWithNewTabTime = setInterval(() => {
                    if (localStorage.getItem('warningMessage')) {
                        this.setState({showLogoutWarning: true});
                    } else if (localStorage.getItem('logout')) {
                        this.props.history.push('/logout');
                        clearInterval(syncWithNewTabTime);
                    }
                    // else {
                    //     this.setState({showLogoutWarning: false});
                    // }
                }, 1000);

                this.setState({
                    timeout: {
                        pathname: this.props.location.pathname,
                        logoutTimeout,
                        warningTimeout,
                        clearTimeouts: () => resetTimeouts(),
                        currentTimestamp: localStorage.getItem('currentLocation')
                    },
                    showLogoutWarning: false
                });
            }
            resetTimeouts();
        }
    }

    componentDidMount() {
        localStorage.setItem('currentLocation', new Date().getTime().toString());
    }

    canAccess(data, role) {
        return data.roles.some(r => role.some(roles => r.role.key === roles));
    }

    getDefaultPath(items) {
        for (let i = 0; i < items.length; i++) {
            if (items[i].defaultMenu) return items[i].url;
            if (items[i].children) return this.getDefaultPath(items[i].children);
        }
    }

    render() {
        const ALLOWED_ROLES = window.config.consul.ALLOWED_ROLES;
        return <Query query={uiConfigurationQuery}>
            {(confResult) => {
                if (confResult.loading) return <Loading/>;
                if (confResult.error) {
                    document.cookie = 'token=; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
                    confResult.client.resetStore();
                    localStorage.clear();
                    this.props.history.push('/');
                    return <h1>Cockpit Core is down. Please contact your system administrator.</h1>
                }

                const transformUiMenuItemOrder = (uiMenuItemOrder) => ({
                    title: uiMenuItemOrder.uiMenuItem.type === "title",
                    divider: uiMenuItemOrder.uiMenuItem.type === "divider",
                    name: uiMenuItemOrder.uiMenuItem.defaultLabel,
                    url: uiMenuItemOrder.uiMenuItem.url,
                    icon: uiMenuItemOrder.uiMenuItem.icon,
                    badge: {
                        variant: uiMenuItemOrder.uiMenuItem.badgeVariant,
                        text: uiMenuItemOrder.uiMenuItem.badgeText
                    },
                    children: uiMenuItemOrder.children && uiMenuItemOrder.children.length > 0 ? uiMenuItemOrder.children.map(transformUiMenuItemOrder) : null,
                    defaultMenu: uiMenuItemOrder.defaultMenu
                });

                if (confResult.data.getUIConfiguration) {
                    const items = confResult.data.getUIConfiguration.uiMenus.uiMenuItemOrders.map(transformUiMenuItemOrder);
                    let defaultPath = this.getDefaultPath(items);
                    defaultPath = defaultPath && defaultPath != "/" ? defaultPath : "/dashboard";
                    return (

                        <Query query={getUserQuery}>
                            {({loading, error, data}) => {
                                if (loading) return null;
                                if (error) return `Error! ${error.message}`;

                                localStorage.roles = data.getUser.roles.map(({role}) => role.key);
                                const org = data.getUser.organizations.reduce((acc, curr) => curr.businessPartnerId != null ? curr : acc, {});

                                if (!this.global.user) {
                                    this.setGlobal({
                                        user: data.getUser,
                                        username: data.getUser.username,
                                        fullName: data.getUser.firstName + ' ' + data.getUser.lastName,
                                        roles: data.getUser.roles.map(({role}) => role.key),
                                        permissions: [...new Set(data.getUser.roles.flatMap(({role}) => role.permissions).map(({permission}) => permission.key))],
                                        salesOrganization: org.businessPartnerId,
                                        ultimateParentOrganization: org.ultimateParent ? org.ultimateParent.businessPartnerId : null
                                    });
                                }
                                return <div className="app">
                                    <div className="app-body"><Sidebar {...this.props} nav={{items: items}}/>
                                        <main className="main">
                                            <Header currentUser={data.getUser}/>
                                            <Container fluid>

                                                <Switch>
                                                    <Route path="/start/bp/:businessPartnerId" name="StartProcess"
                                                           component={StartProcess}/>
                                                    <Route path="/start/contract/:contractId" name="StartProcess"
                                                           component={StartProcess}/>
                                                    <Route path="/dashboard" name="Dashboard" component={Dashboard}/>
                                                    <Route path="/changepassword" exact name="Users"
                                                           component={ChangePassword}/>
                                                    {this.canAccess(data.getUser, ['ADMIN']) &&
                                                        <Route path="/translations" exact name="Translations"
                                                               component={Translations}/>
                                                    }
                                                    {this.canAccess(data.getUser, ['ADMIN']) &&
                                                        <Route path="/transports" exact name="Transports"
                                                               component={Transports}/>
                                                    }
                                                    {this.canAccess(data.getUser, ['COMMISSIONMANAGER', 'SALESADMIN']) &&
                                                        <Route path="/self-bill-invoice" exact render={(props) => (
                                                            <PurchaseInvoice {...props}
                                                                             businessPartnerId={this.global.roles.filter(obj => obj === "SALESADMIN").length > 0 ? "" : org.businessPartnerId}/>
                                                        )}/>
                                                    }
                                                    {this.canAccess(data.getUser, ['ADMIN']) &&
                                                        <Route path="/systems" exact name="Systems"
                                                               component={Systems}/>
                                                    }
                                                    <Route path="/roles" exact name="Roles" component={Roles}/>
                                                    {this.canAccess(data.getUser, ['ADMIN']) &&
                                                        <Route path="/users" exact name="Users" component={Users}/>
                                                    }
                                                    {this.canAccess(data.getUser, ['ADMIN']) &&
                                                        <Route path="/users/createuser" name="Create User"
                                                               render={(props) =>
                                                                   <CreateUser {...props} currentUser={data.getUser}
                                                                               createUserMutation={createUserMutation}
                                                                               setCurrentUser={user => this.setCurrentUser(user)}
                                                                               organizationRequired={false}
                                                                               backTo={"/users"} showSystems={true}/>}
                                                        />
                                                    }
                                                    <Route path="/users/edituser/:username" name="Edit User"
                                                           render={(props) =>
                                                               <EditUser {...props} currentUser={data.getUser}
                                                                         updateUserMutation={updateUserMutation}
                                                                         setCurrentUser={user => this.setCurrentUser(user)}
                                                                         allowedRoles={this.global.permissions.includes("Users.AssignSalesManagerRole")
                                                                             ? ["SALESADMIN", "SALESMANAGER", "SALESAGENT"] : null}
                                                                         organizationRequired={false}
                                                                         backTo={"/users"} showSystems={true}/>}
                                                    />
                                                    <Route path="/users/viewuser/:username" name="View User"
                                                           render={(props) =>
                                                               <ViewUser {...props} currentUser={data.getUser}/>}
                                                    />
                                                    <Route path="/users/viewuser/" name="View User" render={(props) =>
                                                        <ViewUser {...props} currentUser={data.getUser}/>}
                                                    />
                                                    <Route path="/bp/:id" name="Business Partner"
                                                           component={BusinessPartner}/>
                                                    <Route path="/view/:name/:param" render={(props) =>
                                                        <GenericView {...props}
                                                                     configuration={confResult.data.getUIConfiguration}/>}
                                                    />
                                                    <Route path="/view/:name/" render={(props) =>
                                                        <GenericView {...props}
                                                                     configuration={confResult.data.getUIConfiguration}/>}
                                                    />

                                                    <Route path="/bpl" name="Business Partners"
                                                           component={BusinessPartnersList}/>
                                                    <Route path="/bpl/:name" name="Business Partner Search Results"
                                                           component={BusinessPartnersList}/>
                                                    {this.canAccess(data.getUser, ['SALESADMIN', 'SALESMANAGER', 'SALESAGENT']) &&
                                                        <Route path="/tasks" exact name="Tasks" component={Tasks}/>
                                                    }

                                                    <Route path="/processes" exact name="Processes"
                                                           component={Processes}/>
                                                    <Route path="/dataflows" name="Dataflows"
                                                           component={DataflowsRouter}/>

                                                    {this.canAccess(data.getUser, ['SALESADMIN', 'SALESMANAGER', 'SALESAGENT']) &&
                                                        <Route path="/quotes" exact name="Quotes"
                                                               render={props =>
                                                                   <Quotes {...props} currentUser={data.getUser}/>
                                                               }
                                                        />
                                                    }
                                                    {this.canAccess(data.getUser, ['SALESADMIN', 'SALESMANAGER']) &&
                                                        <Route path="/sales-agents" exact name="SalesAgents"
                                                               render={props =>
                                                                   <SalesAgents {...props}
                                                                                currentUser={data.getUser}
                                                               />}
                                                        />
                                                    }
                                                    <Route path="/organization/:id/create-sales-agent" exact
                                                           name="CreateSalesAgent" render={(props) =>
                                                        <CreateUser {...props} currentUser={data.getUser}
                                                                    rolesInUserMutation={true}
                                                                    createUserMutation={createSalesUserMutation}
                                                                    allowedRoles={this.global.permissions.includes("Users.AssignSalesManagerRole") && ALLOWED_ROLES !== undefined ? ALLOWED_ROLES : ["SALESAGENT"]}
                                                                    setCurrentUser={user => this.setCurrentUser(user)}
                                                                    backTo={"/organization/:id"}
                                                                    organizationRequired={true}
                                                                    showSystems={false}/>}
                                                    />
                                                    {this.canAccess(data.getUser, ['SALESADMIN']) &&
                                                        <Route path="/sales-agents/create-sales-agent" exact
                                                               name="CreateSalesAgent" render={(props) =>
                                                            <CreateUser {...props}
                                                                        currentUser={data.getUser}
                                                                        rolesInUserMutation={true}
                                                                        createUserMutation={createSalesUserMutation}
                                                                        allowedRoles={this.global.permissions.includes("Users.AssignSalesManagerRole") && ALLOWED_ROLES}
                                                                        setCurrentUser={user => this.setCurrentUser(user)}
                                                                        backTo={"/sales-agents"}
                                                                        organizationRequired={true}
                                                                        showSystems={false}
                                                            />}
                                                        />
                                                    }
                                                    {this.canAccess(data.getUser, ['SALESADMIN']) &&
                                                        <Route path="/sales-agents/:username" exact name="SalesAgent"
                                                               render={(props) => {
                                                                   return <EditUser {...props}
                                                                                    currentUser={data.getUser}
                                                                                    rolesInUserMutation={true}
                                                                                    updateUserMutation={updateSalesUserMutation}
                                                                                    allowedRoles={this.global.permissions.includes("Users.AssignSalesManagerRole") && ALLOWED_ROLES}
                                                                                    setCurrentUser={user => this.setCurrentUser(user)}
                                                                                    organizationRequired={true}
                                                                                    backTo={"/sales-agents"}
                                                                                    showSystems={false}/>
                                                               }}
                                                        />
                                                    }
                                                    {this.canAccess(data.getUser, ['SALESADMIN', 'SALESMANAGER', 'SALESAGENT']) &&
                                                        <Route path="/agreements-old/:contractid" exact name="Agreement"
                                                               component={ContractsDetail}/>
                                                    }
                                                    {this.canAccess(data.getUser, ['SALESADMIN', 'SALESMANAGER', 'SALESAGENT']) &&
                                                        <Route path="/agreements/:contractid" exact name="Agreement"
                                                               component={ContractsDetailLocal}/>
                                                    }
                                                    {this.canAccess(data.getUser, ['SALESADMIN', 'SALESMANAGER', 'SALESAGENT']) &&
                                                        <Route path="/agreements" exact name="Agreements"
                                                               render={(props) => {
                                                                   return <Agreements {...props}
                                                                                      currentUser={data.getUser}/>
                                                               }}
                                                        />
                                                    }
                                                    {this.canAccess(data.getUser, ['SALESADMIN', 'SALESMANAGER', 'SALESAGENT']) &&
                                                        <Route path="/create-agreement/:processInstanceId" exact
                                                               name="CreateAgreement" component={CreateAgreement}/>
                                                    }
                                                    {this.canAccess(data.getUser, ['SALESADMIN', 'SALESMANAGER', 'SALESAGENT']) &&
                                                        <Route path="/quotes/:quoteId" name="ViewQuote"
                                                               component={QuoteOverviewWrapper}/>
                                                    }
                                                    {this.canAccess(data.getUser, ['SALESADMIN', 'SALESMANAGER', 'SALESAGENT']) &&
                                                        <Route path="/create-quote/:quoteId" name="CreateQuote"
                                                               render={props => <CreateQuote
                                                                   {...props} userRole={data.getUser}/>}/>
                                                    }
                                                    <Route path="/create-quote" name="CreateQuote"
                                                           render={props => <CreateQuote
                                                               {...props} userRole={data.getUser}/>}/>
                                                    <Route path="/price-quote/:quoteId" name="CreateQuote"
                                                           render={(props) => <CreateQuote
                                                               priceQuote={true} bespoke={true} {...props}
                                                               userRole={data.getUser}/>}/>
                                                    {this.canAccess(data.getUser, ['SALESMANAGER', 'SALESAGENT', 'SALESADMIN']) &&
                                                        <Route path="/organization/:id" exact name="Organisation"
                                                               render={(props) => <Organisation currentUser={data.getUser}
                                                                   salesOrganization={org.businessPartnerId} {...props} />}/>
                                                    }
                                                    {this.canAccess(data.getUser, ['SALESMANAGER', 'SALESAGENT', 'SALESADMIN']) &&
                                                        <Route path="/organization" exact name="Organisation"
                                                               render={(props) => <Organisation currentUser={data.getUser}
                                                                   salesOrganization={org.businessPartnerId} {...props} />}/>
                                                    }
                                                    {this.canAccess(data.getUser, ['SALESADMIN']) &&
                                                        <Route path="/organizations" exact name="Organisations"
                                                               component={Organisations}/>
                                                    }
                                                    {this.canAccess(data.getUser, ['ADMIN', 'SALESADMIN']) &&
                                                        <Route path="/tariffs" exact name="Tariffs"
                                                               component={Tariffs}/>
                                                    }
                                                    {this.canAccess(data.getUser, ['SALESADMIN', 'SALESMANAGER', 'SALESAGENT']) &&
                                                        <Route path="/create-organisation" exact
                                                               name="CreateOrganisation"
                                                               component={CreateOrganisation}/>
                                                    }
                                                    <Route path="/bulk-nomination" exact name="BulkNomination"
                                                           component={BulkNomination}/>
                                                    <Route path="/bulk-nomination/progress" exact name="BulkNomination"
                                                           component={BulkNomination}/>
                                                    <Route path="/links" exact name="UsefulLinks"
                                                           component={UsefulLinks}/>

                                                    <Route path="/bpmn-editor" exact name="BPMN Editor"
                                                           component={BPMNEditor}/>

                                                    <Route path="/camunda-links" exact name="Camunda Links"
                                                           component={CamundaLinks}/>
                                                    <Redirect from="/" exact to={defaultPath}/>
                                                    <Route path="/reports" exact name="Configurable Reports"
                                                           render={(props) => <ConfigurableReports configuration={{
                                                               ...confResult.data.getUIConfiguration,
                                                               global: this.global
                                                           }}/>}
                                                    />
                                                    <Route path="/service-requests" exact name="Service Requests"
                                                           render={(props) => <ServiceRequests configuration={{
                                                               ...confResult.data.getUIConfiguration,
                                                               global: this.global
                                                           }}/>}
                                                    />
                                                </Switch>
                                            </Container>
                                        </main>
                                        {/*<Aside/>*/}
                                    </div>
                                    {!(window.config.consul.DYCE || window.DYCE || (typeof DYCE !== 'undefined' ? DYCE : '')) &&
                                        <Footer/>}
                                    <Modal isOpen={this.state.showLogoutWarning}>
                                        <ModalHeader>
                                            You will be logged out due to inactivity
                                        </ModalHeader>
                                        <ModalBody>
                                            <div>You will be logged out in 60 seconds. Click "Continue" to extend your
                                                session.
                                            </div>
                                            <Button onClick={() => this.state.timeout.clearTimeouts()} color="primary"
                                                    style={{float: "right"}}>Continue</Button>
                                        </ModalBody>
                                    </Modal>
                                </div>;
                            }}
                        </Query>
                    );
                } else {
                    document.cookie = 'token=; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
                    confResult.client.resetStore();
                    localStorage.clear();
                    this.props.history.push('/');
                    return <h1>Cockpit Core is down. Please contact your system administrator.</h1>
                }
            }}
        </Query>
    }
}


export default Full;
