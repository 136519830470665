import React, {Component} from 'react';
import {Query} from "react-apollo";
import Loading from "../Loading";
import {MDBCard, MDBCardTitle, MDBContainer} from 'mdbreact';

import gql from "graphql-tag";
import {Button, Col, Row} from "reactstrap";
import GenericView from "../GenericView";
import {uiConfigurationQuery} from "../../queries/Queries";

class ConfigurableReports extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentCategory: ""
        };
    }

    render() {
        let reports = [];
        let categories = {};

        return (
            <Query query={uiConfigurationQuery}>
                {(result) => {
                    if (result.loading) return (<Loading/>);
                    if (result.error) return <div>{result.error} </div>;
                    const {data} = result;

                    {
                        data:data.getUIConfiguration.uiLayouts.forEach((layout) => {
                            if (layout.name.startsWith('config-report')) {
                                reports.push(layout.name);
                                let category = RegExp(/config-report-(.*)-/).exec(layout.name)[1];
                                category = category.replace(/^./, category[0].toUpperCase());
                                if (!categories.hasOwnProperty(category)) {
                                    let icon = RegExp(/iconName="(.*?)"/).exec(layout.jsx)[1];
                                    categories[category] = icon;
                                }
                            }
                        });
                    }

                    return (
                        <div id="configReports">
                            <Row>
                                <div className="col-lg-4 col-md-4 col-xs-12">
                                    <MDBContainer>
                                        <MDBCard className="report-cart-body card-body">
                                            <MDBCardTitle className="report-cart">REPORT CATEGORIES</MDBCardTitle>
                                            {Object.keys(categories).map(category => <div className="row"
                                                                                          id={category + "Btn"}>
                                                <Button
                                                    onClick={() => {
                                                        this.setState({
                                                            currentCategory: category
                                                        });
                                                    }}
                                                    className={this.state.currentCategory === category ? "report-category-btn selected" : "report-category-btn"}
                                                >
                                                    <div className="row">
                                                        <div className="col-sm-2 col-xs-2">
                                                            <img className="report-category-icon"
                                                                 src={"/img/categories/" + categories[category]}/>
                                                        </div>
                                                        <div className="col-sm-10 col-xs-10">
                                                            {category}
                                                        </div>
                                                    </div>

                                                </Button>
                                            </div>)}
                                        </MDBCard>
                                    </MDBContainer>
                                </div>
                                <Col className="col-lg-8 col-md-8 col-xs-12">
                                    {reports.filter(report => RegExp(/config-report-(.*)-/).exec(report)[1].toLowerCase() === this.state.currentCategory.toLowerCase()).map(report =>
                                        <GenericView layout={report}
                                                     configuration={this.props.configuration}/>)}
                                </Col>
                            </Row>
                        </div>
                    );
                }}
            </Query>
        );
    }
}

export default ConfigurableReports;