import i18n from '../../views/Pages/Login/i18n';

export default function validate(formData, errors) {

    if(formData.correspondence_address) {
        if(!formData.correspondence_address.correspondence_address_street && !formData.correspondence_address.correspondence_address_quarter) {
            errors.correspondence_address.correspondence_address_street.addError(i18n.t("switchingForm.streetOrDiscrictRequired"));
            errors.correspondence_address.correspondence_address_quarter.addError(i18n.t("switchingForm.streetOrDiscrictRequired"));
        }

    }

    if (formData.place_of_supply) {
        if(!formData.place_of_supply.street && !formData.place_of_supply.quarter) {
            errors.place_of_supply.street.addError(i18n.t("switchingForm.streetOrDiscrictRequired"));
            errors.place_of_supply.quarter.addError(i18n.t("switchingForm.streetOrDiscrictRequired"));
        }
    }

    if(formData.other_place_of_supply ) {
        formData.other_place_of_supply.forEach((other_place, key) => {
            if(other_place.place_of_supply) {
                if(!other_place.place_of_supply.street && !other_place.place_of_supply.quarter) {
                    errors.other_place_of_supply[key].place_of_supply.street.addError(i18n.t("switchingForm.streetOrDiscrictRequired"));
                    errors.other_place_of_supply[key].place_of_supply.quarter.addError(i18n.t("switchingForm.streetOrDiscrictRequired"));
                }
            }
        });
    }

    return errors;
}
