import React, {Component} from 'react';
import ReactDOM from 'react-dom';
import {withRouter} from 'react-router';
import {compose, graphql, Mutation} from "react-apollo";
import {Breadcrumb, BreadcrumbItem, Col, Input, Row, TabContent, TabPane} from 'reactstrap';
import i18n from '../Pages/Login/i18n';
import UserDetails from "../UserDetails/UserDetails"
import Loading from '../../components/Loading';
import axios from "../../utils/Client";
import {createUserMutation, createUserRoles, getUsers, organizationsQuery} from "../../queries/Queries";
import ExpiringAlert from "../../components/ExpiringAlert";


class CreateUser extends Component {

    constructor(props) {
        super(props);

        this.state = {
            user: {
                enabled: true,
                roles: {user: false, admin: false, billing: false, dataflows: false},
                phoneNumbers: [{
                    phoneNumber: "",
                    type: "",
                    primaryNumber: true
                }],
                organizations: [],
                userSystems: []
            }
        };
        this.state.activeTab = "1";
        this.handleSubmit = this.handleSubmit.bind(this);
        this.toggle = this.toggle.bind(this);
        this.handleChange = this.handleChange.bind(this);

    }

    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        }
    }

    handleChange(name, value, name1, value1) {
        if (name1) {
            this.setState({user: {...this.state.user, [name]: value, [name1]: value1}});
        } else {
            this.setState({user: {...this.state.user, [name]: value}});
        }
    }

    handleSubmit(createUserMutation, event) {
        if (document.getElementById('user-details').reportValidity()) {
            var user = {...this.state.user};
            delete user.roles;
            delete user.avatar;
            user.organizations = user.organizations.map(({value}) => {
                delete value.ultimateParent;
                return value;
            });
            if (!this.props.showSystems) {
                delete user.userSystems;
            }
            var strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");
            if (!strongRegex.test(user.password)) {
                ReactDOM.render(<ExpiringAlert color="danger"
                                               message={i18n.t('createUser.passwordNotCorrect')}/>, document.getElementById('alert'));
                return;
            }
            if (Object.entries(this.state.user.roles).map(([k, v]) => v).filter(v => v).length === 0) {
                ReactDOM.render(<ExpiringAlert color="danger"
                                               message={i18n.t('createUser.missingRoles')}/>, document.getElementById('alert'));
                return;
            }

            const createUser = () => {
                let addRoles = [];
                for (var role in this.state.user.roles) {
                    if (this.state.user.roles[role]) {
                        addRoles.push(role.toUpperCase());
                    }
                }
                const rolesInUserMutation = this.props.rolesInUserMutation;
                if (rolesInUserMutation) {
                    console.log("Adding roles to user mutation");
                    user.roles = addRoles;
                }

                createUserMutation({
                    variables: {
                        user: user
                    }
                }).then(({data}) => {
                    this.props.createUserRoles({
                        variables: {
                            username: this.state.user.username,
                            roles: addRoles
                        },
                        refetchQueries: [{query: getUsers}, {query: organizationsQuery}]
                    }).then(({data}) => {
                        console.log('got data', data);
                    }).catch((error) => {
                        ReactDOM.render(<ExpiringAlert color="danger"
                                                       message={error.message}/>, document.getElementById('alert').appendChild(document.createElement("div")));
                        console.log('there was an error sending the query', error);
                    });
                    ReactDOM.render(<ExpiringAlert color="success"
                                                   message={i18n.t('users.successfulCreate')}/>, document.getElementById('alert').appendChild(document.createElement("div")));
                    this.props.history.push(this.props.backTo.replace(":id", this.props.match.params.id))
                    console.log('got data', data);
                }).catch((error) => {
                    ReactDOM.render(<ExpiringAlert color="danger"
                                                   message={error.graphQLErrors[0].message}/>, document.getElementById('alert').appendChild(document.createElement("div")));
                    console.log('there was an error sending the query', error);
                });
            }

            if (this.state.user.avatar) {
                const data = new FormData();
                data.append('image', this.state.user.avatar);
                const config = {
                    headers: {'Authorization': "Bearer " + localStorage.token}
                };
                axios.post(`${(window.config.consul.CORE_URL || window.CORE_URL || (typeof CORE_URL !== 'undefined' ? CORE_URL : ''))}/images`, data, config).then(response => {
                    user.avatarUrl = `${(window.config.consul.CORE_URL || window.CORE_URL || (typeof CORE_URL !== 'undefined' ? CORE_URL : ''))}/images/` + response.data;
                    createUser();
                });
            } else {
                createUser();
            }
        }
        // event.preventDefault();
    }

    render() {
        const {initialValid} = this.props;

        return (
            <div className="create-user">
                <Row className='subheader'>
                    <Col className='col-8'>
                        <h1>{i18n.t('header.createUsers')}</h1>
                    </Col>
                    <Col className='col-4'>
                        <Breadcrumb>
                            <BreadcrumbItem><i className="icon ion-android-home"></i><a
                                href="#">Home</a></BreadcrumbItem>
                            <BreadcrumbItem><a href="#/users">Users</a></BreadcrumbItem>
                            <BreadcrumbItem active>Create</BreadcrumbItem>
                        </Breadcrumb>
                    </Col>
                </Row>
                <div>
                    <div className='bp-nav-items'>
                        <TabContent activeTab={this.state.activeTab} className='bp-tab-content'>
                            <TabPane tabId="1">
                                <Row>
                                    <Col xs="12" sm="12" md="10" lg="8" xl="6">
                                        <UserDetails user={this.state.user} onChange={this.handleChange}
                                                     showPasswordField={true} allowedRoles={this.props.allowedRoles}
                                                     organizationRequired={this.props.organizationRequired}
                                                     showSystems={this.props.showSystems}
                                                     defaultOrganization={this.props.match.params.id}
                                                     userRole={this.props.currentUser.roles}
                                        />
                                    </Col>
                                </Row>
                                <Row className="btn-cnt">
                                    <Col xs="12" sm="12" className="spacer"></Col>
                                    <Col xs="4" sm="4" md="2" lg="2" xl="2"></Col>
                                    <Col xs="8" sm="8">
                                        <Mutation mutation={this.props.createUserMutation}>
                                            {(createUserMutation, data) =>
                                                <Input className='btn btn-primary' type='submit'
                                                       value={i18n.t('header.addUser')}
                                                       onClick={() => this.handleSubmit(createUserMutation)}/>
                                            }
                                        </Mutation>
                                        <Input className="btn btn-secondary" type="submit"
                                               value={i18n.t('header.cancel')}
                                               onClick={() => this.props.history.push(this.props.backTo.replace(":id", this.props.match.params.id))}/>
                                    </Col>
                                </Row>
                            </TabPane>
                        </TabContent>
                    </div>
                </div>
            </div>)

    }
}

const LoadingComponent = () => <Loading/>

let withRouter2 = withRouter(CreateUser);
export default compose(
    graphql(createUserRoles, {name: 'createUserRoles'}))(withRouter2);
