import React, {Component} from "react";

import {Field, reduxForm, SubmissionError} from "redux-form";
import {Button, Card, CardBody, CardGroup, Col, Container, Row, FormGroup} from "reactstrap";
import {renderInput} from "./../../../RenderInput";
import i18n from "../Login/i18n";
import { translate } from 'react-i18next';
//import showResults from './../../../components/showResults';
import {compose, graphql} from "react-apollo";
import {withRouter} from "react-router-dom";
import axios from "axios";
import PasswordInputMatch from "../../../components/PasswordInputMatch";
import ExpiringAlert from "../../../components/ExpiringAlert";
import ReactDOM from "react-dom";

const validate = values => {
    const errors = {}
    const requiredFields = ['username', 'password']
    requiredFields.forEach(field => {
        if (!values[field]) {
            errors[field] = i18n.t('login.required')
        }
    })
    if (values.username && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.username)) {
        errors.username = i18n.t('login.invalidEmail')
    }
    return errors
}


class ResetPassword extends Component {

    constructor(props){
        super(props);
        this.state = {password: ''};
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    errorHandler(error) {
        console.log('there was an error sending the query', error);
        this.setState({ errorMessage: error.response.data});
    }

    handleSubmit() {
        return axios.post(`${(window.config.consul.CORE_URL || window.CORE_URL || (typeof CORE_URL !== 'undefined' ? CORE_URL : '' ))}/reset-password`, {token: this.props.match.params.token, password: this.state.password})
        .then(response => {
            ReactDOM.render(<ExpiringAlert color="success" message={i18n.t('resetPassword.passwordReset')} />, document.getElementById('alert'));
            this.setState({ errorMessage: null});
            this.props.history.push('/');
        })
        .catch(this.errorHandler.bind(this));

    }

    render() {


            <p className="signin-text-muted">{ i18n.t('resetPassword.explanation') }</p>
        return (
            <div className="app flex-row align-items-center">
                <Container fluid={true}>
                    <Row className="justify-content-center">
                        <Col md="12" style={{paddingLeft: 0, paddingRight: 0}}>
                            <CardGroup>
                                <Card className="p-4 login-panel">
                                    <CardBody className='credentials-card'>
                                        <form>
                                            <div className='login-logo'></div>
                                            <div className="text-danger">{this.state.errorMessage}</div>
                                            <PasswordInputMatch password={this.state.password} handleChange={(_, password) => this.setState({password})}
                                            handleConfirm={() => this.handleSubmit()}/>
                                        </form>
                                    </CardBody>
                                </Card>
                                <Card>
                                    <CardBody className="text-center side-image">
                                    </CardBody>
                                </Card>
                            </CardGroup>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}

const reduxFormWrapper = reduxForm({
    form: 'login',
    validate
});


export default compose(
    //gqlWrapper,
    reduxFormWrapper,
    withRouter
)(ResetPassword);
