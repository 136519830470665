import React from 'react';
import { Form, FormGroup, Input } from 'reactstrap';
import i18n from '../Pages/Login/i18n';
import moment from "moment";
import { SingleDatePicker } from "react-dates";
import MaskInput from 'react-maskinput'

/**
 * Author: Martin Stoynov
 * This class is intented for the use of customizing react-jsonschema-form fields and not as a standalone component.
 */
class MeterFields extends React.Component {

    state = {
        mDMBPEstate: this.props.formContext.mDMBPEstateList.length == 1
            ? this.props.formContext.mDMBPEstateList[0]
            : { mDMEstate: { mDMMeterPointList: [] } },
        mDMMeterPoint: { mDMMeterRegisterList: [] },
        meter: {},
        date: moment(),
        type: 'Client',
    }

    updateForm() {

        const {mDMBPEstate, mDMMeterPoint, meter, date, type} = this.state;

        const data = {
            mDMBPEstate: '',
            mDMMeterPoint: '',
            meter: '',
            date: '',
            type: ''
        };

        if (mDMBPEstate && mDMBPEstate.mDMEstate && mDMBPEstate.mDMEstate.locationAddress && mDMBPEstate.mDMEstate.locationAddress.addressLine1) {
            data.mDMBPEstate = mDMBPEstate.mDMEstate.locationAddress.addressLine1;
        }

        if (mDMMeterPoint && mDMMeterPoint.id) {
            data.mDMMeterPoint = mDMMeterPoint.name;
        }

        if (meter && meter.id) {
            data.meter = meter.serialNumber;
        }

        if (date) {
            data.date = date.format('YYYY-MM-DD');
        }

        if (type) {
            data.type = type;
        }

        console.log('data',data);
        this.props.onChange(data);
    }

    render() {
        return (
            <Form>
                <FormGroup>
                    <label>{"MP Address"}:&nbsp;</label>
                    <Input
                        type="select"
                        value={this.state.mDMBPEstate.id}
                        onChange={(e) => {
                            this.setState({
                                meter: {},
                                mDMMeterPoint: { mDMMeterRegisterList: [] },
                                mDMBPEstate: this.props.formContext.mDMBPEstateList.filter(estate => estate.id === e.target.value).reduce((accumulator, currentValue) => currentValue || accumulator, { mDMEstate: { mDMMeterPointList: [] } })
                            }, this.updateForm);
                        }}
                    >
                        <option value="">---</option>
                        {this.props.formContext.mDMBPEstateList.map(estate => <option value={estate.id}>{estate.mDMEstate.locationAddress.addressLine1}</option>)}
                    </Input>
                </FormGroup>
                <FormGroup>
                    <label>{"Meter point"}:&nbsp;</label>
                    <Input
                        type="select"
                        value={this.state.mDMMeterPoint.id}
                        onChange={(e) => {
                            this.setState({
                                mDMMeterPoint: this.state.mDMBPEstate.mDMEstate.mDMMeterPointList.filter(meterPoint => meterPoint.id === e.target.value).reduce((accumulator, currentValue) => currentValue || accumulator, { mDMMeterRegisterList: [] })
                            }, this.updateForm);
                        }}
                    >
                        <option value="">---</option>
                        {this.state.mDMBPEstate.mDMEstate.mDMMeterPointList.map(meterPoint => <option value={meterPoint.id}>{meterPoint.name}</option>)}
                    </Input>
                </FormGroup>
                <FormGroup>
                    <label>{"Meter"}:&nbsp;</label>
                    <Input
                        type="select"
                        value={this.state.meter.id}
                        onChange={(e) => {
                            this.setState({
                                meter: this.state.mDMMeterPoint.mDMMeterRegisterList.filter(({ meter }) => meter.id === e.target.value).reduce((accumulator, currentValue) => currentValue || accumulator, { meter: '' }).meter
                            }, this.updateForm);
                        }}
                    >
                        <option value="">---</option>
                        {this.state.mDMMeterPoint.mDMMeterRegisterList.map(({ meter }) => meter).filter((meter, index, self) => {
                            return self.findIndex(m => m.id === meter.id) === index;
                        }).map(meter => <option value={meter.id}>{meter.serialNumber}</option>)
                        }
                    </Input>
                </FormGroup>
                {this.state.mDMMeterPoint.mDMMeterRegisterList.filter(({ meter }) => meter.id === this.state.meter.id).map(mDMMeterRegister => {
                    const lastReading = mDMMeterRegister.meter.ammMeterReadingList
                        .filter(reading => (reading.customerReadType === "Customer" || reading.customerReadType === "Initial") && reading.product.id === mDMMeterRegister.mDMRegister.product.id)
                        .map(reading => ({ ...reading, readingdate: moment(reading.readingdate, "YYYY-MM-DDTHH:mm:ssZ") }))
                        .sort((a, b) => b.readingdate.isBefore(a.readingdate) ? -1 : 1)[0];
                    return (<FormGroup>
                        <div className="last-reading">
                            <label>Last Read {lastReading ? lastReading.readingdate.format("L") : ""}</label>
                            <p>{lastReading ? lastReading.newreadingmetervt : "N/A"}</p>
                        </div>
                        <label>{mDMMeterRegister.mDMRegister.product.name}:&nbsp;</label><br />

                        <MaskInput
                            value={this.state['reading' + mDMMeterRegister.id]}
                            onChange={(e) => {
                                this.setState({
                                    ['reading' + mDMMeterRegister.id]: e.target.value, ['lastReading' + mDMMeterRegister.id]: lastReading
                                });
                            }}
                            alwaysShowMask
                            maskChar={"0"}
                            mask={"0".repeat(mDMMeterRegister.meterReadingDigitsCount)}
                            placeholder="20491"
                            className={'reading-input'}
                            style={{ width: mDMMeterRegister.meterReadingDigitsCount * 24 }}
                        />
                    </FormGroup>)
                })}
                <FormGroup>
                    <label>{"Date of COT  (move out date)"}:&nbsp;</label>
                    <SingleDatePicker
                        id="change-of-tenancy"
                        date={this.state.date}
                        numberOfMonths={1}
                        onDateChange={d => {
                            this.setState({
                                date: d
                            }, this.updateForm);
                        }}
                        isOutsideRange={day => day.isAfter(moment())}
                        focused={this.state.focused}
                        onFocusChange={({ focused }) => this.setState({ focused: focused })}
                    />
                </FormGroup>
                <FormGroup>
                    <label>{"Final Reading Type"}:&nbsp;</label>
                    <Input
                        type="select"
                        value={this.state.type}
                        onChange={e => {
                            this.setState({
                                type: e.target.value
                            },this.updateForm);
                        }}
                    >
                        <option value="customer">Customer</option>
                        <option value="estimate">Estimate</option>
                    </Input>
                </FormGroup>
            </Form>
        );
    }
}


export default MeterFields;