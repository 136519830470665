import React, {Component} from 'react';
import {Col, Row} from 'reactstrap';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import 'react-accessible-accordion/dist/fancy-example.css';
import i18n from '../../views/Pages/Login/i18n';

class AddressOverview extends Component {
    render() {
        const postalCode = <Row>
            <Col sm={3}>
                <span>{i18n.t('address.postalCode')}</span>
            </Col>
            <Col>
                {this.props.address.postalCode}
            </Col>
        </Row>;
        const county = <Row>
            <Col sm={3}>
                <span>{i18n.t('address.county')}</span>
            </Col>
            <Col>
                {this.props.address.county}
            </Col>
        </Row>;
        const city = <Row>
            <Col sm={3}>
                <span>{i18n.t('address.city')}</span>
            </Col>
            <Col>
                {this.props.address.city}
            </Col>
        </Row>;
        const street = <Row>
            <Col sm={3}>
                <span>{i18n.t('address.street')}</span>
            </Col>
            <Col>
                {this.props.address.street}
            </Col>
        </Row>;
        const dependentStreet = <Row>
            <Col sm={3}>
                <span>{i18n.t('address.dependentStreet')}</span>
            </Col>
            <Col>
                {this.props.address.dependentStreet}
            </Col>
        </Row>;
        const block = <Row>
            <Col sm={3}>
                <span>{i18n.t('address.block')}</span>
            </Col>
            <Col>
                {this.props.address.block}
            </Col>
        </Row>;
        const houseNumber = <Row>
            <Col sm={3}>
                <span>{i18n.t('address.houseNumber')}</span>
            </Col>
            <Col>
                {this.props.address.houseNumber}
            </Col>
        </Row>;
        return this.props.address ? <p>
            {(window.config.consul.DYCE || window.DYCE || (typeof DYCE !== 'undefined' ? DYCE : '' )) ? [ houseNumber,
                    block,
                    street,
                    dependentStreet,
                    city,
                    county,
                    postalCode
                ]
            : [ postalCode,
                county,
                city,
                street,
                dependentStreet,
                block,
                houseNumber] }
            </p>
            : null;
    }
}

export default AddressOverview;