import React from "react";

class CardNumberField extends React.Component {
    constructor(props) {
        super(props);

        let digits = props.schema.digits;
        let data = [];
        let values = [];
        for (let i = 1; i <= digits; i++) {
            data.push(i);
            values.push('');
        }

        this.state = {
            inputRef: [],
            data: data,
            values: values
        };

        this.jump = this.jump.bind(this);
        this.updateValue = this.updateValue.bind(this);
        this.isValid = this.isValid.bind(this);

        const {
            options,
            value,
            required,
            disabled,
            readonly,
            autofocus,
            onBlur,
            onFocus,
            onChange,
            id
        } = props;
    }

    jump(event) {
        console.log(event);
        let next = parseInt(event.target.id);
        if (event.target.value.length === parseInt(event.target.getAttribute('maxLength'))) {
            if (next < 11) {
                this.state.inputRef[next].focus();
            }
        }
        if (event.key === "Delete" || event.key === "Backspace") {
            if (next > 1) {
                this.state.inputRef[next - 2].focus();
            }
            if (this.state.values[next - 1] !== '') {
                this.state.values[next - 1] = '';
                this.setState({values: this.state.values});
                if (next === 1) {
                    this.state.inputRef[next].focus();
                }
            }
            this.props.onChange(undefined);
        }
    }

    updateValue(event) {
        let next = parseInt(event.target.id);
        if (event.target.value.length === parseInt(event.target.getAttribute('maxLength'))) {
            if (next <= 11 && this.isValid(event.target.value)) {
                this.state.values[next - 1] = parseInt(event.target.value);
                this.setState({values: this.state.values});
                if (!this.state.values.includes('')) {
                    this.props.onChange(parseInt(this.state.values.join('')));
                }
            }
        }
    }

    isValid(value) {
        const re = /^[0-9\b]+$/;
        return value === '' || re.test(value);
    }

    render() {
        return (
            <div className="two-columns-group">
                <div className="form-group">
                    <label htmlFor={this.props.label}
                           className="col-md-12 col-form-label">{this.props.schema.title}{this.props.required ? '*' : ''}</label>
                    <div className="col-xs-12 digit-input-box">
                        {this.state.data.map((element, i) => <input ref={el => this.state.inputRef[i] = el}
                                                                    className="digit-input"
                                                                    onChange={this.updateValue}
                                                                    value={this.state.values[i]}
                                                                    type="text"
                                                                    onKeyUp={this.jump}
                                                                    maxLength="1"
                                                                    id={element}
                                                                    name={element}/>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

export default CardNumberField;