import React from "react";
import PropTypes from "prop-types";

function UrnBuilder(props) {
    const {
        options,
        value,
        required,
        disabled,
        readonly,
        autofocus,
        onBlur,
        onFocus,
        onChange,
        id
    } = props;

    const _onChange = () => {
        const session_id = props.formContext.uuid;
        const urn = "urn:switching-form:tad:" + session_id + ":signed_contract.pdf";
        return onChange(urn);
    };


    return (
        <div>
            {_onChange()}
        </div>
    );
}

if (process.env.NODE_ENV !== "production") {
    UrnBuilder.propTypes = {
        schema: PropTypes.object.isRequired,
        id: PropTypes.string.isRequired,
        placeholder: PropTypes.string,
        options: PropTypes.shape({
            rows: PropTypes.number,
        }),
        value: PropTypes.string,
        required: PropTypes.bool,
        disabled: PropTypes.bool,
        readonly: PropTypes.bool,
        autofocus: PropTypes.bool,
        onChange: PropTypes.func,
        onBlur: PropTypes.func,
        onFocus: PropTypes.func,
    };
}
export default UrnBuilder;
