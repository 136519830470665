import React, {Component} from 'reactn';

import i18n from '../../views/Pages/Login/i18n';
import gql from "graphql-tag";
import {Mutation} from 'react-apollo';
import {deleteTaskMutation, getTasksQuery, updateTaskMutation} from "../../queries/Queries";
import schema from "../../schemas/task.schema.js"
import uischema from "../../schemas/task.uischema.js"
import uischemareadonly from "../../schemas/taskreadonly.uischema"
import Form from "react-jsonschema-form";
import ReactDOM from "react-dom";
import ExpiringAlert from "../ExpiringAlert";
import TasksAttachments from "../../components/TasksAttachments";
import moment from "moment";
import Loading from "../Loading";
import SwitchingForm from "../../views/SwitchingForm";

class TasksDetail extends Component {

    render() {

        const formdata = this.props.task ? {
            ...this.props.task, ...this.props.task.variables.reduce((accumulator, {name, value}) => {
                return {...accumulator, [name]: value};
            }, {})
        } : {};
        const taskReadonly = formdata.status === 'Closed' || formdata.status === 'Cancelled';
        const form = this.props.task && this.props.task.variables.filter(e => e.name === 'formData')[0];
        const formDetails = form && JSON.parse(form.value)
        return this.props.task
            ? form ?
            <div><SwitchingForm taskDetails={{id: this.props.task.id, service: this.props.task.service, outputVariableName: formDetails.outputVariableName }} {...formDetails}/></div>
                :
            <div className="task-details">
                <div className="task-labels clearfix">
                    <span className="pull-left">{ i18n.t('tasks.id') } {this.props.task.id} &nbsp;&nbsp;&nbsp;&nbsp;</span>
                    <span className="pull-left delete-task">
                        {this.global.permissions.includes("Tasks.DeleteTasks") && <Mutation mutation={deleteTaskMutation} variables={{taskId: this.props.task.id}} refetchQueries={[{query: getTasksQuery}]}>
                                                    {(deleteTask, {data}) => (<a href="javascript:void(0)" onClick={()=>{if(confirm("Are you sure?")) deleteTask().then(()=>{
                                                        ReactDOM.render(<ExpiringAlert color="success" message={i18n.t('tasks.successfulDelete')}  />, document.getElementById('alert').appendChild(document.createElement("div")));
                                                        this.props.resetView();
                                                    })}}><i className="icons ion-trash-b"></i>&nbsp;&nbsp;{ i18n.t('tasks.deletetask') }</a>)}
                                                </Mutation>}  
                    </span>
                    <span className="pull-right">by {this.props.task.owner} on {moment(this.props.task.createTime).format("L")}</span>
                </div> 
                <Mutation mutation={gql`${updateTaskMutation}`} refetchQueries={[{query: getTasksQuery}]}>
                    {(updateTask, {data}) => {
                        const submit = ({formData}) => {
                            ReactDOM.render(<Loading />,
                                document.getElementById('alert'));
                            updateTask({ variables: { task:{
                                        id:formData.id,
                                        name:formData.name,
                                        dueDate:formData.dueDate,
                                        description: formData.description,
                                        assignee: formData.assignee,
                                        priority: formData.priority,
                                        variables : [
                                            {
                                                name: "process",
                                                type: "string",
                                                value : formData.process
                                            },
                                            {
                                                name: "customer",
                                                type: "string",
                                                value : formData.customer
                                            },
                                            {
                                                name: "status",
                                                type: "string",
                                                value : formData.status
                                            }
                                        ]
                                    }, service : this.props.task.service}}).then(()=>{
                                ReactDOM.render(null, document.getElementById('alert'));
                                ReactDOM.render(<ExpiringAlert color="success" message={i18n.t('tasks.successfulEdit')}  />, document.getElementById('alert').appendChild(document.createElement("div")));

                            });
                            // this.props.resetView();
                        };
                        return (
                            <div>

                                <Form schema={schema}
                                      noValidate={true}
                                      noHtml5Validate={true}
                                      uiSchema={taskReadonly ? uischemareadonly : uischema}
                                      task={this.props.task} resetView={() => this.setState({task:null})}

                                      onSubmit={submit}

                                      formData={formdata}
                                      formContext={{formdata:formdata, submit:submit}} >
                                    <TasksAttachments task={this.props.task} uploadImmediately={true}/>
                                    {!taskReadonly && <div className="buttons">
                                        <div style={{marginBottom:"35px"}}>
                                        <button type="submit" className="btn btn-primary">Submit</button>
                                        <button type="button" className="btn btn-secondary">Cancel</button>
                                        </div>
                                    </div>
                                    }
                                </Form></div>)
                    }}
                                        
                </Mutation>
            </div>
            : <div className="no-tasks">Select task on the left to open it</div>;
           
    }
}


export default TasksDetail;
