import React, { Component } from "react";
import { Mutation, Query } from 'react-apollo';
import gql from "graphql-tag";
import {withRouter} from "react-router-dom";
import _ from "underscore";
import Form from "react-jsonschema-form";

import i18n from '../../views/Pages/Login/i18n';
import {dateFormatter} from "../../utils/Formatters";
import widgets from "../FormWidget"
import fields from "../FormField"

import jsonata from "./../../../node_modules/jsonata/jsonata-es5"
import ReactDOM from "react-dom";
import ExpiringAlert from "../ExpiringAlert";


class QueryForm extends React.Component {

    constructor(props) {
        super(props);
        this.setParents = this.setParents.bind(this);
        this.dateFormatter = dateFormatter.bind(this);
    }

    setParents(obj, parents){
        let newObj = JSON.parse(JSON.stringify(obj));
        const ownProps = Object.keys(newObj);
        for (let ownProp of ownProps) {
            if (newObj[ownProp] instanceof Object || newObj[ownProp] instanceof Array) {
                let newparents = parents.slice();
                newparents.unshift(obj);
                newObj[ownProp] = this.setParents(newObj[ownProp], newparents);
            }
        }
        newObj.__parents = parents;
        return newObj;
    }

    getDescendantProp (obj, desc) {
        if(desc) {
            var arr = desc.replace(']', '').split(/\[|\./);
            while (arr.length && (obj = obj[arr.shift()])) ;
            return obj;
        }
    }

    render() {
        if (!this.props.queryKey) {
            let data = this.props.newRecord;
            if (data) {
                const compiled = _.template(JSON.stringify(data));
                data = JSON.parse(compiled(this.props.match.params));
            }

            const {queries, queryKey, variables, ...newProps} = this.props;

            return (<Mutation mutation={gql`${this.props.queries[this.props.mutationKey]}`}>{(update) => {
                return <Form {...newProps} widgets={widgets.widgets} fields={fields} formData={data}
                             formContext={data} onSubmit={({formData}) => {
                    update({
                        variables: {
                            object: function removeProperties(obj) {
                                delete obj.__typename;
                                delete obj.__parents;
                                for (let ownProp of Object.keys(obj)) {
                                    if(obj[ownProp] instanceof Array || obj[ownProp] instanceof Object){
                                        delete obj[ownProp].__typename;
                                        delete obj[ownProp].__parents;
                                        for (let ownProp1 of Object.keys(obj[ownProp])) {
                                            if(obj[ownProp][ownProp1] instanceof Array || obj[ownProp][ownProp1] instanceof Object){
                                                delete obj[ownProp][ownProp1];
                                            }
                                        }
                                    }
                                }
                                return obj;
                            }(JSON.parse(JSON.stringify(formData[Object.keys(newProps.schema.properties)[0]])))
                        }
                    }).then(
                            //this.props.history.push('/bp/' + this.props.match.params.id + '/' + this.props.onSelectOpenPage + '/' + row.id);
                            ReactDOM.render(<ExpiringAlert color="success" message={i18n.t('Successfully added new entry')}  />, document.getElementById('alert').appendChild(document.createElement("div")))
                    );
                }
                }/>
            }
            }
            </Mutation>)
        }

        let variables;
        if (this.props.match.params["variables"]) {
            variables = JSON.parse(this.props.match.params["variables"][this.props.queryKey]);
        } else {
            variables = this.props.variables;
            if (variables) {
                const compiled = _.template(JSON.stringify(variables));
                variables = JSON.parse(compiled(this.props.match.params));
            }
        }
        const v = variables;

        return (<Query query={gql`${this.props.queries[this.props.queryKey]}`} variables={variables}>
            {(result) => {
                if (result.loading) return (<div>Loading...</div>);//<Loading/>;
                if (result.error) return <div>{result.error} </div>;
                const { data } = result;
                const dataWithParents = this.setParents(data, []);
                let expr = null;
                if (this.props.expression) {
                    const compiled = _.template(this.props.expression);
                    expr = compiled(this.props.match.params);
                }
                const expression = expr ? jsonata(expr) : null;
                const transformedData = expression ? expression.evaluate(dataWithParents) : dataWithParents;

                const {queries, queryKey, variables, ...newProps} = this.props;

 
                /**
                 *  Temporary solution till the submit functionality is discussed. 
                 */
                if(this.props.changeOfTanancy) {

                    const {schema, uiSchema, fields} = this.props;
                    const { businessPartner } = transformedData;
                    const [formContext] = businessPartner;

                    const [mDMContractList] = formContext.mDMContractList;

                    const {tariff, mDMContractPriceVersionList} = mDMContractList;

                    const products = [];

                    formContext.mDMContractList.forEach(mDMContract => {
                        products.push({
                            tariff: mDMContract.tariff,
                            product: mDMContract.marketingProduct.name,
                            paymentMethod: formContext.paymentMethod.name
                        });
                    });

                    return (
                        <Form
                            schema={schema}
                            uiSchema={uiSchema}
                            fields={fields}
                            formContext={formContext}
                            formData={{ 
                                newTenant: {
                                    bankAccount: { products },
                                }
                            }}
                            onSubmit={({formData}) => {
                                console.log(JSON.stringify(formData));
                                // moment(new Date("2020-12-23").toString()).format('DD/MM/YYYY')
                            }}
                        />
                    );
                }

                
                return (<Mutation mutation={gql`${this.props.queries[this.props.mutationKey]}`} refetchQueries={[{query: gql`${this.props.queries[this.props.queryKey]}`, variables: v}]}>{(update) => {
                    return <Form {...newProps} widgets={widgets.widgets} fields={fields} formData={transformedData}
                                 formContext={transformedData} onSubmit={({formData}) => {
                        update({
                            variables: {
                                object: function removeProperties(obj) {
                                    delete obj.__typename;
                                    delete obj.__parents;
                                    for (let ownProp of Object.keys(obj)) {
                                        if(obj[ownProp] instanceof Array || obj[ownProp] instanceof Object){
                                            delete obj[ownProp].__typename;
                                            delete obj[ownProp].__parents;
                                            for (let ownProp1 of Object.keys(obj[ownProp])) {
                                                if(obj[ownProp][ownProp1] instanceof Array || obj[ownProp][ownProp1] instanceof Object){
                                                    delete obj[ownProp][ownProp1];
                                                }
                                            }
                                        }
                                    }
                                    return obj;
                                }(JSON.parse(JSON.stringify(formData[Object.keys(newProps.schema.properties)[0]])))
                            }
                        });
                    }
                    }/>
                }
                    }
                </Mutation>)
            }}</Query>);
    }
}
export default withRouter(QueryForm);
