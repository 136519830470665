import React, { Component } from 'react';
import { Breadcrumb, Col, Row } from "reactstrap";

class UsefulLinks extends Component {
    constructor(props) {
        super(props);

        this.state = {
            file:null
        };
    }

    render() {
        return (
            <div>
                <Row className='subheader'>
                    <Col className='col-8'>
                        <h1>Useful Links</h1>
                    </Col>
                    <Col className='col-4'>
                        <Breadcrumb>
                        </Breadcrumb>
                    </Col>
                </Row>
                <div  className='page-cnt'>
                <Row>
                    <Col>
                        <p>
                            <a href={window.config.consul.FAQ_URL} target={'_blank'}>FAQ's</a>
                        </p>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <p>
                        <a href={window.config.consul.DOWNLOADS_URL} target={'_blank'}>Downloads</a>
                        </p>
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <p>
                        <a href={window.config.consul.NEWS_URL} target={'_blank'}>News</a>
                        </p>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <p>
                        <a href={window.config.consul.CONTACT_US_URL} target={'_blank'}>Contact Us</a>
                        </p>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <p>
                        <a href={(window.config.consul.TERMS_AND_CONDITIONS_URL || window.TERMS_AND_CONDITIONS_URL || (typeof TERMS_AND_CONDITIONS_URL !== 'undefined' ? TERMS_AND_CONDITIONS_URL : '' ))} target={'_blank'}>Terms &amp; Conditions</a>
                        </p>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <p>
                        <a href={window.config.consul.TRAINING_VIDEOS_URL} target={'_blank'}>Partner Portal Training Videos</a>
                        </p>
                    </Col>
                </Row>
                </div>
            </div>
        )
    }
}

export default UsefulLinks;
