import React, {Component} from 'react';
import JsxParser from 'react-jsx-parser';
import InfoCard from '../../components/InfoCard';
import QueryDataGrid1 from "../../components/QueryDataGrid1";
import {withRouter} from 'react-router';
import {
    Card,
    CardBody,
    CardHeader,
    CardText,
    CardTitle,
    Col,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Form,
    FormGroup,
    Nav,
    NavItem,
    Row,
    TabContent,
    TabPane
} from 'reactstrap';
import {Link, Redirect, Route, Switch} from 'react-router-dom';
import NavTabs from "../../components/NavTabs";
import {
    attachmentIconFormatter,
    billingDocumentTypeFormatter,
    dateFormatter,
    downloadFormatter,
    cdnDownloadFormatter,
    imageFormatter,
    invoiceEstateFormatter,
    jsonValueFormatter,
    noteIconFormatter,
    paymentCompleteFormatter,
    periodFormatter,
    prefCommChannelFormatter,
    priceCustomFormatter,
    priceFormatter,
    productFormatter,
    reportedAmountFormatter,
    rolesFormatter,
    checkBoxFormatter,
    jsonataFormatter,
    iconFormatter,
    downloadLinkFormatter
} from "../../utils/Formatters";
import i18n from '../../views/Pages/Login/i18n';
import ProcessesTable from "../ProcessesTable";
import QueryInfoCard from "../QueryInfoCard";
import QueryForm from "../QueryForm";
import GenericView from "../GenericView";
import TableHeader from "../../components/TableHeader"
import TableHeader2 from "../../components/TableHeader2"
import QueryLink from "../QueryLink";
import BillsTab from "../BillsTab";
import ReadingsTab from "../../components/ReadingsTab";
import QueryNotesAndAttachments from "../QueryNotesAndAttachments";
import QueryBillsTabWrapper from "../QueryBillsTabWrapper";
import BusinessPartnerHeader from "../BusinessPartnerHeader";
import StartProcess from "../StartProcess";
import PaginatedQueryDataGrid from "../PaginatedQueryDataGrid";
import QueryWrapper from "../QueryWrapper";
import DisplayValue from "../DisplayValue";

class GenericViewPreview extends Component {

    constructor(props) {
        super(props);
    }

    getQueries(){

        const result = {};
        const uiQueries = this.props.configuration.uiQueries;
        if (uiQueries && uiQueries.length) for (let i=0; i<uiQueries.length; i++) {
            result[uiQueries[i].name] = uiQueries[i].query;
        }
        return result;
    }

    getVariables(){

        const result = {};
        const uiQueries = this.props.configuration.uiQueries;
        if (uiQueries && uiQueries.length) for (let i=0; i<uiQueries.length; i++) {
            result[uiQueries[i].name] = uiQueries[i].variables;
        }
        return result;
    }

    render() {

        const queries = this.getQueries();
        this.props.match.params["variables"] = this.getVariables();
        const configuration = this.props.configuration;

        this.props.match.params = {...this.props.match.params, ...this.props.context};
        console.log(this.props.match.params);

        return (<JsxParser
            bindings={{...this.props, queries: queries,
                configuration: configuration,
                i18n: i18n,
                productFormatter: productFormatter,
                priceFormatter: priceFormatter,
                priceCustomFormatter: priceCustomFormatter,
                dateFormatter: dateFormatter,
                periodFormatter: periodFormatter,
                reportedAmountFormatter: reportedAmountFormatter,
                rolesFormatter: rolesFormatter,
                imageFormatter: imageFormatter,
                noteIconFormatter: noteIconFormatter,
                attachmentIconFormatter: attachmentIconFormatter,
                paymentCompleteFormatter: paymentCompleteFormatter,
                downloadFormatter: downloadFormatter,
                cdnDownloadFormatter: cdnDownloadFormatter,
                invoiceEstateFormatter: invoiceEstateFormatter,
                jsonValueFormatter: jsonValueFormatter,
                billingDocumentTypeFormatter: billingDocumentTypeFormatter,
                prefCommChannelFormatter: prefCommChannelFormatter,
                checkBoxFormatter: checkBoxFormatter,
                jsonataFormatter: jsonataFormatter,
                iconFormatter: iconFormatter,
                downloadLinkFormatter: downloadLinkFormatter}}
            components={{
                Dropdown,
                DropdownToggle,
                DropdownItem,
                DropdownMenu,
                Row,
                Col,
                Nav,
                NavItem,
                TabContent,
                TabPane,
                InfoCard,
                Card,
                CardText,
                Link,
                Switch,
                Route,
                Redirect,
                QueryDataGrid1,
                Form,
                FormGroup,
                NavTabs,
                ProcessesTable,
                QueryForm,
                QueryInfoCard,
                GenericView,
                TableHeader,
                BillsTab,
                ReadingsTab,
                QueryNotesAndAttachments,
                QueryBillsTabWrapper,
                QueryLink,
                QueryWrapper,
                DisplayValue,
                BusinessPartnerHeader,
                CardHeader,
                CardTitle,
                CardBody,
                StartProcess,
                PaginatedQueryDataGrid,
                TableHeader2
            }}
            jsx={this.props.jsx}
            showWarnings={false}
        />)
    }
}

export default withRouter(GenericViewPreview)