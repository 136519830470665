import React from "react";
import PropTypes from "prop-types";
import gql from "graphql-tag";
import ReactDOM from "react-dom";
import {AsyncTypeahead} from "react-bootstrap-typeahead";
import { Query } from "react-apollo";
import ExpiringAlert from "../../components/ExpiringAlert";
import axios from "../../utils/Client";
import moment from "moment";
import 'react-bootstrap-typeahead/css/Typeahead.css';

class AsyncSelect extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            filter: props.value || "~",
            region: "",

        }
    }

    render() {
        const cities = gql`query ($city: String, $region: String){
        city_point_of_supply(where: {
            city: {
                name: {_ilike: $city}
                region: {_ilike: $region}
            }
        }
        ) {
            city {
              city_id
              name
              region
            }
            point_of_supply {
              name
            }
          }
        }`;
        return <div><Query query={cities} variables={{city: this.state.filter, region: this.props.contextData && (this.props.contextData.area || this.props.contextData.correspondence_address_area) }}>
            {(result) => {

                if (result.error) ReactDOM.render(<ExpiringAlert color="danger"
                    message={result.error.message}/>, document.getElementById('alert').appendChild(document.createElement("div")));

                let cityPointOfSupply = [];
                if (result.data && Object.keys(result.data).length) {
                    cityPointOfSupply = result.data.city_point_of_supply;
                    const d = cityPointOfSupply[0];
                    this.props.onInit(d);
                }
                cityPointOfSupply.sort((o1, o2) => o1.city.name && o2.city.name && o1.city.name.localeCompare(o2.city.name));
                return <AsyncTypeahead id={this.props.path.join("_") + "search_city"} ref="typeahead2" isLoading={this.state.isLoading}
                                       cssclassName="md-12"
                                       options={!result.loading ? cityPointOfSupply : []}
                                       labelKey={(x) => { if (x) return x.city[0].name + ((!this.props.contextData || (!this.props.contextData.area && !this.props.contextData.correspondence_address_area)) ? ", " + x.city[0].region : "") }}

                                       placeholder={this.props.placeholder}
                                       filterBy={() => true}
                                       clearButton
                                       selectHintOnEnter={true}
                                       onSearch={(query) => {
                                           this.setState({filter: '%' + query + '%'});
                                       }}
                                       onChange={(selected) => {
                                           this.props.onChange(selected[0] ? selected[0] : "")
                                       }}
                                       defaultInputValue={this.props.value}
                                       //selected={this.props.value ? [{city: [{name: this.props.value}]}] : []}
                />
            }
            }
        </Query></div>
    }

}


function QuertyAsyncTypeAheadWidgetPointOfSupply(props) {
    const {
        options,
        value,
        required,
        disabled,
        readonly,
        autofocus,
        onBlur,
        onFocus,
        onChange,
        onPropertyChange,
        id,
    } = props;

    const details = gql`query MyQuery ($city: String) {
            client (where: {vat_number:{_eq: $city}}) {
                name
                address
                email
                fax
                first_name
                last_name
                middle_name
                phone
                city {
                    city_id
                    name
                    region
                }
                city_id
                vat_number
            }
        }`

    function loadOffers(event) {
        if (event) {
            if (props.path[0]==="place_of_supply" || props.path[0]==="correspondence_address") {
                axios.post(`${(window.config.consul.GRAPHQL_URL || window.GRAPHQL_URL || (typeof GRAPHQL_URL !== 'undefined' ? GRAPHQL_URL : '' ))}/tarifs/com.methodia.ts.bill.budget-plan-v2`, {
                    "tariffConditions": {
                        "DSO": event.point_of_supply[0].name,
                        "Type of customer": "sp",
                        "Date": moment().format("YYYY-MM-DD"),
                        "Budget Plan Tariff Name": props.formContext.tariffName
                    },
                    "ruleParameters": {"typeOfCustomer": "sp", tariff: props.formContext.tariffName},
                    "peopleCount": 1,
                    "Products": [{"searchKey": "Service Electricity"}]
                }).then(({data}) => {
                    const contextPath = [...props.path];
                    contextPath.splice(-1,1);
                    let obj = getValue(this.state, contextPath)
                    if (event.city[0].region)
                        if (props.path[0]==="correspondence_address") {
                            obj = {...obj, correspondence_address_area: event.city[0].region}
                        } else {
                            obj = {...obj, area: event.city[0].region}
                        }
                    setValue(this.state, contextPath, obj)
                    this.setState({...this.state, offer: data, DSO: event.point_of_supply[0].name});
                })
            } else {
                const contextPath = [...props.path];
                contextPath.splice(-1,1);
                let obj = getValue(this.state, contextPath)
                obj = {...obj, locality: event.city[0].name}
                if (event.city[0].region) obj = {...obj, area: event.city[0].region}
                setValue(this.state, contextPath, obj);
                contextPath.splice(-1,1);
                obj = getValue(this.state, contextPath)
                setValue(this.state, contextPath, {...obj, DSO: event.point_of_supply[0].name});
                console.log(this.state)
                this.setState({...this.state});
            }
        }
    }


    function hasValue(obj, path) {
        return obj[path[0]] && (path.length === 1 || hasValue(obj[path[0]], path.slice(1)));
    }
    function getValue(obj, path) {
        return (!obj[path[0]] || path.length === 1) ? obj[path[0]] : getValue(obj[path[0]], path.slice(1));
    }
    function setValue(obj, path, value) {
        if (path.length === 1 && obj) {
            obj[path[0]] = value;
        } else {
            setValue(obj[path[0]], path.slice(1), value);
        }
    }

    return (
        <AsyncSelect {...props} value={value} onInit={(data) => {if (!this.state.offer && hasValue(this.state, props.path)) loadOffers.call(this, data)}}
                     onChange={(event) => {
                         onChange(event ? event.city[0].name : null);
                         if (event) {
                             loadOffers.call(this, event);
                         }
                     }}/>
    );
}

if (process.env.NODE_ENV !== "production") {
    QuertyAsyncTypeAheadWidgetPointOfSupply.propTypes = {
        schema: PropTypes.object.isRequired,
        id: PropTypes.string.isRequired,
        placeholder: PropTypes.string,
        options: PropTypes.shape({
            rows: PropTypes.number,
        }),
        value: PropTypes.string,
        required: PropTypes.bool,
        disabled: PropTypes.bool,
        readonly: PropTypes.bool,
        autofocus: PropTypes.bool,
        onChange: PropTypes.func,
        onBlur: PropTypes.func,
        onFocus: PropTypes.func,
    };
}
export default QuertyAsyncTypeAheadWidgetPointOfSupply;
