import React from "react";
import PropTypes from "prop-types";
import {Input} from "reactstrap";
import InlineSVG from "svg-inline-react";

function ImageSelectorV2(props) {
    const {
        options,
        value,
        required,
        disabled,
        readonly,
        autofocus,
        onBlur,
        onFocus,
        onChange,
        id,
    } = props;
    // Generating a unique field name to identify this set of radio buttons
    const name = Math.random().toString();
    const { enumOptions, enumDisabled, inline } = options;
    // checked={checked} has been moved above name={name}, As mentioned in #349;
    // this is a temporary fix for radio button rendering bug in React, facebook/react#7630.
    console.log(options);
    return (
        <div className="field-radio-group image-selector" id={id}>
            {enumOptions.map((option, i) => {
                const checked = option.value === value;
                const itemDisabled =
                    enumDisabled && enumDisabled.indexOf(option.value) !== -1;
                const disabledCls =
                    disabled || itemDisabled || readonly ? "disabled" : "";
                const radio = (
                    <p className={"form__answer " + options.className} style={{width: options.width || 90/enumOptions.length + "%"}}>
                        <Input
                            type="radio"
                            checked={checked}
                            id={name + "_" + i}
                            name={name}
                            required={required}
                            value={option.value}
                            disabled={disabled || itemDisabled || readonly}
                            autoFocus={autofocus && i === 0}
                            onChange={_ => {console.log(option.value); onChange(option.value)}}
                            onBlur={onBlur && (event => onBlur(id, event.target.value))}
                            onFocus={onFocus && (event => onFocus(id, event.target.value))}
                        />
                        <label htmlFor={name + "_" + i} style={{display: "block"}}>
                            {options.svg && options.svg[i] && <InlineSVG key={name + "_" + i + "_svg"} src={options.svg[i]} />}
                            {option.label}
                        </label>
                    </p>
                );

                return radio;
            })}
        </div>
    );
}

if (process.env.NODE_ENV !== "production") {
    ImageSelectorV2.propTypes = {
        schema: PropTypes.object.isRequired,
        id: PropTypes.string.isRequired,
        placeholder: PropTypes.string,
        options: PropTypes.shape({
            rows: PropTypes.number,
        }),
        value: PropTypes.string,
        required: PropTypes.bool,
        disabled: PropTypes.bool,
        readonly: PropTypes.bool,
        autofocus: PropTypes.bool,
        onChange: PropTypes.func,
        onBlur: PropTypes.func,
        onFocus: PropTypes.func,
    };
}
export default ImageSelectorV2;
