import React, {useState} from 'react';

function IdentificatorMeasurementField(props) {
    const {
        options,
        value,
        required,
        disabled,
        readonly,
        autofocus,
        onBlur,
        onFocus,
        onChange,
        type,
        id,
    } = props;
    const rootIndex = id.lastIndexOf("_");
    const len = id.length;
    const realId = id.substring(rootIndex + 1, len);
    const additionalProperties = {};
    if (props.schema.maxLength) {
        additionalProperties.maxLength = props.schema.maxLength;
    }
    const [supplierInvoice, setSupplierInvoice] = useState(0);
    return (
        <div className="two-columns-group identificator-measurment" id={id}>
            <div className="form-group">
                <div className="row">
                    <label htmlFor={props.label}
                        className="col-md-6 col-form-label">{props.schema.title}{required ? '*' : ''}</label>
                    <div class="col-md-6 my-3">
                        <span onClick={() => setSupplierInvoice('invoice-EVN.jpg')} class="btn btn-primary btn-xs">ЕВН</span>
                        <span onClick={() => setSupplierInvoice('invoice-CHEZ.jpg')} class="btn btn-primary btn-xs mx-1">ЧЕЗ</span>
                        <span onClick={() => setSupplierInvoice('invoice-ENERGO-PRO.jpg')} class="btn btn-primary btn-xs">ЕНЕРГО-ПРО</span>
                    </div>
                </div>
                <div className="col-md-12 cs-tooltip">
                    <input className="form-control" id={id}
                           name={realId} label={id}
                           placeholder=""
                           type={props.schema.type}
                           readOnly={props.readonly}
                           onChange={(event) => props.onChange(event.target.value)}
                           value={props.value} {...additionalProperties}/>
                        {props.schema.hint ? <span class="cs-tooltiptext" dangerouslySetInnerHTML={{ __html: props.schema.hint }}></span> : ''}
                </div>
                <div className="supplier-invoice-image">
                    { supplierInvoice ? <img src={'img/supplier-invoices/' + supplierInvoice}/> : ''}
                </div>
            </div>
            {props.schema.description ? <label className="col-md-12 input-description">{props.schema.description}</label> :""}
        </div>
    );
}

export default IdentificatorMeasurementField;