import React, {Component} from 'react';

import i18n from '../../views/Pages/Login/i18n';
import axios from "../../utils/Client";
import Dropzone from "react-dropzone";
import fileDownload from "js-file-download";

class TasksAttachments extends Component {
    constructor(props) {
        super(props);
        this.state = {attachments:[], newAttachments: []};
        this.uploadAttachments = this.uploadAttachments.bind(this);
        this.getAttachments = this.getAttachments.bind(this);
        this.getAttachments(props.task);
    }

    componentWillReceiveProps(nextProps) {
        this.getAttachments(nextProps.task);
    }

    getAttachments(task) {
        if(task) {
            const taskId = task.id;
            axios.get(`${(window.config.consul.CORE_URL || window.CORE_URL || (typeof CORE_URL !== 'undefined' ? CORE_URL : '' ))}/tasks/${taskId}/attachments?service=${task.service}`, {
                headers: {'Authorization': "Bearer " + localStorage.token}
            }).then((response) => {
                this.setState({attachments: response.data});
            });
        } else {
            this.state.attachments = [];
        }
    }

    uploadAttachments(task){
        Promise.all(this.state.newAttachments.map(attachment => {
            const data = new FormData();
            data.append('file', attachment);
            return axios.post(`${(window.config.consul.CORE_URL || window.CORE_URL || (typeof CORE_URL !== 'undefined' ? CORE_URL : '' ))}/tasks/${task.id}/attachments?service=${task.service}`, data, {
                headers: {'Authorization': "Bearer " + localStorage.token}
            });
        })).then(() => {
            this.getAttachments(task);
            this.setState({newAttachments:[]});
        })
    }

    render() {

        const taskReadonly = this.props.task && (this.props.task.status === 'Closed' ||  this.props.task.status === 'Cancelled');
        return this.props.task || !this.props.uploadImmediately
            ?  <div className="files">
                    <h6>Attachments</h6>
                    {this.state.attachments.map(attachment => <div><i className="icon ion-android-attach"  style={{fontSize:"18px"}} title={i18n.t('attachments.attachment')}></i>
                        <a href='javascript:void(0)'
                           onClick={() => axios.get(`${(window.config.consul.CORE_URL || window.CORE_URL || (typeof CORE_URL !== 'undefined' ? CORE_URL : '' ))}/tasks/${ this.props.task.id}/attachments/${attachment.id}?service=${this.props.task.service}`, {
                        headers: {'Authorization': "Bearer " + localStorage.token},
                        responseType: 'arraybuffer'
                    }).then((response) => {
                        fileDownload(response.data, attachment.name);
                    })
                    }>{attachment.name}</a></div>)}
             
                {!taskReadonly && <div><Dropzone data-placeholder="Drop files to attach, or browse." className="droparea" onDrop={ files => {
                    this.setState({newAttachments:[...files, ...this.state.newAttachments]}, ()=>{
                        if(this.props.uploadImmediately){
                                this.uploadAttachments(this.props.task);
                            }
                        });
                    }}/>
                    {this.state.newAttachments.map(attachment => <div><i className="icon ion-android-attach"  style={{fontSize: "18px"}} title={i18n.t('attachments.attachment')}></i> {attachment.name}</div>)}
                    </div>
                }
                </div>
            : ""
    }
}




export default TasksAttachments;
